import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';

interface SwitcherProps {
    value: boolean;
    className?: string;
    label?: string;
    name?: string;
    onChange: (checked: boolean) => void;
}

export const Switcher: React.FC<SwitcherProps> = props => {
    return (
        <FormControlLabel
            className={props.className}
            control={
                <Switch
                    checked={props.value}
                    name={props.name}
                    onChange={event => props.onChange(event.target.checked)}
                    color='primary'
                />
            }
            label={props.label}
        />
    );
};

export const SwitcherRaw: React.FC<
    SwitcherProps & {
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        onBlur: (event: React.FocusEvent<HTMLButtonElement>) => void;
    }
> = props => {
    return (
        <FormControlLabel
            className={props.className}
            control={
                <Switch
                    color='primary'
                    name={props.name}
                    checked={props.value}
                    onChange={ev => {
                        props.onChange(ev);
                    }}
                    onBlur={props.onBlur}
                />
            }
            label={props.label}
        />
    );
};

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { IconButton } from "../common/IconButton";
import { SmartModal } from "../common/SmartModal";

import { AddNewSizechart } from "./AddNewSizechart";
import { selectSizecharts, sizechartsActions } from "./sizechart.state";
import { SizechartCard } from "./SizechartCard";

import "./SizechartList.scss";

export const SizechartList: React.FC = () => {
  const sizecharts = useSelector(selectSizecharts);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className="sizechart-list">
        {sizecharts.map((sizechart) => (
          <SizechartCard key={sizechart.id} id={sizechart.id} />
        ))}
      </div>

      <SmartModal
        body={({ closeModal }) => (
          <AddNewSizechart
            onSave={(newSizechart) => {
              dispatch(sizechartsActions.added(newSizechart));
              closeModal();
            }}
          />
        )}
      >
        <IconButton icon={faPlus}>
          {t("Add new size chart", "Add new size chart")}
        </IconButton>
      </SmartModal>
    </>
  );
};

import { API_URL, isMockApi } from '../config';
import { Segment } from '../domain/Segment';

import { Segments } from './__fixtures__/segments';
import { HttpClient } from './http-client';

export const fetchSegments = (httpClient: HttpClient) => (): Promise<Segment[]> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch segments');
              setTimeout(() => {
                  resolve(Segments);
              }, 1000);
          })
        : httpClient.get(`${API_URL}/v1/admin-seller/segments`).then(res => res.data);

import { API_URL, isMockApi, STORAGE_URL } from '../config';
import { BrandProfile } from '../domain/BrandProfile';
import { ProductVariationTexture } from '../domain/Product';
import { NewEntity, randomInt } from '../helpers';

import { Textures } from './__fixtures__/textures';
import { HttpClient } from './http-client';
import { toBase64 } from './brand.api';

const TEXTURES_API_URL = `${API_URL}/v1/admin-seller/textures`;

export const fetchTextures = (httpClient: HttpClient) => (
    brandId: BrandProfile['id'],
): Promise<ProductVariationTexture[]> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch textures for BrandID', brandId);
              setTimeout(() => {
                  resolve(Textures);
              }, 1000);
          })
        : httpClient
              .get(`${TEXTURES_API_URL}`)
              .then(res => res.data.data)
              .then(textures =>
                  textures.map((texture: any) => ({
                      id: texture.id,
                      name: texture.name,
                      pictureTexture: `${texture.picture_texture}`,
                  })),
              );

export const createTexture = (httpClient: HttpClient) => (brandId: BrandProfile['id']) => async (
    params: NewEntity<ProductVariationTexture>,
): Promise<{ id: ProductVariationTexture['id'] }> => {
    console.log(params)
    return isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Create texture for BrandID', brandId, 'and data', params);
              setTimeout(() => {
                  resolve({ id: randomInt(50, 99) });
              }, 1000);
          })
        : // TODO: check texture create (including images)
          httpClient.post(`${TEXTURES_API_URL}`, {
            name: params.name,
            nova_imagem: await toBase64(params.nova_imagem),
          }).then((res) => res.data);
}

export const updateTexture = (httpClient: HttpClient) => (id: ProductVariationTexture['id']) => async (
    params: NewEntity<ProductVariationTexture>,
): Promise<{ id: ProductVariationTexture['id'] }> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Update texture for ID`', id, 'and data', params);
              setTimeout(() => {
                  resolve({ id: randomInt(50, 99) });
              }, 1000);
          })
        : // TODO: check edit image
          httpClient.put(`${TEXTURES_API_URL}/${id}`, {
              name: params.name,
              nova_imagem: await toBase64(params.nova_imagem)
          });

export const deleteTexture = (httpClient: HttpClient) => (
    id: ProductVariationTexture['id'],
): Promise<void> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Delete texture', id);
              setTimeout(() => {
                  resolve();
              }, 1000);
          })
        : httpClient.delete(`${TEXTURES_API_URL}/${id}`);

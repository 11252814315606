import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import Swal from "sweetalert2";

import { PrimaryButton } from "../PrimaryButton";

import {
  Dimensions,
  ImageditEditorChangeEvent,
  ImageditEditor,
} from "./ImageditEditor";
import { ImageditImage } from "./ImageditImage.model";
import { ImageditReorderer } from "./ImageditReorderer";
import { VideoUrlHandler } from "./VideoUrl";

const getInitialImages = (
  src: ImageditImage | ImageditImage[]
): ImageditImage[] => {
  if (Array.isArray(src)) {
    if (src.length === 0) {
      return [{ src: "", crop: {}, id: Math.random() }];
    } else {
      return src;
    }
  } else {
    return [src];
  }
};

const getInitialSelectedImage = (images: ImageditImage[], index: number) => {
  if (images.length > 0) {
    return images[index].id;
  } else {
    return undefined;
  }
};

interface ImageditModalProps {
  allowEmpty: boolean;
  desiredDimensions: Dimensions;
  multiple?: boolean;
  onSave: (images: ImageditImage[]) => void;
  videoUrlHandler?: VideoUrlHandler;
  title: string;
  src: ImageditImage | ImageditImage[];
  noCrop?: boolean;
  helpDimensions?: Dimensions;
  imageLimit?: number;
  openIndex?: number;
}
export const ImageditModal: React.FC<ImageditModalProps> = (props) => {
  const {
    allowEmpty,
    desiredDimensions,
    multiple,
    videoUrlHandler,
    title,
    src,
    noCrop,
    helpDimensions,
    imageLimit,
    openIndex,
  } = props;
  const [images, setImages] = useState<ImageditImage[]>(getInitialImages(src));
  const [imagesError, setImagesError] = useState<string | null>();
  const [localLoadingPreview, setLocalLoadingPreview] = useState(false);

  const [currentId, setCurrentId] = useState<ImageditImage["id"] | undefined>(
    getInitialSelectedImage(images, openIndex || 0)
  );
  const [currentImage, setCurrentImage] = useState<ImageditImage | undefined>(
    undefined
  );
  const { t } = useTranslation();

  const onImageAdd = ({
    src,
    file,
  }: {
    src: ImageditImage["src"];
    originalImage?: ImageditImage["src"];
    file?: File;
  }) => {
    const id = Math.random();
    const newImages = [
      ...images,
      { id, src, crop: { x: 0, y: 0, width: 0, height: 0 }, file: file, originalImage: src },
    ];
    setImages(newImages.filter(item => item.src !== ""));
    setCurrentImage(newImages[newImages.length - 1]);
    setCurrentId(id);
  };

  const onImageChange = (change: ImageditEditorChangeEvent) => {
    const filteredImages = images.filter(item => item.src !== "");
    const sizeExcludingEmptySrc = filteredImages.length;

    if (sizeExcludingEmptySrc === 0) {
      onImageAdd(change);
      return;
    }
    
    const newImages = images.map((img) =>
      img.id === currentId
        ? {
          ...img,
          ...change,
          originalImage: change.src,
        }
        : img
    );
    setImages(newImages);
    setCurrentImage({
      id: currentId || "",
      originalImage: change.src,
      ...change,
    });
  };

  const onImageMove = (
    sourceId: ImageditImage["id"],
    destinationId: ImageditImage["id"]
  ) => {
    const sourceIndex = images.findIndex((image) => image.id === sourceId);
    const destinationIndex = images.findIndex(
      (image) => image.id === destinationId
    );

    if (sourceIndex < 0 || destinationIndex < 0) {
      return;
    }

    const newImages = images.slice();
    newImages[sourceIndex] = images[destinationIndex];
    newImages[destinationIndex] = images[sourceIndex];

    setImages(newImages);
  };

  const onImageRemove = () => {
    const index = images.findIndex((img) => img.id === currentId);

    if (index < 0) {
      return;
    }

    Swal.fire({
      title: t(
        "Are you sure you want to remove this image?",
        "Are you sure you want to remove this image?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Remove", "Remove"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        const newImage = images.find(
          (image) => !image.remover && image.id !== currentId
        );

        let newImages = images.map((img) => {
          if (img.id === currentId) {
            img.remover = true;
          }
          return img;
        });

        if (newImage) {
          setCurrentId(newImage.id);
          setCurrentImage(newImage);
        } else {
          const temporaryId = Math.random();
          newImages = [{ src: "", crop: {}, id: temporaryId }, ...newImages];

          setTimeout(() => {
            setCurrentId(temporaryId);
            setCurrentImage(newImages[0]);
          }, 500);
        }

        setImages(newImages);
      }
    });
  };

  const loadingSave = () => {
    setLocalLoadingPreview(true);

    setTimeout(() => {
      setLocalLoadingPreview(false);
      onSave();
    }, 3000);
  };


  const onSave = async () => {
    setImagesError(null);

    if (!noCrop) {
      for (
        let index = 0;
        index < images.filter((img) => !img.remover && (img.src || img.videoUrl)).length;
        index++
      ) {
        const image = images.filter((img) => !img.remover)[index];
        if (
          !image.remover &&
          image.src &&
          (!image.crop ||
            !image.crop?.width ||
            image.crop.width <= 0 ||
            !image.crop?.height ||
            image.crop.height <= 0) &&
          !image.imageCropping
        ) {
          setImagesError(
            t("Crop image", "Crop image") +
              (images.length > 1 ? " " + index + 1 : "") +
              " " +
              t("is required!", "is required!")
          );
          return;
        }

        image.cropOriginal = await getPercentCropDimensionsToDisplayCrop(
          image.originalImage || "",
          image.crop
        );

        image.crop = await getPercentCropDimensionsToDisplayImage(
          image.originalImage || "",
          image.crop
        );
      }
    }

    props.onSave(images);
  };

  const getImageSize = (
    url: string
  ): Promise<{
    width: number;
    height: number;
  }> => {
    return new Promise((resolve) => {
      var img = new Image();

      img.onload = function () {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = () => resolve({width: 0, height: 0})

      img.src = url;
    });
  };

  const getPercentCropDimensionsToDisplayImage = async (
    url: string,
    crop: ReactCrop.PercentCrop | ReactCrop.Crop
  ) => {
    const sizes = await getImageSize(url);
    const correctCrop = { ...crop };
    correctCrop.unit = "px";
    correctCrop.width = sizes.width * ((crop.width || 0) / 100);
    correctCrop.height = sizes.height * ((crop.height || 0) / 100);
    correctCrop.x = sizes.width * ((crop.x || 0) / 100);
    correctCrop.y = sizes.height * ((crop.y || 0) / 100);
    return correctCrop;
  };

  const getPercentCropDimensionsToDisplayCrop = async (
    url: string,
    crop: ReactCrop.PercentCrop | ReactCrop.Crop
  ) => {
    const correctCrop = { ...crop };
    correctCrop.unit = "px";
    correctCrop.width = crop.width;
    correctCrop.height = crop.height;
    correctCrop.x = crop.x;
    correctCrop.y = crop.y;
    return correctCrop;
  };

  const handleChangeImageReorderer = (id: any) => {
    const image = images.find((item) => item.id === id);

    setCurrentId(id);
    setCurrentImage(image);
  };

  useEffect(() => {
    const images = getInitialImages(src);
    setImages(images);

    const image = images.find((item) => item.id === currentId);
    setCurrentImage(image);
  }, [src]);

  useEffect(() => {
    if (videoUrlHandler && currentImage) {
      videoUrlHandler.url = currentImage.videoUrl || '';
      videoUrlHandler.handler = url => videoUrlHandler.defaultHandler(url, {...currentImage}, [...images]);
    }
  }, [currentImage]);

  return (
    <div className="imagedit-modal">
      <h2>{title}</h2>

      {images.filter(filtered => filtered.id === currentImage?.id) && (
        <ImageditEditor
          allowEmpty={allowEmpty}
          crop={currentImage?.crop}
          desiredDimensions={desiredDimensions}
          helpDimensions={helpDimensions}
          onChange={onImageChange}
          onRemove={onImageRemove}
          videoUrlHandler={videoUrlHandler}
          src={currentImage?.originalImage || ""}
          file={currentImage?.file}
          noCrop={noCrop || false}
        />
      )}

      {multiple ? (
        <ImageditReorderer
          images={images.filter((img) => !img.remover)}
          currentImageId={currentId}
          onAdd={onImageAdd}
          onChange={handleChangeImageReorderer}
          onMove={onImageMove}
          imageLimit={imageLimit || 8}
        />
      ) : null}

      {imagesError && <span className="images-error">{imagesError}</span>}

      <PrimaryButton className="imagedit-save" onClick={loadingSave}>
        {t("Save", "Save")}

        {localLoadingPreview && <div className="loadingSaveButton"></div>}
      </PrimaryButton>
    </div>
  );
};

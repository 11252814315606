import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import { useApi } from "../../api/useApi";
import BrandStyle from "../../components/BrandStyle/BrandStyle";
import { InfoBlocks } from "../../components/InfoBlocks/InfoBlocks";
import { BrandProfile, SiteArea } from "../../domain/BrandProfile";
import { PageLoadingSpinner } from "../../pages/common/PageLoadingSpinner";
import { noop } from "../common/helpers";
import { useAsync } from "../useAsync";
import { FeaturedInfoBlock } from "./FeaturedInfoBlock";
import { TermsAndConditions } from "./TermsAndConditions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import "./TheBrand.scss";
import { TextToggledit } from "../common/TextToggledit";
import { isMobile } from "../../helpers";
import { useTranslation } from "react-i18next";
import getCroppedImg from "../getCroppedImg";
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const perChunk = [8, 5, 2];

export const TheBrand: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const theBrandInfoBlocks = values.theBrand.infoBlock;
  const [textBrandStyle, setTextBrandStyle] = useState("");
  const [imgBrandStyle, setImgBrandStyle] = useState<any[]>([]);
  const { t } = useTranslation();
  const [loadingPreview, setLoadingPreview] = useState<boolean | number>(false);

  const handleSetTemplate = (template: number) => {
    setFieldValue("theBrand.brandStyle.template", template);
  };

  const transformCrop = (image: any) => {
    return new Promise((resolve) => {
      if (!image.imageCropping580) {
        resolve({});
      }
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = auxImage?.imageCropping580?.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: 605 / 417,
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.onerror = err => resolve({})
      img.src = image?.originalImage || "";
    });
  };

  const loadImagesPreview = (newImage: any) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = async () => {
        const croppedImg: any = await getCroppedImg(img, newImage.novaImagemCrop);
        var objectURL = URL.createObjectURL(croppedImg);
        resolve(objectURL);
      };
      img.onerror = () => resolve('')
      img.crossOrigin = "anonymous";

      if (!newImage.file && !newImage.imageSrc.startsWith('blob:')) {
        img.src = newImage.imageSrc + `?x=123`;
      } else if(!newImage.file && newImage.imageSrc.startsWith('blob:')) {
        img.src = newImage.imageSrc ;
      } else {
        img.src = newImage.imageSrc;
      }
    });
  };

  useEffect(() => {
    setLoadingPreview(values.theBrand.brandStyle.imagePreviewIndex || 0);
    const loadImages = async () => {
      let results: any[] = values.theBrand.brandStyle.images
        .filter((item) => !item.remover)
        .reduce((resultArray: any[], item, index) => {
          const chunkIndex = Math.floor(
            index / perChunk[values.theBrand.brandStyle.template - 1]
          );

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item);
          return resultArray;
        }, []);

      Promise.all(
        results.map((slide) => {
          return Promise.all(
            slide.map(async (img: any) => {
              return {
                id: img.id || `${Math.random()}`,
                src: img.novaImagemCrop
                  ? await loadImagesPreview(img)
                  : img.imageSrc,
                originalImage: img.imageSrc.startsWith('blob:') ? img.imageSrc : img.originalImage || "",
                imageCropping: img.imageCropping580,
                title: img.title,
                link: img.link,
                cropOriginal: img.cropOriginal,
                crop: img.cropOriginal
                  ? img.cropOriginal
                  : await transformCrop(img),
              };
            })
          );
        })
      )
      .then((images: any) => {setImgBrandStyle(images)})
      .catch((err) => {console.error(err)})
      .finally(() => setLoadingPreview(false))
    };
    loadImages();
  }, [values.theBrand.brandStyle.template, values.theBrand.brandStyle.images]);

  return (
    <div className="the-brand">
      <FeaturedInfoBlock />
      <div className="container-brand-style">
        <h1>{t("Brand Style", "Brand Style")}</h1>
        <div className="text">
          <TextToggledit
            text={values.theBrand.brandStyle.description || ""}
            placeholder={t("Brand Style text", "Brand Style text")}
            maxLength={900}
            multipleLines
            name={`text`}
            onChange={(e) =>
              setFieldValue("theBrand.brandStyle.description", e)
            }
          />
        </div>
        <Swiper
          pagination={{
            type: "bullets",
            clickable: true,
          }}
          allowTouchMove={isMobile()}
          slidesPerView={1}
          spaceBetween={10}
          navigation={true}
          className="mySwiper"
        >
          {imgBrandStyle.length > 0 ? (
            imgBrandStyle.map((item, index) => (
              <SwiperSlide key={index}>
                <BrandStyle
                  template={values.theBrand.brandStyle.template}
                  loadingPreview={loadingPreview}
                  onSetTemplate={(val) => handleSetTemplate(val)}
                  images={imgBrandStyle[index]}
                  index={index}
                  chunkSize={imgBrandStyle.length}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <BrandStyle
                loadingPreview={loadingPreview}
                template={values.theBrand.brandStyle.template}
                onSetTemplate={(val) => handleSetTemplate(val)}
                images={[]}
                index={0}
                chunkSize={1}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <InfoBlocks
        infoBlocks={theBrandInfoBlocks.filter(
          (infoblock) => !infoblock.featured
        )}
        pathPrefix="theBrand.infoBlock"
        name="the-brand-infoblocks"
        siteArea={SiteArea.TheBrand}
      />

      <TermsAndConditions />
    </div>
  );
};

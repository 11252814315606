import React, { createContext, useContext } from "react";

interface OrderableGridContextContent {
  itemsType: string;
  droppedItem?: boolean|number;
}

const OrderableGridContext = createContext<OrderableGridContextContent>({
  itemsType: "",
  droppedItem: false
});

interface OrderableGridProps {
  itemsType: string;
  className?: string;
  droppedItem?: boolean|number;
}
export const OrderableGrid: React.FC<OrderableGridProps> = (props) => {
  return (
    <OrderableGridContext.Provider
      value={{ itemsType: props.itemsType, droppedItem: props.droppedItem }}
    >
      {props.children}
      {/* <div className={props.className}>{props.children}</div> */}
    </OrderableGridContext.Provider>
  );
};

export const useOrderableGridContext = (): OrderableGridContextContent => {
  return useContext(OrderableGridContext);
};

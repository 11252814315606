import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../api/useApi";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ErrorMessage } from "../external/common/ErrorMessage";
import { noop } from "../external/common/helpers";
import { PrimaryButton } from "../external/common/PrimaryButton";
import { useAsync } from "../external/useAsync";

import "./Login.page.scss";
import registerAccessGtm from "../services/gtmRegister";

export const LoginPage: React.FC = () => {
  const { login, authenticate } = useApi();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [logoXAccount, setLogoXAccount] = useState("");

  const { isPending, isFailure, start } = useAsync(login);

  const onLogin = () => {
    start({ username, password }).then(authenticate).catch(noop);
  };

  const { t } = useTranslation();

  useEffect(() => {
    registerAccessGtm()
  }, [])

  useEffect(() => {
    if (localStorage.getItem("X-Account-Logo")) {
      setLogoXAccount(localStorage.getItem("X-Account-Logo") || "");
    }
  }, []);

  return (
    <div className="login-page">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onLogin();
        }}
      >
        <div className="login-box">
          <img className="logo" src={logoXAccount} />

          <div className="title">
            {t("Brand administration", "Brand administration")}
          </div>

          {isFailure ? (
            <ErrorMessage
              message={t("Invalid credentials", "Invalid credentials")}
            />
          ) : null}

          <TextField
            required
            label={t("Email", "Email")}
            className="login-field"
            autoFocus
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            required
            type="password"
            label={t("Password", "Password")}
            className="login-field"
            onChange={(event) => setPassword(event.target.value)}
          />

          <PrimaryButton disabled={isPending} className="login-button">
            {isPending ? <LoadingSpinner /> : null}
            {t("Login", "Login")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { LoadingSpinner } from '../../components/LoadingSpinner';

import { useAsyncImage } from './useAsyncImage';

import './Thumbnail.scss';

interface ThumbnailProps {
    src: string;
    className?: string;
    placeholder?: JSX.Element;
    error?: JSX.Element;
    onClick?: () => void;
}
export const Thumbnail: React.FC<ThumbnailProps> = props => {
    const { src, className, placeholder, error, onClick } = props;

    const { isPending, isSuccess, isFailure } = useAsyncImage({ src });

    const getBody = () => {
        if (isPending) {
            return placeholder || <LoadingSpinner />;
        }

        if (isSuccess) {
            return (
                <div
                    className='thumbnail-image'
                    style={{ backgroundImage: `url("${src}")` }}
                    onClick={onClick}
                />
            );
        }

        if (isFailure) {
            return error || <FontAwesomeIcon icon={faExclamationCircle} />;
        }
    };

    return <div className={`thumbnail ${className}`}>{getBody()}</div>;
};

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Size } from '../../domain/Size';
import { RootState } from '../../RootState';

interface NewSizeArgs {
    id?: Size['id'],
    name: Size['name'];
}

const makeNewSize = (args: NewSizeArgs): SizeState => ({
    id: args.id || Math.random(),
    isNew: true,
    name: args.name,
});

/** Reducer */
export interface SizeState extends Size {
    isNew?: boolean;
}

const sizesAdapter = createEntityAdapter<SizeState>();
export const sizesSlice = createSlice({
    name: 'sizes',
    initialState: sizesAdapter.getInitialState(),
    reducers: {
        fetched: sizesAdapter.setAll,
        added: {
            reducer: sizesAdapter.addOne,
            prepare: (args: NewSizeArgs) => ({
                payload: makeNewSize(args),
            }),
        },
        nameChanged: {
            reducer: sizesAdapter.updateOne,
            prepare: (args: { id: SizeState['id']; name: SizeState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        removed: sizesAdapter.removeOne,
    },
});

/** Actions */
export const sizesActions = sizesSlice.actions;

/** Selectors */
const sizesSelectors = sizesAdapter.getSelectors((state: RootState) => state.sizes);

export const selectSizes = sizesSelectors.selectAll;
export const selectSizesCollection = sizesSelectors.selectEntities;
export const selectSize = (id: SizeState['id']) => (state: RootState): SizeState | undefined =>
    sizesSelectors.selectById(state, id);

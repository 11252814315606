import {
  faEllipsisH,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import { Sizechart } from "../../domain/Sizechart";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { ImageWithPlaceholder } from "../common/ImageWithPlaceholder";
import { Menu } from "../common/Menu";
import { TextToggledit } from "../common/TextToggledit";

import {
  selectSizechart,
  SizechartState,
  sizechartsActions,
} from "./sizechart.state";

import "./SizechartCard.scss";
import { useTranslation } from "react-i18next";

export const SizechartCard: React.FC<{ id: SizechartState["id"] }> = (
  props
) => {
  const sizechart = useSelector(selectSizechart(props.id));
  const dispatch = useDispatch();
  const api = useApi();
  const { t } = useTranslation();

  if (!sizechart) {
    return null;
  }

  const updateSizechart = (changes: Partial<Sizechart>) => {
    api.updateSizechart(sizechart.id)({
      ...sizechart,
      ...changes,
    });
  };

  const deleteSizechart = () => {
    Swal.fire({
      title: t(
        "Are you sure you want to delete this size chart?",
        "Are you sure you want to delete this size chart?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Delete", "Delete"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        api.deleteSizechart(sizechart.id).catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = "";
            const keysErrors = Object.keys(error.response.data);
            if (keysErrors.length > 0) {
              for (let index = 0; index < keysErrors.length; index++) {
                errors += error.response.data[keysErrors[index]] + "\n";
              }
            }
            Swal.fire(
              t("Error!", "Error!"),
              errors ||
                t(
                  "An error ocurred, please review the inserted data.",
                  "An error ocurred, please review the inserted data."
                ),
              "error"
            );
          }

          throw Promise.reject(error);
        });
        dispatch(sizechartsActions.removed(sizechart.id));
      }
    });
  };

  return (
    <Card className="sizechart-card">
      <CardContent>
        <TextToggledit
          name="sizechart-name"
          text={sizechart.name}
          onChange={(name) => {
            dispatch(sizechartsActions.nameChanged({ id: sizechart.id, name }));
            updateSizechart({ name });
          }}
        />

        <Imagedit
          allowEmpty
          className="sizechart-image"
          desiredDimensions={{ width: 0, height: 0 }}
          render={() => <ImageWithPlaceholder src={sizechart.image?.url} />}
          onSave={(newImages) => {
            const image = newImages.length > 0 ? newImages[0] : undefined;
            dispatch(
              sizechartsActions.imageChanged({
                id: sizechart.id,
                image: {
                  url: image?.remover === true ? "" : image?.src,
                  remover: image?.remover,
                },
              })
            );
            updateSizechart({
              image: {
                url: image?.src,
                remover: image?.remover,
                nova_imagem: image?.file,
              },
            });
          }}
          title={t("Edit size chart image", "Edit size chart image")}
          src={[
            {
              id: "sizechart.image",
              src: sizechart.image?.url || "",
              originalImage: sizechart.image?.url || "",
              crop: {},
            },
          ]}
          noCrop={true}
        />
        <CardActions className="sizechart-actions">
          <Menu
            icon={faEllipsisH}
            render={({ close }) => {
              const onDelete = () => {
                deleteSizechart();
                close();
              };

              return (
                <MenuItem onClick={onDelete}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                  </ListItemIcon>
                  <ListItemText primary={t("Delete", "Delete")} />
                </MenuItem>
              );
            }}
          ></Menu>
        </CardActions>
      </CardContent>
    </Card>
  );
};

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { stopPropagation } from "../helpers";

import { Modal } from "../Modal";
import { PrimaryButton } from "../PrimaryButton";
import { TextInput } from "../TextInput";
import { ImageditImage } from "./ImageditImage.model";

export interface VideoUrlHandler {
    readonly defaultHandler: (url: string, image?: ImageditImage, images?: ImageditImage[]) => void;
    handler: (url: string, image?: ImageditImage, images?: ImageditImage[]) => void;
    url: string;
}

const VideoUrlModal: React.FC<{
  url: string | null;
  onClose: () => void;
  onConfirm: (url: string) => void;
}> = (props) => {
  const { onClose, onConfirm } = props;
  const { t } = useTranslation();

  const [url, setUrl] = useState(props.url ?? '')


  return (
    <Modal
      isOpen={true}
      onRequestClose={stopPropagation(() => onClose())}
    >
      <p>{t("Provide a embed URL", "Provide a embed URL")}</p>

      <div className="horizontal-center" style={{ gap: '1rem' }}>
        <TextInput value={url} onChange={value => setUrl(value)} />

        <PrimaryButton onClick={stopPropagation(() => onConfirm(url))}>
          {t("Confirm", "Confirm")}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export const VideoUrl: React.FC<{
  videoUrlHandler: VideoUrlHandler;
}> = ({ videoUrlHandler }) => {
  const [showModal, setShowModal] = useState(false);

  function openModal () {
    setShowModal(true);
  }

  function closeModal () {
    setShowModal(false);
  }

  function onConfirm(url: string) {
    videoUrlHandler.handler(url);

    closeModal();
  }

  return (
    <>
      <FontAwesomeIcon icon={faLink} onClick={openModal} />

      {showModal && <VideoUrlModal url={videoUrlHandler.url} onClose={closeModal} onConfirm={onConfirm} />}
    </>
  );
};

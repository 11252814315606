import React from "react";

import { CollectionSummary } from "../../../domain/CollectionList";
import { useRoutes } from "../../../useRoutes";
import { ListCard } from "../../common/ListCard";

import "./CollectionCard.scss";

export const CollectionCard: React.FC<{ collection: CollectionSummary }> = (
  props
) => {
  const routes = useRoutes();
  const { collection } = props;

  function onCollectionClick(id: number) {
    routes.collections.navigateById(id);
  }

  return (
    <ListCard
      className="collection-card"
      title={collection.name}
      image={collection.listImage}
      onClick={() => onCollectionClick(collection.id)}
    ></ListCard>
  );
};

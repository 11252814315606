import { Collection } from './Collection';

export enum LinesheetTemplate {
    Template1 = 1,
    Template2 = 2,
    Template3 = 3,
    Template4 = 4,
}

export interface Linesheet {
    id: number;
    template: LinesheetTemplate;
    cover: string;
    moodboard: string;
    backCover: string;
    showVariation: boolean;
    collectionId: Collection['id'];
}

import React, { useEffect, useState } from "react";

import { AsyncButton } from "./AsyncButton";
import { ErrorText } from "./ErrorMessage";

import "./BottomBar.scss";
import { useFormikContext } from "formik";
import { Product } from "../../domain/Product";
import { useTranslation } from "react-i18next";

export const BottomBarBrandPage: React.FC<{
  isSubmitting: boolean;
  message?: string;
  draftStatus: any;
  isFailure?: boolean;
  handleSubmitReview: Function;
}> = (props) => {
  const isSubmitting = props.isSubmitting ?? false;
  const [isMsgShown, setMsgShown] = useState(false);
  const { setFieldValue, submitForm } = useFormikContext<Product>();

  useEffect(() => {
    setMsgShown(true);
    const timerId = setTimeout(() => {
      if (!props.isFailure) {
        setMsgShown(false);
      }
    }, 5000);
    return () => clearTimeout(timerId);
  }, [props.message, props.isFailure]);

  const { t } = useTranslation();

  return (
    <div className="bottom-bar">
      {isMsgShown ? (
        props.isFailure ? (
          <ErrorText message={props.message ?? ""} />
        ) : (
          props.message
        )
      ) : null}
      {props.draftStatus !== "waiting_for_approval" && (
        <AsyncButton type="submit" isPending={isSubmitting}>
          {t("Save", "Save")}
        </AsyncButton>
      )}
      {props.draftStatus === "pending" && (
        <button
          onClick={() => props.handleSubmitReview(true)}
          className="primary-button"
          type="button"
        >
          {t("Submit for review", "Submit for review")}
        </button>
      )}
      {props.draftStatus === "waiting_for_approval" && (
        <p>Your changes are waiting for approval.</p>
      )}
    </div>
  );
};

import classnames from "classnames";
import React from "react";

import "./Showcase.scss";

interface ShowcaseProps {
  className?: string;
  title: React.ReactNode;
  image: React.ReactNode;
  topContent: React.ReactNode;
  bottomContent: React.ReactNode;
  imagePosition?: "left" | "right";
  imageOrientation?: "portrait" | "landscape";
  dots?: boolean;
}
export const Showcase: React.FC<ShowcaseProps> = (props) => {
  const { title, image, topContent, bottomContent, className, dots } = props;

  const imagePosition = props.imagePosition ?? "left";
  const imageOrientation = props.imageOrientation ?? "portrait";

  return (
    <div
      className={classnames(
        "showcase",
        className,
        `image-position-${imagePosition}`,
        `image-orientation-${imageOrientation}`
      )}
    >
      <div className="image">
        {image}
        {dots ? (
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : null}
      </div>
      <div className="content">
        <div className="top-content">{topContent}</div>
        <div className="title">{title}</div>
        <div className="bottom-content">{bottomContent}</div>
      </div>
    </div>
  );
};

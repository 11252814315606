import {
  faEllipsisH,
  faEllipsisV,
  faImages,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrandProfile } from "../../domain/BrandProfile";
import { Menu } from "../../external/common/Menu";
import { TextToggledit } from "../../external/common/TextToggledit";

import "./BrandStyle.scss";
import EightSmall from "./EightSmall";
import OneBigFourSmall from "./OneBigFourSmall";
import TwoBig from "./TwoBig";

const BrandStyle: React.FC<{
  template: number;
  onSetTemplate: (template: number) => void;
  images: any[];
  index: number;
  chunkSize: number;
  loadingPreview: boolean | number;
}> = (props) => {
  const slidePadrao = {
    remover: false,
    imageSrc: "",
    id: null,
  };
  const { chunkSize, index } = props;
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const { t } = useTranslation();

  const handleNewSlide = () => {
    let images: any = props.images.filter((item) => item.imageSrc !== "");

    const templateSoma = [8, 5, 2];

    if (
      images.length === templateSoma[values.theBrand.brandStyle.template - 1]
    ) {
      setFieldValue(
        `theBrand.brandStyle.images[${values.theBrand.brandStyle.images.length}]`,
        slidePadrao
      );
    }

    setTimeout(() => {
      var bullets = document.querySelectorAll(".swiper-pagination-bullet");
      const bullet = bullets[bullets.length - 1] as any;
      bullet.click();
    }, 500);
  };

  const handleRemoverSlide = () => {
    setFieldValue(`theBrand.brandStyle.images`, [
      ...values.theBrand.brandStyle.images.slice(
        0,
        values.theBrand.brandStyle.images.length - 1
      ),
    ]);
  };

  return (
    <div className="brand-style">
      <div className="images">
        {props.template === 1 && (
          <EightSmall
            loadingPreview={props.loadingPreview}
            images={props.images}
          />
        )}
        {props.template === 2 && (
          <OneBigFourSmall
            loadingPreview={props.loadingPreview}
            images={props.images}
          />
        )}
        {props.template === 3 && (
          <TwoBig loadingPreview={props.loadingPreview} images={props.images} />
        )}
        <div className="actions">
          <div className="container-icons">
            <FontAwesomeIcon
              onClick={handleNewSlide}
              title={t("New Slide", "New Slide")}
              icon={faPlus}
            />
            {JSON.stringify(
              values.theBrand.brandStyle.images[
                values.theBrand.brandStyle.images.length - 1
              ]
            ) === JSON.stringify(slidePadrao) && chunkSize - 1 === index ? (
              <FontAwesomeIcon
                onClick={handleRemoverSlide}
                title={t("Remove", "Remove")}
                icon={faTrash}
              />
            ) : null}
          </div>
          <Menu
            icon={faEllipsisV}
            render={({ close, t }) => {
              return (
                <MenuItem>
                  <ListItem>
                    {t("Template", "Template")}:{" "}
                    <select
                      value={props.template}
                      onChange={(e) =>
                        props.onSetTemplate(parseInt(e.target.value))
                      }
                    >
                      <option value={1}>{t("8 small", "8 small")}</option>
                      <option value={2}>
                        {t("1 big and 4 small", "1 big and 4 small")}
                      </option>
                      <option value={3}>{t("Two big", "Two big")}</option>
                    </select>
                  </ListItem>
                </MenuItem>
              );
            }}
          ></Menu>
        </div>
      </div>
    </div>
  );
};

export default BrandStyle;

import { useState, useEffect } from 'react';

import { moveItemById } from '../external/common/OrderableGrid/moveItem';

export const useCollectionState = <T extends { id: unknown }>(initial: T[]) => {
    const [items, setItems] = useState(initial);
    
    useEffect(() => {
        setItems(initial || items)
    }, []);

    const [isChanged, setChanged] = useState(false);
    return {
        add: (item: T | T[]) => {
            const newItems = item instanceof Array ? item : [item];
            setItems(oldItems => [...oldItems, ...newItems]);
            setChanged(true);
        },
        remove: (itemId: T['id'] | T['id'][]) => {
            const removedItemIds = itemId instanceof Array ? itemId : [itemId];
            setItems(oldItems => oldItems.filter(item => !removedItemIds.includes(item.id)));
            setChanged(true);
        },
        move: (sourceId: T['id'], destinationId: T['id']) => {
            setItems(oldItems => {
                const sourceIndex = items.findIndex(item => item.id === sourceId);
                const destinationIndex = items.findIndex(item => item.id === destinationId);
                if (sourceIndex < 0 || destinationIndex < 0) {
                    return oldItems;
                }
                const beforeDest = items
                    .slice(0, destinationIndex)
                    .filter(item => item.id !== sourceId);
                const afterDest = items
                    .slice(destinationIndex)
                    .filter(item => item.id !== sourceId);
                const srcItem = items[sourceIndex];
                return [...beforeDest, srcItem, ...afterDest];
            });
            setChanged(true);
        },
        findById: (itemId: T['id']) => items.find(item => item.id === itemId),
        items,
        isChanged,
    };
};

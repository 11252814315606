import { FormControl, FormHelperText, InputLabel, Select as MUISelect } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

interface SelectProps {
    className?: string;
    label?: string;
    id: string;
    value: string | null;
    fullWidth?: boolean;
    onChange: (
        value: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => void;
}
export const Select: React.FC<SelectProps> = props => {
    const p = { labelId: props.id };
    return (
        <FormControl className={props.className}>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <MUISelect {...p} id={props.id} value={props.value} onChange={props.onChange}>
                {props.children}
            </MUISelect>
        </FormControl>
    );
};

export const FormSelect: React.FC<SelectProps & { name: string }> = props => {
    const [, meta] = useField(props.name);
    const hasError = meta.touched && !!meta.error;

    return (
        <FormControl error={hasError}>
            {props.label ? (
                <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
                    {props.label}
                </InputLabel>
            ) : null}
            <MUISelect {...props}>{props.children}</MUISelect>
            {hasError ? <FormHelperText>{meta.error}</FormHelperText> : null}
        </FormControl>
    );
};

export const moveItem = <T>(collection: T[]) => (args: { sourceId: T; destinationId: T }): T[] => {
    const { sourceId, destinationId } = args;

    const sourceIndex = collection.findIndex(item => item === sourceId);
    const destinationIndex = collection.findIndex(item => item === destinationId);
    if (sourceIndex < 0 || destinationIndex < 0) {
        return collection;
    }

    const coll = collection.slice();
    const aux = coll[sourceIndex];
    coll[sourceIndex] = coll[destinationIndex];
    coll[destinationIndex] = aux;
    return coll;
};

export const moveItemBy = <T, I>(selector: (item: T) => I) => (collection: T[]) => (args: {
    sourceId: I;
    destinationId: I;
}): T[] => {
    const { sourceId, destinationId } = args;

    const sourceIndex = collection.findIndex(item => selector(item) === sourceId);
    const destinationIndex = collection.findIndex(item => selector(item) === destinationId);
    if (sourceIndex < 0 || destinationIndex < 0) {
        return collection;
    }

    const coll = collection.slice();
    const aux = coll[sourceIndex];
    coll[sourceIndex] = coll[destinationIndex];
    coll[destinationIndex] = aux;
    return coll;
};

export const moveItemById = <T extends { id: unknown }>(args: {
    sourceId: T['id'];
    destinationId: T['id'];
}) => (collection: T[]): T[] => moveItemBy<T, T['id']>(item => item.id)(collection)(args);

import {
  faPlus,
  faTrashAlt,
  faEllipsisV,
  faArrowsAlt,
  faArrowsAltH,
  faPortrait,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import classnames from "classnames";
import { useFormikContext, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import Swal from "sweetalert2";

import { useApi } from "../../api/useApi";
import { InfoBlock, BrandProfile, SiteArea } from "../../domain/BrandProfile";
import { FormAutoSave } from "../../external/common/FormAutoSave";
import { noop } from "../../external/common/helpers";
import { Imagedit } from "../../external/common/Imageedit/Imagedit";
import { Dimensions } from "../../external/common/Imageedit/ImageditEditor";
import { moveItemById } from "../../external/common/OrderableGrid/moveItem";
import {
  OrderableGrid,
  useOrderableGridContext,
} from "../../external/common/OrderableGrid/OrderableGrid";
import { OrderableGridHandle } from "../../external/common/OrderableGrid/OrderableGridHandle";
import { OrderableGridItem } from "../../external/common/OrderableGrid/OrderableGridItem";
import { Showcase } from "../../external/common/Showcase";
import { TextToggledit } from "../../external/common/TextToggledit";
import { YoutubeEmbedVideo } from "../../external/common/YoutubeEmbedVideo";
import getCroppedImg from "../../external/getCroppedImg";
import { useAsync } from "../../external/useAsync";

import "./InfoBlocks.scss";

const makeEmptyInfoBlock = (args: { siteArea: SiteArea }): InfoBlock => ({
  id: -1,
  images: [],
  title: "",
  summary: "",
  text: "",
  buttonLabel: "",
  link: "",
  imagePosition: "left",
  imageOrientation: "portrait",
  order: 0,
  seller: 40,
  featured: false,
  siteArea: args.siteArea,
});

const getImageDimensions = (
  orientation: InfoBlock["imageOrientation"]
): Dimensions => {
  switch (orientation) {
    case "portrait":
      return { width: 300, height: 450 };
    case "landscape":
      return { width: 500, height: 300 };
    default:
      return { width: 0, height: 0 };
  }
};

const getImageAspect = (orientation: InfoBlock["imageOrientation"]): number => {
  const { width, height } = getImageDimensions(orientation);
  if (height > 0) {
    return width / height;
  } else {
    return 0;
  }
};

const InfoBlockMenu: React.FC<{
  infoBlock: InfoBlock;
  pathPrefix: string;
  name: string;
  index: number;
  onRemove: (index: number) => void;
}> = (props) => {
  const { infoBlock, index, name, pathPrefix } = props;
  const { handleChange, handleBlur } = useFormikContext<BrandProfile>();

  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <FontAwesomeIcon
        icon={faEllipsisV}
        aria-label="more"
        aria-controls={`${name}-menu`}
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id={`${name}-menu`}
        className={classnames("infoblocks-menu", `${name}-menu`)}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                icon={faImage}
                size="lg"
                style={{ marginLeft: "24px" }}
              />
              <FontAwesomeIcon icon={faPortrait} size="lg" />
            </span>
          </ListItemIcon>
          <ListItemText
            primary={
              <div className="infoblock-image-orientation">
                {t("Image orientation", "Image orientation")}
                <select
                  value={infoBlock.imageOrientation}
                  name={`imageOrientation`}
                  onChange={(event) => {
                    handleChange(event);
                    handleClose();
                  }}
                  onBlur={handleBlur}
                >
                  <option value="portrait">{t("Portrait", "Portrait")}</option>
                  <option value="landscape">
                    {t("Landscape", "Landscape")}
                  </option>
                </select>
              </div>
            }
          />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowsAltH} />
          </ListItemIcon>
          <ListItemText
            primary={
              <div className="infoblock-image-position">
                {t("Image position", "Image position")}
                <select
                  value={infoBlock.imagePosition}
                  name={`imagePosition`}
                  onChange={(event) => {
                    handleChange(event);
                    handleClose();
                  }}
                  onBlur={handleBlur}
                >
                  <option value="left">{t("Left", "Left")}</option>
                  <option value="right">{t("Right", "Right")}</option>
                </select>
              </div>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onRemove(index);
            handleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faTrashAlt} />
          </ListItemIcon>
          <ListItemText primary={t("Remove", "Remove")} />
        </MenuItem>
      </Menu>
    </>
  );
};

const InfoBlockRender: React.FC<{
  infoBlock: InfoBlock;
  pathPrefix: string;
  name: string;
  index: number;
  onRemove: (index: number) => void;
  onSave: (infoBlock: InfoBlock, index: number) => void;
}> = (props) => {
  const { index, name, pathPrefix } = props;
  const [infoBlock, setInfoBlock] = useState(props.infoBlock);
  const { updateBrandInfoBlock, isUpdatingBrand, setInfoBlocksSubmitting } =
    useApi();
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const valuesOrderableGrid = useOrderableGridContext();
  const updateBrandInfoBlockAsync = useAsync(
    updateBrandInfoBlock(infoBlock.id)
  );

  const [imgSrc, setImgSrc] = useState(
    infoBlock.images.length > 0
      ? formRef.current?.values?.imageOrientation === "portrait"
        ? infoBlock.images[0].portraitImage
        : infoBlock.images[0].landscapeImage
      : ""
  );
  const [videoUrl, setVideoUrl] = useState(infoBlock.images[0]?.videoUrl || null)
  const [newVideoUrl, setNewVideoUrl] = useState(videoUrl || null)

  useEffect(() => {
    if (isUpdatingBrand && formRef.current.dirty) {
      formRef.current.submitForm();
    }
  }, [isUpdatingBrand]);

  const [image, setImage] = useState<any[]>([]);

  const transformCrop = (image: any, imageOrientation: any) => {
    return new Promise((resolve) => {
      if (!image.imageCropping) {
        resolve({});
      }
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = auxImage?.imageCropping?.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: getImageAspect(imageOrientation),
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.onerror = () => resolve({});
      img.src = image?.originalImage || "";
    });
  };

  useEffect(() => {
    if (
      valuesOrderableGrid.droppedItem !== false &&
      valuesOrderableGrid.droppedItem === infoBlock.id &&
      formRef.current
    ) {
      if (!formRef.current.isSubmitting) {
        formRef.current.handleSubmit();
      }
    }
  });

  const onSave = (infoBlock: InfoBlock, index: number) => {
    setInfoBlock(infoBlock);
    setInfoBlocksSubmitting(infoBlock.id, true);

    updateBrandInfoBlockAsync
      .start(infoBlock)
      .then(async (response) => {
        let infoBlockImages = { ...(response.images as any) };
        infoBlockImages = await Promise.all(
          response.images.map(async (img) => ({
            id: img.id || `${Math.random()}`,
            src:
              (infoBlock.imageOrientation === "portrait"
                ? img.portraitImage
                : img.landscapeImage) || "",
            originalImage: img.originalImage || "",
            imageCropping:
              (infoBlock.imageOrientation === "portrait"
                ? img.portraitImageCropping
                : img.imageCropping) || "",
            crop: await transformCrop(img, infoBlock.imageOrientation),
            videoUrl: img.videoUrl || null,
          }))
        );

        formRef.current.setFieldValue("images", [...infoBlockImages]);
        formRef.current.resetForm({
          values: { ...infoBlock, images: [...infoBlockImages] },
        });
        setImage([...infoBlockImages]);
        return props.onSave({ ...infoBlock, id: response.id }, index);
      })
      .finally(() => setInfoBlocksSubmitting(infoBlock.id, false))
      .catch(noop);
  };

  useEffect(() => {
    const loadImages = async () => {
      let images = await Promise.all(
        infoBlock.images.map(async (img, index) => {
          return {
            id: img.id || `${Math.random()}`,
            src:
              (infoBlock.imageOrientation === "portrait"
                ? img.portraitImage
                : img.landscapeImage) || "",
            originalImage: img.originalImage || "",
            imageCropping:
              (infoBlock.imageOrientation === "portrait"
                ? img.portraitImageCropping
                : img.imageCropping) || "",
            crop: await transformCrop(img, infoBlock.imageOrientation),
            videoUrl: img.videoUrl,
          };
        })
      );
      setImage(images);
    };
    loadImages();
  }, [infoBlock.imageOrientation]);

  return (
    <div className="infoblock">
      <Formik
        innerRef={formRef}
        initialValues={{ ...infoBlock, avoidSubmit: false }}
        onSubmit={(values) => onSave({ ...values, order: index }, index)}
      >
        {({ values, setFieldValue, dirty }) => (
          <>
            <Prompt
              when={!!dirty}
              message={(location) =>
                `${t(
                  "You have unsaved changes",
                  "You have unsaved changes"
                )}. ${t(
                  "Are you sure you want leave?",
                  "Are you sure you want leave?"
                )}`
              }
            />
            <Showcase
              imagePosition={values.imagePosition}
              imageOrientation={values.imageOrientation}
              title={
                <h2>
                  <TextToggledit
                    text={values.title}
                    placeholder={t("Info block title", "Info block title")}
                    maxLength={60}
                    name={`title`}
                    onChange={(value) => setFieldValue(`title`, value)}
                  />
                </h2>
              }
              image={
                <Imagedit
                  allowEmpty={true}
                  desiredDimensions={getImageDimensions(
                    values.imageOrientation
                  )}
                  render={() => {
                    let shouldRender = 'placeholder'

                    if (videoUrl) {
                      shouldRender = 'videoUrl'
                    } else if (imgSrc) {
                      shouldRender = 'img'
                    }

                    return ({
                      videoUrl: <YoutubeEmbedVideo src={videoUrl as string} className="info-block" />,
                      img: <img src={imgSrc as string} />,
                      placeholder: (
                        <div className="placeholder-image">
                          <FontAwesomeIcon icon={faImage} />
                        </div>
                      ),
                    } as any)[shouldRender]
                  }}
                  onSave={async (images) => {
                    const newImages = images.map((image) => ({
                      ...image,
                      imageResp: image.src,
                      portraitImage: image.src,
                      landscapeImage: image.src,
                      url: image.src,
                      nova_imagem: image.file,
                      nova_imagem_crop: image.crop,
                      videoUrl: newVideoUrl,
                    }));

                    const primeiraImagem = newImages.find(image => !image.remover && (image.src || image.videoUrl));

                    if (primeiraImagem) {
                      let img = new Image();
                      img.onload = async () => {
                        const croppedImg: any = await getCroppedImg(img, primeiraImagem.nova_imagem_crop);
                        var objectURL = URL.createObjectURL(croppedImg);
                        setImgSrc(objectURL);
                      };
                      img.onerror = () => null
                      img.crossOrigin = "anonymous";

                      if (!primeiraImagem.file) {
                        img.src = primeiraImagem.src + `?x=123`;
                      } else {
                        img.src = primeiraImagem.src;
                      }
                    } else {
                      setImgSrc(null);
                    }

                    setVideoUrl(newVideoUrl || null)

                    setFieldValue(`images`, newImages);


                    let infoblockImages = [
                      ...images.map((image) => ({
                        ...image,
                        crop: image.cropOriginal || {},
                        videoUrl: newVideoUrl,
                      })),
                    ];

                    if (!primeiraImagem && infoblockImages.length === 0) {
                      infoblockImages = [
                        { crop: {}, src: "", id: Math.random(), videoUrl: null },
                        ...infoblockImages,
                      ];
                    }

                    setImage(infoblockImages);
                  }}
                  videoUrlHandler={{
                    defaultHandler: url => setNewVideoUrl(url),
                    handler: _ => {
                      throw Error('O método precisa ser sobrescrito.');
                    },
                    url: newVideoUrl || '',
                  }}
                  title={t("Info block image", "Info block image")}
                  src={image}
                />
              }
              topContent={null}
              bottomContent={
                <>
                  <TextToggledit
                    text={values.text}
                    placeholder={t("Info block text", "Info block text")}
                    maxLength={900}
                    multipleLines
                    name={`text`}
                    onChange={(value) => setFieldValue(`text`, value)}
                  />
                </>
              }
            />
            <div className="actions">
              <OrderableGridHandle>
                <FontAwesomeIcon icon={faArrowsAlt} />
              </OrderableGridHandle>
              <InfoBlockMenu
                infoBlock={values}
                pathPrefix={pathPrefix}
                name={name}
                index={index}
                onRemove={() => props.onRemove(infoBlock.id)}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export const InfoBlocks: React.FC<{
  name: string;
  pathPrefix: string;
  infoBlocks: InfoBlock[];
  siteArea: SiteArea;
}> = (props) => {
  const { pathPrefix, name } = props;
  const { createBrandInfoBlock, removeBrandInfoBlock } = useApi();
  const createBrandInfoBlocksAsync = useAsync(createBrandInfoBlock());
  const removeBrandInfoBlocksAsync = useAsync(removeBrandInfoBlock);
  const [infoBlocks, setInfoBlocks] = useState<InfoBlock[]>(props.infoBlocks);
  const [droppedItem, setDroppedItem] = useState<boolean | InfoBlock["id"]>(
    false
  );
  const { t } = useTranslation();
  const onAdd = () => {
    const newInfoBlock = makeEmptyInfoBlock({ siteArea: props.siteArea });
    setInfoBlocks((oldInfoBlocks) => [...oldInfoBlocks, { ...newInfoBlock }]);
  };

  const onRemove = (infoBlockId: InfoBlock["id"]) => {
    Swal.fire({
      title: t(
        "Are you sure you want to remove this info block?",
        "Are you sure you want to remove this info block?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Remove", "Remove"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setInfoBlocks((oldInfoBlocks) =>
          oldInfoBlocks.filter((infoBlock) => infoBlock.id !== infoBlockId)
        );
        removeBrandInfoBlocksAsync.start(infoBlockId).catch(noop);
      }
    });
  };

  const onSave = (infoBlockUpdated: InfoBlock, indexInfoBlock: number) => {
    setDroppedItem(false);
    setInfoBlocks((infoBlock) =>
      infoBlock.map((infoBlock, index) => {
        if (index === indexInfoBlock && infoBlock.id === -1) {
          infoBlock = infoBlockUpdated;
        }
        return infoBlock;
      })
    );
  };

  const onMove = (
    sourceId: InfoBlock["id"],
    destinationId: InfoBlock["id"]
  ) => {
    setInfoBlocks(moveItemById({ sourceId, destinationId }));
    // let destinationIndex = infoBlocks
    //   .map((item) => item.id)
    //   .indexOf(destinationId);
    // let sourceInfoblock = infoBlocks.filter((item) => item.id === sourceId)[0];
    // sourceInfoblock.order = destinationIndex;

    // console.log(infoBlocks.filter((item) => item.id === sourceId));
    // console.log(destinationIndex);
    // console.log(sourceInfoblock);
  };

  const onDrop = (sourceId: InfoBlock["id"]) => {
    setDroppedItem(sourceId);
  };

  return (
    <div className={classnames("infoblocks", name)}>
      <OrderableGrid itemsType="infoblocks" droppedItem={droppedItem}>
        {infoBlocks.map((infoBlock, index) => (
          <OrderableGridItem
            key={infoBlock.id}
            id={infoBlock.id}
            onMoveItem={onMove}
            onDropItem={onDrop}
          >
            <InfoBlockRender
              key={infoBlock.id}
              infoBlock={infoBlock}
              pathPrefix={props.pathPrefix}
              name={props.name}
              index={index}
              onRemove={onRemove}
              onSave={onSave}
            />
          </OrderableGridItem>
        ))}
      </OrderableGrid>
      <div className="add-infoblock" onClick={onAdd}>
        <FontAwesomeIcon icon={faPlus} />
        <div>{t("Add info block", "Add info block")}</div>
      </div>
    </div>
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { IconDefinition } from '../../common';

import './IconButton.scss';

export const IconButton: React.FC<{
    icon: IconDefinition;
    onClick?: () => void;
}> = props => {
    return (
        <div className='icon-button' onClick={props.onClick}>
            <FontAwesomeIcon icon={props.icon} />
            <span>{props.children}</span>
        </div>
    );
};

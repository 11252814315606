import React from 'react';

import { useTabbedViewContext, TabID } from './common';

interface TabSelectorProps {
    id: TabID;
}
export const TabSelector: React.FC<TabSelectorProps> = props => {
    const { selectTab, selectedTab } = useTabbedViewContext();
    const classNames = `tab-selector${selectedTab === props.id ? ' tab-selector-active' : ''}`;
    return (
        <div className={classNames} onClick={() => selectTab(props.id)}>
            {props.children}
        </div>
    );
};

import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BrandProfile } from "../../domain/BrandProfile";
import { Modal } from "../common/Modal";
import { PrimaryButton } from "../common/PrimaryButton";
import { RichTextEditor } from "../common/RichTextEditor";

import "./TermsAndConditions.scss";

export const TermsAndConditions: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [isEditing, setEditing] = useState(false);
  const [terms, setTerms] = useState(values.theBrand.termsConditions);

  const onSave = () => {
    setFieldValue("theBrand.termsConditions", terms);
    setEditing(false);
  };

  const { t } = useTranslation();

  return (
    <div className="terms-and-conditions">
      <span onClick={() => setEditing(true)}>
        {t("Terms and conditions", "Terms and conditions")}
      </span>

      <Modal
        isOpen={isEditing}
        contentLabel={t("Terms and conditions", "Terms and conditions")}
        onRequestClose={() => setEditing(false)}
      >
        <h2>{t("Terms and conditions", "Terms and conditions")}</h2>
        <div className="terms-and-conditions-modal-content">
          <RichTextEditor
            value={terms}
            onChange={(event) => setTerms(event.target.value)}
          />
        </div>
        <div className="terms-and-conditions-modal-actions save-button">
          <PrimaryButton onClick={onSave}>{t("Save", "Save")}</PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

import { noop } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createMeasurement } from "../../api/measurements.api";
import { measurementsActions } from "../../external/Measurement/measurements.state";
import { useApi } from "../../api/useApi";

import { PrimaryButton } from "../common/PrimaryButton";
import { AsyncButton } from "../common/AsyncButton";
import { useAsync } from "../useAsync";

import "./AddMeasurement.scss";
import { useTranslation } from "react-i18next";

export const AddNewMeasurement: React.FC<{
  onSave: (args: { id: number; name: string }) => void;
}> = (props) => {
  const { createMeasurement, user, fetchMeasurements } = useApi();
  const createMeasurementAsync = useAsync(createMeasurement());
  const { start } = useAsync(fetchMeasurements);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const nameIsValid = name.length > 0;

  const onSave = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (nameIsValid) {
      createMeasurementAsync
        .start({ name })
        .then((res) => props.onSave({ id: res.id, name }))
        .catch(noop);
    }
  };

  return (
    <form className="add-measurement-form" onSubmit={onSave}>
      <h3>{t("Add new measurement", "Add new measurement")}</h3>
      <input
        type="text"
        className="measurement-name-input"
        placeholder={t("Name", "Name")}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <div className="save-button">
        <AsyncButton isPending={createMeasurementAsync.isPending}>
          {t("Save", "Save")}
        </AsyncButton>
      </div>
    </form>
  );
};

import { normalize, schema } from 'normalizr';

import { ProductDTO } from '../../../api/product.api';

const detailSchema = new schema.Entity('details');
const colorSchema = new schema.Entity('colors');
const textureSchema = new schema.Entity('textures');
const sizeSchema = new schema.Entity('sizes');
const stocksSchema = new schema.Entity('stocks', {
    sizeId: sizeSchema,
});

const variationSchema = new schema.Entity('variations', {
    color: colorSchema,
    texture: textureSchema,
    stock: [stocksSchema],
});

const productSchema = new schema.Entity('product', {
    details: [detailSchema],
    variations: [variationSchema],
    sizes: [sizeSchema],
});

export const normalizeProduct = (productDto: ProductDTO): any =>
    normalize(productDto, productSchema);

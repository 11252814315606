import { API_URL, isMockApi, STORAGE_URL } from "../config";
import {
  BrandFooter,
  BrandProfile,
  BrandProfileHistoryDetails,
  InfoBlock,
  InfoblockImage,
} from "../domain/BrandProfile";

import { HttpClient } from "./http-client";

/** Brand Profile */
export const fetchBrandProfile =
  (httpClient: HttpClient) => (): Promise<BrandProfile | void> =>
    httpClient
      .get(`${API_URL}/v1/admin-seller/brand-page`)
      .then((res) => res.data)
      .then((brand) => {
        return {
          id: brand.id,
          name: brand.name,
          draft_status: brand.draft_status,
          slug: brand.slug,
          isActive: brand.is_active,
          coverImage: {
            url: `${brand.cover_image}`,
            imageOriginal: brand.cover_image_original,
            imageCropping: brand.cover_image_cropping,
            remove: false,
          },
          coverImageThumbnail: {
            url: `${brand.cover_image_thumbnail}`,
            remove: false,
          },
          logoBrandPage: {
            url: `${brand.logo_image}`,
            remove: false,
          },
          theBrand: {
            brandFrom: brand.brand_from,
            yearEstablished: brand.year_established,
            multibrandRetailers: brand.multibrand_retailers,
            multibrandRetailersFull: brand.multibrand_retailers_full,
            brandStyle: {
              description: brand.brand_style,
              template: brand.brand_style_template,
              images: brand.brand_style_images.map((image: any) => (
                {
                  id: image.id,
                  imageSrc: image.image_580_600,
                  originalImage: image.image_original,
                  imageCropping285: image.image_285_cropping,
                  imageCropping580: image.image_580_600_cropping,
                  title: image.title,
                  link: image.link,
                  button_label: image.button_label,
                  videoUrl: image.video_url,
                }
              ))
            },
            featuredInfoBlock: brand.featured_info_block
              ? {
                  id: brand.featured_info_block.id,
                  imageOrientation: brand.featured_info_block.image_orientation,
                  imagePosition: brand.featured_info_block.image_position,
                  buttonLabel: brand.featured_info_block.button_label,
                  images: brand.featured_info_block.images.map(
                    (image: any) => ({
                      id: image.id,
                      imageResp: image.image_resp,
                      featuredImage: image.featured_image,
                      featuredImageResp: image.featured_image_resp,
                      landscapeImage: image.landscape_image,
                      portraitImage: image.portrait_image,
                      originalImage: image.original_image,
                      imageCropping: image.featured_image_cropping,
                      videoUrl: image.video_url,
                    })
                  ),
                  link: brand.featured_info_block.link,
                  order: brand.featured_info_block.order,
                  seller: brand.featured_info_block.seller,
                  summary: brand.featured_info_block.summary,
                  text: brand.featured_info_block.text,
                  title: brand.featured_info_block.title,
                  featured: true,
                  siteArea: 1,
                }
              : undefined,
            infoBlock: brand.info_blocks.map((infoBlock: any) => ({
              id: infoBlock.id,
              images: infoBlock.images.map((image: any) => ({
                id: image.id,
                imageResp: image.image_resp,
                featuredImage: image.featured_image,
                featuredImageResp: image.featured_image_resp,
                originalImage: image.original_image,
                landscapeImage: image.landscape_image,
                portraitImage: image.portrait_image,
                imageCropping: image.featured_image_cropping,
                portraitImageCropping: image.portrait_image_cropping,
                videoUrl: image.video_url,
              })),
              title: infoBlock.title,
              summary: infoBlock.summary,
              text: infoBlock.text,
              buttonLabel: infoBlock.button_label,
              imagePosition: infoBlock.image_position,
              imageOrientation: infoBlock.image_orientation,
              order: infoBlock.order,
              link: infoBlock.link,
              siteArea: infoBlock.site_area,
            })),
            termsConditions: brand.terms_conditions,
          },
          history: {
            // lookbookTemplate: brand.lookbook_template,
            // lookbookDescription: brand.lookbook_description,
            // lookbookImages: [], // TODO
            lookbooks: {
              description: brand.lookbook_description,
              template: brand.lookbook_template,
              images: brand.lookbook_images.map((image: any) => (
                {
                  id: image.id,
                  imageSrc: image.image_580_600,
                  originalImage: image.image_original,
                  imageCropping285: image.image_285_cropping,
                  imageCropping580: image.image_580_600_cropping,
                  title: image.title
                }
              ))
            },
            historyImage: `${brand.history.image}`,
            historyImageCropping: `${brand.history.image_cropping}`,
            historyImageOriginal: `${brand.history.image_original}`,
            details: brand.history.details,
            infoBlock: brand.history.info_blocks.map((infoBlock: any) => ({
              id: infoBlock.id,
              images: infoBlock.images.map((image: any) => ({
                id: image.id,
                imageResp: image.image_resp,
                featuredImage: image.featured_image,
                featuredImageResp: image.featured_image_resp,
                landscapeImage: image.landscape_image,
                portraitImage: image.portrait_image,

                originalImage: image.original_image,
                imageCropping: image.landscape_image_cropping,
                portraitImageCropping: image.portrait_image_cropping,
                videoUrl: image.video_url,
              })),
              title: infoBlock.title,
              summary: infoBlock.summary,
              text: infoBlock.text,
              buttonLabel: infoBlock.button_label,
              imagePosition: infoBlock.image_position,
              imageOrientation: infoBlock.image_orientation,
              order: infoBlock.order,
              link: infoBlock.link,
              siteArea: infoBlock.site_area,
            })),
            siteUrl: brand.site_url,
            facebookUrl: brand.facebook_url,
            instagramUrl: brand.instagram_url,
            twitterUrl: brand.twitter_url,
          },
          callToAction: brand.call_to_action
            ? {
                id: brand.call_to_action.id,
                buttonLabel: brand.call_to_action.button_label,
                image: brand.call_to_action.image,
                imageOriginal: brand.call_to_action.image_original,
                imageCropping: brand.call_to_action.image_cropping,
                imageMobile: brand.call_to_action.image_mobile,
                imageCroppingMobile: brand.call_to_action.image_cropping_mobile,
                link: brand.call_to_action.link,
                seller: brand.call_to_action.seller,
                text: brand.call_to_action.text,
                title: brand.call_to_action.title,
              }
            : undefined,
        };
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });

export async function toBase64(file?: File) {
  if (!file) {
    return "";
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const converterImage = async (file?: File) => {
  if (!file) {
    return null;
  }
  const arquivo = await toBase64(file);
  return arquivo;
};

export const updateBrandProfile =
  (httpClient: HttpClient) =>
  () =>
  async (params: BrandProfile): Promise<void> => {
    if (isMockApi) {
      return new Promise((resolve) => {
        console.log("[API] Update Brand Profile", params);
        setTimeout(resolve, 1000);
      });
    }

    const featuredInfoBlockImages = [];

    if (params.theBrand.featuredInfoBlock?.images) {
      for (
        let index = 0;
        index < params.theBrand.featuredInfoBlock?.images.length;
        index++
      ) {
        const image = params.theBrand.featuredInfoBlock?.images[index];
        featuredInfoBlockImages.push({
          ...image,
          nova_imagem: await toBase64(image.nova_imagem),
          nova_imagem_crop: image.crop,
          video_url: image.videoUrl,
        });
      }
    }

    let imagesBrandStyle = [];
    if(params.theBrand.brandStyle.images){
      for(let i = 0; i < params.theBrand.brandStyle.images.length; i++){
        imagesBrandStyle.push({
          id: params.theBrand.brandStyle.images[i].id,
          remover: params.theBrand.brandStyle.images[i].remover,
          nova_imagem: await toBase64(params.theBrand.brandStyle.images[i].novaImagem),
          nova_imagem_crop: params.theBrand.brandStyle.images[i].novaImagemCrop,
          title: params.theBrand.brandStyle.images[i].title,
          link: params.theBrand.brandStyle.images[i].link,
          button_label: params.theBrand.brandStyle.images[i].button_label
        })
      }
    }

    let imagesLookbook = [];
    if(params.history.lookbooks.images){
      for(let i = 0; i < params.history.lookbooks.images.length; i++){
        imagesLookbook.push({
          id: params.history.lookbooks.images[i].id,
          remover: params.history.lookbooks.images[i].remover,
          nova_imagem: await toBase64(params.history.lookbooks.images[i].novaImagem),
          nova_imagem_crop: params.history.lookbooks.images[i].novaImagemCrop,
          title: params.history.lookbooks.images[i].title,
          link: "",
          button_label: ""
        })
      }
    }

    return httpClient.put(`${API_URL}/v1/admin-seller/brand-page`, {
      name: params.name,
      cover_image: {
        ...params.coverImage,
        nova_imagem: await toBase64(params.coverImage.nova_imagem),
        nova_imagem_crop: params.coverImage.nova_imagem_crop,
      },
      logo_image: params.logoBrandPage,
      brand_from: params.theBrand.brandFrom,
      year_established: params.theBrand.yearEstablished,
      multibrand_retailers: params.theBrand.multibrandRetailers,
      multibrand_retailers_full: params.theBrand.multibrandRetailersFull,
      featured_info_block: params.theBrand.featuredInfoBlock
        ? {
            images: featuredInfoBlockImages,
            title: params.theBrand.featuredInfoBlock.title,
            summary: params.theBrand.featuredInfoBlock.summary,
            text: params.theBrand.featuredInfoBlock.text,
            button_label: params.theBrand.featuredInfoBlock.buttonLabel,
            link: params.theBrand.featuredInfoBlock.link,
            image_position: params.theBrand.featuredInfoBlock.imagePosition,
            image_orientation:
              params.theBrand.featuredInfoBlock.imageOrientation,
            order: params.theBrand.featuredInfoBlock.order,
          }
        : null,
      terms_conditions: params.theBrand.termsConditions,
      brand_style: {
        description: params.theBrand.brandStyle.description,
        template: params.theBrand.brandStyle.template,
        images: imagesBrandStyle
      },
      lookbook: {
        description: params.history.lookbooks.description,
        template: params.history.lookbooks.template,
        images: imagesLookbook
      },
      // lookbook_images
      history: {
        history_image: params.history.historyImage,
        nova_imagem: await toBase64(params.history.nova_imagem),
        nova_imagem_crop: params.history.nova_imagem_crop,
      },
      // details
      info_blocks: params.theBrand.infoBlock.map((infoBlock) => ({
        id: infoBlock.id,
        images: null,
        title: infoBlock.title,
        summary: infoBlock.summary,
        text: infoBlock.text,
        button_label: infoBlock.buttonLabel,
        image_position: infoBlock.imagePosition,
        image_orientation: infoBlock.imageOrientation,
        order: infoBlock.order,
        link: infoBlock.link,
        siteArea: infoBlock.siteArea,
      })),
      site_url: params.history.siteUrl,
      facebook_url: params.history.facebookUrl,
      instagram_url: params.history.instagramUrl,
      twitter_url: params.history.twitterUrl,
      call_to_action: params.callToAction
        ? {
            id: params.callToAction.id,
            button_label: params.callToAction.buttonLabel,
            image: params.callToAction.image,
            image_cropping: params.callToAction.imageCropping,
            nova_imagem: await toBase64(params.callToAction.nova_imagem),
            nova_imagem_crop: params.callToAction.nova_imagem_crop,
            image_mobile: params.callToAction.imageMobile,
            image_cropping_mobile: params.callToAction.imageCroppingMobile,
            link: params.callToAction.link,
            text: params.callToAction.text,
            title: params.callToAction.title,
          }
        : null,
    });
  };

/** Brand Infoblocks */
const toDomainInfoBlock = (
  infoblock: any
): InfoBlock & { featured: boolean; siteArea: number } => ({
  featured: infoblock.featured === 1,
  siteArea: infoblock.site_area,

  id: infoblock.id,
  title: infoblock.title,
  summary: infoblock.summary,
  text: infoblock.text,
  buttonLabel: infoblock.button_label,
  link: infoblock.link,
  order: infoblock.order,
  seller: infoblock.seller_id,
  imagePosition: infoblock.image_position,
  imageOrientation: infoblock.image_orientation,
  images: infoblock.images.map(
    (image: any): InfoblockImage => ({
      imageResp: `${STORAGE_URL}${image.image}`,
      landscapeImage: `${STORAGE_URL}${image.image}`,
      portraitImage: `${STORAGE_URL}${image.image}`,
      featuredImage: `${STORAGE_URL}${image.image}`,
      featuredImageResp: `${STORAGE_URL}${image.image}`,
    })
  ),
});
export const fetchBrandInfoBlocks =
  (httpClient: HttpClient) =>
  (
    brandId: BrandProfile["id"]
  ): Promise<(InfoBlock & { featured: boolean; siteArea: number })[]> =>
    httpClient
      .get(`${API_URL}/brandinfo/${brandId}`)
      .then((res) => res.data)
      .then((res) => res.map(toDomainInfoBlock));

export const createBrandInfoBlock =
  (httpClient: HttpClient) =>
  () =>
  (infoblock: InfoBlock): Promise<InfoBlock["id"]> =>
    httpClient
      .post(`${API_URL}/v1/admin-seller/brand-page/info-block`, {
        title: infoblock.title,
        summary: infoblock.summary,
        text: infoblock.text,
        button_label: infoblock.buttonLabel,
        featured: infoblock.featured ? 1 : 0,
        site_area: infoblock.siteArea,
        image_position: infoblock.imagePosition,
        image_orientation: infoblock.imageOrientation,
        order: infoblock.order,
        link: infoblock.link,
        images: infoblock.images,
        seller_id: infoblock.seller,
      })
      .then((res) => res.data);

export const updateBrandInfoBlock =
  (httpClient: HttpClient) =>
  (infoblockId: InfoBlock["id"]) =>
  async (infoblock: InfoBlock): Promise<{ id: InfoBlock["id"], images: InfoblockImage[] }> => {
    const infoBlockImages = [];

    if (infoblock.images) {
      for (
        let index = 0;
        index < infoblock.images.length;
        index++
      ) {
        const image = infoblock.images[index];
        infoBlockImages.push({
          id: image.id,
          crop: image.crop,
          url: image.url,
          imageResp: image.imageResp,
          landscapeImage: image.landscapeImage,
          portraitImage: image.portraitImage,
          nova_imagem: await toBase64(image.nova_imagem),
          nova_imagem_crop: image.nova_imagem_crop,
          remover: image.remover,
          video_url: image.videoUrl,
        });
      }
    }

    // console.log({
    //   title: infoblock.title,
    //   summary: infoblock.summary,
    //   text: infoblock.text,
    //   button_label: infoblock.buttonLabel,
    //   featured: infoblock.featured ? 1 : 0,
    //   site_area: infoblock.siteArea,
    //   image_position: infoblock.imagePosition || "left",
    //   image_orientation: infoblock.imageOrientation || "landscape",
    //   order: infoblock.order,
    //   link: infoblock.link,
    //   images: infoBlockImages,
    // }, 'requesttt') 

    return httpClient
      .put(`${API_URL}/v1/admin-seller/brand-page/info-blocks/${infoblockId}`, {
        title: infoblock.title,
        summary: infoblock.summary,
        text: infoblock.text,
        button_label: infoblock.buttonLabel,
        featured: infoblock.featured ? 1 : 0,
        site_area: infoblock.siteArea,
        image_position: infoblock.imagePosition || "left",
        image_orientation: infoblock.imageOrientation || "landscape",
        order: infoblock.order,
        link: infoblock.link,
        images: infoBlockImages,
      })
      .then((res) => {
        return(
          {
            id: res.data.id,
            images: res.data.images.map((image: any) => ({
              id: image.id,
              imageResp: image.image_resp,
              featuredImage: image.featured_image,
              featuredImageResp: image.featured_image_resp,
              originalImage: image.original_image,
              landscapeImage: image.landscape_image,
              portraitImage: image.portrait_image,
              imageCropping: image.featured_image_cropping,
              portraitImageCropping: image.portrait_image_cropping,
              videoUrl: image.video_url,
            }))
          }
        )
      });
  };

export const removeBrandInfoBlock =
  (httpClient: HttpClient) =>
  (infoBlockId: InfoBlock["id"]): Promise<void> =>
    httpClient.delete(
      `${API_URL}/v1/admin-seller/brand-page/info-blocks/${infoBlockId}`
    );

export const submitForReview =
  (httpClient: HttpClient) =>
  (): Promise<void> =>
    httpClient.post(
      `${API_URL}/v1/admin-seller/brand-page/request-approval`
    );

/** Brand History Details */
export const fetchBrandHistoryDetails =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]): Promise<BrandProfileHistoryDetails[]> =>
    httpClient
      .get(`${API_URL}/branddetails/${brandId}`)
      .then((res) => res.data)
      .then((res) =>
        res.map(
          (historyDetail: any): BrandProfileHistoryDetails => ({
            id: historyDetail.id,
            title: historyDetail.title,
            text: historyDetail.text,
            order: historyDetail.order,
            seller: historyDetail.seller_id,
          })
        )
      );

export const createBrandHistoryDetail =
  (httpClient: HttpClient) =>
  () =>
  (
    historyDetail: BrandProfileHistoryDetails
  ): Promise<BrandProfileHistoryDetails["id"]> =>
    httpClient
      .post(`${API_URL}/branddetails`, {
        title: historyDetail.title,
        text: historyDetail.text,
        order: historyDetail.order,
        seller_id: historyDetail.seller,
        edit_by_seller: 0,
      })
      .then((res) => res.data);

export const updateBrandHistoryDetail =
  (httpClient: HttpClient) =>
  (detailId: BrandProfileHistoryDetails["id"]) =>
  (
    historyDetail: BrandProfileHistoryDetails
  ): Promise<BrandProfileHistoryDetails> =>
    httpClient
      .put(`${API_URL}/v1/admin-seller/brand-page/details/${detailId}`, {
        title: historyDetail.title,
        text: historyDetail.text,
        order: historyDetail.order,
      })
      .then((res) => res.data);

export const removeBrandHistoryDetail =
  (httpClient: HttpClient) =>
  (detailId: BrandProfileHistoryDetails["id"]): Promise<void> =>
    httpClient.delete(
      `${API_URL}/v1/admin-seller/brand-page/details/${detailId}`
    );

/** Brand Footer */
export const fetchBrandFooter =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]): Promise<BrandFooter[]> =>
    httpClient
      .get(`${API_URL}/brandcta/${brandId}`)
      .then((res) => res.data)
      .then((res) =>
        res.map((cta: any) => ({
          id: cta.id,
          title: cta.title,
          text: cta.text,
          image: `${STORAGE_URL}${cta.image}`,
        }))
      );

export const updateBrandFooter =
  (httpClient: HttpClient) =>
  (footerId: number) =>
  (footer: Omit<BrandFooter, "id">): Promise<BrandFooter[]> =>
    httpClient
      .put(`${API_URL}/brandcta/${footerId}`, {
        title: footer.title,
        text: footer.text,
        image: footer.image.replace(new RegExp(`${STORAGE_URL}`, "g"), ""),
      })
      .then((res) => res.data);

import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

export const RichTextEditor: React.FC<{
    name?: string;
    value: string;
    onChange: (value: { target: { value: string } }) => void;
}> = props => {
    const onChange = (val: string) => {
        const change = {
            target: {
                value: val,
            },
        };

        if (props.name) {
            (props.onChange as any)(props.name)(change);
        } else {
            props.onChange(change);
        }
    };

    return <ReactQuill theme='snow' value={props.value} onChange={onChange} />;
};

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { useState } from "react";

import { noop, stopPropagation } from "../helpers";
import { Modal } from "../Modal";

import { getCroppedImg } from "./getCroppedImg";
import { Dimensions } from "./ImageditEditor";
import { ImageditImage } from "./ImageditImage.model";
import { ImageditModal } from "./ImageditModal";
import { VideoUrlHandler } from "./VideoUrl";

import "./Imagedit.scss";

export interface ImageditRenderProps {
  isHovered: boolean;
}

interface ImageditProps {
  allowEmpty?: boolean;
  className?: string;
  desiredDimensions: Dimensions;
  multiple?: boolean;
  render: (props: ImageditRenderProps) => React.ReactNode;
  onClose?: () => void;
  onSave: (images: ImageditImage[]) => void;
  overlay?: () => React.ReactElement<any>;
  videoUrlHandler?: VideoUrlHandler;
  title: string;
  src: ImageditImage | ImageditImage[];
  zIndex?: number;
  noCrop?: boolean;
  helpDimensions?: Dimensions;
  imageLimit?: number;
  openIndex?: number;
}
export const Imagedit: React.FC<ImageditProps> = (props) => {
  const {
    desiredDimensions,
    noCrop,
    render,
    onClose,
    onSave,
    videoUrlHandler,
    src,
    title,
    helpDimensions,
    imageLimit,
    openIndex,
  } = props;
  const allowEmpty = props.allowEmpty ?? true;
  const className = props.className ?? "";
  const multiple = props.multiple ?? false;
  const zIndex = props.zIndex ?? 1;

  const overlay = props.overlay ? (
    React.cloneElement(props.overlay(), {
      onMouseOver: stopPropagation(noop),
      onMouseOut: stopPropagation(() => setHovered(false)),
    })
  ) : (
    <div
      className="imagedit-overlay"
      onMouseOver={stopPropagation(noop)}
      onMouseOut={stopPropagation(() => setHovered(false))}
      onClick={stopPropagation(() => setOpened(true))}
      style={{ zIndex: zIndex + 1 }}
    >
      <FontAwesomeIcon icon={faPencilAlt} />
    </div>
  );

  const [isHovered, setHovered] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const child = render({ isHovered });

  const onModalClose = stopPropagation(() => {
    setHovered(false);
    setOpened(false);
    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      setOpened(false); // TODO: fix this horrible shit
    }, 100);
  });

  const onModalSave = async (images: ImageditImage[]) => {
    // const croppedImg = await getCroppedImg(
    //     images[0].src,
    //     { x: 50, y: 100, width: 300, height: 300 },
    //     'test.jpg',
    // );
    // console.log(croppedImg);

    onSave(images);
    onModalClose();
  };

  return (
    <div
      className={classnames("imagedit", className)}
      onMouseOver={stopPropagation(() => setHovered(true))}
      onClick={stopPropagation(() => setOpened(true))}
      style={{ zIndex }}
    >
      {child}

      {isHovered ? overlay : null}

      <Modal
        isOpen={isOpened}
        contentLabel={title}
        onRequestClose={onModalClose}
      >
        <ImageditModal
          allowEmpty={allowEmpty}
          desiredDimensions={desiredDimensions}
          multiple={multiple}
          title={title}
          src={src}
          onSave={onModalSave}
          videoUrlHandler={videoUrlHandler}
          noCrop={noCrop || false}
          helpDimensions={helpDimensions}
          imageLimit={imageLimit || 8}
          openIndex={openIndex || 0}
        />
      </Modal>
    </div>
  );
};

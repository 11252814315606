import classnames from "classnames";
import React from "react";
import { LazyImage } from "react-lazy-images";

import "./ListCard.scss";

interface ListCardProps {
  className?: string;
  title: string;
  image: string;
  onClick?: () => void;
}

export const ListCard: React.FC<ListCardProps> = (props) => {
  return (
    <div
      className={classnames("list-card", props.className)}
      onClick={props.onClick}
    >
      <div className="list-card-image-wrapper">
        <LazyImage
          src={props.image}
          alt="Buildings with tiled exteriors, lit by the sunset."
          placeholder={({ imageProps, ref }) => (
            <img
              className="list-card-image"
              ref={ref}
              alt={imageProps.alt}
            />
          )}
          actual={({ imageProps }) => (
            <img {...imageProps} className="list-card-image" />
          )}
        />
      </div>
      <div className="list-card-title">{props.title}</div>
      {props.children}
    </div>
  );
};

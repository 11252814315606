import { API_URL, isMockApi } from "../config";
import { BrandProfile } from "../domain/BrandProfile";
import { Size } from "../domain/Product";
import { NewEntity, randomInt } from "../helpers";

import { Sizes } from "./__fixtures__/sizes";
import { HttpClient } from "./http-client";

const SIZES_API_URL = `${API_URL}/brandsizes`;

export const fetchSizes =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]): Promise<Size[]> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Fetch sizes for BrandID", brandId);
          setTimeout(() => {
            resolve(Sizes);
          }, 1000);
        })
      : httpClient
          .get(`${API_URL}/v1/admin-seller/sizes`)
          .then((res) => res.data)
          .then((sizeMap) => Object.values(sizeMap))
          .then((sizes) =>
            sizes.map((size: any) => ({
              id: size.id,
              name: size.name,
              shortName: size.short_name,
              order: size.order,
            }))
          );

export const createSize =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]) =>
  (size: NewEntity<Size>): Promise<{ id: Size["id"]; order: Size["order"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log(
            "[API] Create size for BrandID",
            brandId,
            "and data",
            size
          );
          setTimeout(() => {
            resolve({ id: randomInt(50, 99), order: 0 });
          }, 1000);
        })
      : httpClient
          .post(`${API_URL}/v1/admin-seller/sizes`, {
            name: size.name,
            short_name: size.shortName,
            order: size.order,
          })
          .then((res) => res.data);

export const updateSize =
  (httpClient: HttpClient) =>
  (id: Size["id"]) =>
  (params: NewEntity<Size>): Promise<{ id: Size["id"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Update size for ID`", id, "and data", params);
          setTimeout(() => {
            resolve({ id });
          }, 1000);
        })
      : httpClient.put(`${API_URL}/v1/admin-seller/sizes/${id}`, {
          name: params.name,
          short_name: params.shortName,
          order: params.order,
        });

export const deleteSize =
  (httpClient: HttpClient) =>
  (id: Size["id"]): Promise<void> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Delete size ID`", id);
          setTimeout(() => {
            resolve();
          }, 1000);
        })
      : httpClient.delete(`${API_URL}/v1/admin-seller/sizes/${id}`);

// export const deleteSize = (httpClient: HttpClient) => (id: Size['id']): Promise<void> =>
//     isMockApi
//         ? new Promise((resolve, reject) => {
//               console.log('[API] Delete size', id);
//               setTimeout(() => {
//                   resolve();
//               }, 1000);
//           })
//         : httpClient.delete(`${SIZES_API_URL}/${id}`);

import { createSlice, createEntityAdapter, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { CollectionProducts } from '../../components/Collection/CollectionProducts';

import { Collection, CollectionProduct } from '../../domain/Collection';
import { Product } from '../../domain/Product';
import { ProductSummary } from '../../domain/ProductList';
import { RootState } from '../../RootState';
import { moveItem } from '../common/OrderableGrid/moveItem';
import {
    selectProductList,
    selectProductListCollection,
} from '../Products/state/productList.state';

const makeNewCollection = (): CollectionState => ({
    id: -1,
    sellerId: -1,
    isNew: true,
    name: '',
    isActive: false,
    isShopifyActive: false,
    listImageThumbnail: '',
    orderWindowStart: new Date(),
    orderWindowEnd: new Date(),
    productionWindowStart: new Date(),
    productionWindowEnd: new Date(),
    lookbookUrl: '',
    listImage: '',
    typeButton: 'o',
    deliveryMsg: '',
    currency: '',
    pressRelease: '',
    created: new Date(),
    updated: new Date(),
    linesheetImage: '',
    linesheetText: '',
    linesheetTemplate: 1,
    linesheetCover: '',
    linesheetMoodboard: '',
    linesheetBackCover: '',
    linesheetShowVariation: false,
    collectionType: 'future_delivery',
    markup: 0,
    wholesalePriceTip: '',
    retailerPriceTip: '',
    products: [],
});

/** Reducer */
export interface CollectionState extends Collection {
    isNew?: boolean;
}

const collectionsAdapter = createEntityAdapter<CollectionState>();
export const collectionsSlice = createSlice({
    name: 'collections',
    initialState: collectionsAdapter.getInitialState(),
    reducers: {
        fetched: collectionsAdapter.setAll,
        fetchedOne: {
            reducer: collectionsAdapter.setAll,
            prepare: (collection: Collection) => ({
                payload: [{ ...collection }],
            }),
        },
        // fetchedProducts: {
        //     reducer: collectionsAdapter.updateOne,
        //     prepare: (args: { id: CollectionState['id']; products: CollectionProduct[] }) => ({
        //         payload: {
        //             id: args.id,
        //             changes: { products: args.products as any },
        //         },
        //     }),
        // },
        added: {
            reducer: collectionsAdapter.addOne,
            prepare: () => ({
                payload: makeNewCollection(),
            }),
        },
        removed: collectionsAdapter.removeOne,
        nameChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: { id: CollectionState['id']; name: CollectionState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        orderWindowStartChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                orderWindowStart: CollectionState['orderWindowStart'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { orderWindowStart: args.orderWindowStart },
                },
            }),
        },
        orderWindowEndChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                orderWindowEnd: CollectionState['orderWindowEnd'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { orderWindowEnd: args.orderWindowEnd },
                },
            }),
        },
        productionWindowStartChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                productionWindowStart: CollectionState['productionWindowStart'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { productionWindowStart: args.productionWindowStart },
                },
            }),
        },
        productionWindowEndChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                productionWindowEnd: CollectionState['productionWindowEnd'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { productionWindowEnd: args.productionWindowEnd },
                },
            }),
        },
        lookbookUrlChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                lookbookUrl: CollectionState['lookbookUrl'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { lookbookUrl: args.lookbookUrl },
                },
            }),
        },
        listImageChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                listImage: CollectionState['listImage'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { listImage: args.listImage },
                },
            }),
        },
        typeButtonChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                typeButton: CollectionState['typeButton'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { typeButton: args.typeButton },
                },
            }),
        },
        deliveryMsgChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                deliveryMsg: CollectionState['deliveryMsg'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { deliveryMsg: args.deliveryMsg },
                },
            }),
        },
        currencyChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                currency: CollectionState['currency'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { currency: args.currency },
                },
            }),
        },
        pressReleaseChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                pressRelease: CollectionState['pressRelease'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { pressRelease: args.pressRelease },
                },
            }),
        },
        linesheetImageChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetImage: CollectionState['linesheetImage'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetImage: args.linesheetImage },
                },
            }),
        },
        linesheetTextChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetText: CollectionState['linesheetText'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetText: args.linesheetText },
                },
            }),
        },
        linesheetTemplateChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetTemplate: CollectionState['linesheetTemplate'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetTemplate: args.linesheetTemplate },
                },
            }),
        },
        linesheetCoverChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetCover: CollectionState['linesheetCover'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetCover: args.linesheetCover },
                },
            }),
        },
        linesheetMoodboardChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetMoodboard: CollectionState['linesheetMoodboard'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetMoodboard: args.linesheetMoodboard },
                },
            }),
        },
        linesheetBackCoverChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetBackCover: CollectionState['linesheetBackCover'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetBackCover: args.linesheetBackCover },
                },
            }),
        },
        linesheetShowVariationChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                linesheetShowVariation: CollectionState['linesheetShowVariation'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { linesheetShowVariation: args.linesheetShowVariation },
                },
            }),
        },
        collectionTypeChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                collectionType: CollectionState['collectionType'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { collectionType: args.collectionType },
                },
            }),
        },
        markupChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: { id: CollectionState['id']; markup: CollectionState['markup'] }) => ({
                payload: {
                    id: args.id,
                    changes: { markup: args.markup },
                },
            }),
        },
        wholesalePriceTipChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                wholesalePriceTip: CollectionState['wholesalePriceTip'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { wholesalePriceTip: args.wholesalePriceTip },
                },
            }),
        },
        retailerPriceTipChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                retailerPriceTip: CollectionState['retailerPriceTip'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { retailerPriceTip: args.retailerPriceTip },
                },
            }),
        },
        isActiveChanged: {
            reducer: collectionsAdapter.updateOne,
            prepare: (args: {
                id: CollectionState['id'];
                isActive: CollectionState['isActive'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { isActive: args.isActive },
                },
            }),
        },

        moveProduct: (
            state,
            action: PayloadAction<{
                id: CollectionState['id'];
                sourceId: Product['id'];
                destinationId: Product['id'];
            }>,
        ) => {
            return state;
            // const products = state?.entities[action.payload.id]?.products;
            // if (!products) {
            //     return state;
            // }

            // const changes = state.entities[action.payload.id]
            //     ? {
            //           products: moveItem(products)(action.payload),
            //       }
            //     : {};
            // return collectionsAdapter.updateOne(state, {
            //     id: action.payload.id,
            //     changes,
            // });
        },
        addProduct: (
            state,
            action: PayloadAction<{
                id: CollectionState['id'];
                productId: Product['id'];
            }>,
        ) => {
            return state;
            // const products = state?.entities[action.payload.id]?.products;
            // if (!products) {
            //     return state;
            // }

            // if (products.find(productId => productId === action.payload.productId)) {
            //     return state;
            // }

            // return collectionsAdapter.updateOne(state, {
            //     id: action.payload.id,
            //     changes: {
            //         products: [...products, action.payload.productId],
            //     },
            // });
        },
        removeProduct: (
            state,
            action: PayloadAction<{
                id: CollectionState['id'];
                productId: Product['id'];
            }>,
        ) => {
            return state;
            // const products = state?.entities[action.payload.id]?.products;
            // if (!products) {
            //     return state;
            // }

            // return collectionsAdapter.updateOne(state, {
            //     id: action.payload.id,
            //     changes: {
            //         products: products.filter(productId => productId !== action.payload.productId),
            //     },
            // });
        },
    },
});

/** Actions */
export const collectionsActions = collectionsSlice.actions;

/** Selectors */
const collectionsSelectors = collectionsAdapter.getSelectors(
    (state: RootState) => state.collections,
);

export const selectCollections = collectionsSelectors.selectAll;
export const selectCollectionsCollection = collectionsSelectors.selectEntities;
export const selectCollection = (id: CollectionState['id']) => (
    state: RootState,
): CollectionState | undefined => state && collectionsSelectors.selectById(state, id);

// export const selectCollectionProductList = (id: CollectionState['id']) =>
//     createSelector(
//         selectCollection(id),
//         selectProductListCollection,
//         (collection, productList) =>
//             collection?.products?.map(productId => productList[productId as any]) || [],
//     );

import { useHistory } from "react-router-dom";

const entityRoutes = (basePath: string) => (history: any) => ({
  navigateToList: () => history.push(`/${basePath}s`),
  navigateById: (id: number) => history.push(`/${basePath}/${id}`),
  navigateToCreate: () => history.push(`/create-${basePath}`),
  reload: () => history.go(0),
  listUrl: `/${basePath}s`,
  byIdUrl: `/${basePath}/:id`,
  createUrl: `/create-${basePath}`,
});

export const useRoutes = () => {
  const history = useHistory();
  return {
    login: "/login",

    // colors: entityRoutes('/colors')(history),
    sizecharts: entityRoutes("sizechart")(history),
    measurements: entityRoutes("measurement")(history),
    sizes: entityRoutes("size")(history),
    // textures: entityRoutes('/textures')(history),

    colorsAndTextures: entityRoutes("colors-texture")(history),

    brandPage: {
      url: "/brand-page",
      navigateTo: () => history.push(`/brand-page`),
    },
    collections: entityRoutes("collection")(history),
    dashboard: {
      url: "/dashboard",
      navigateTo: () => history.push(`/dashboard`),
    },
    products: entityRoutes("product")(history),
  };
};

import Axios from 'axios'
import TagManager from 'react-gtm-module'
import { API_URL } from '../config';

export default async function registerAccessGtm() {
    const userData = JSON.parse(sessionStorage.getItem('userData'))
    const sellerData = await getSellerData()
    const gtm_id_admin_seller = localStorage.getItem('gtm_id_admin_seller');

    TagManager.initialize({
        gtmId: `${gtm_id_admin_seller}`,
        dataLayer: {
            'userData': {
                'first_name': userData?.first_name,
                'last_name': userData?.last_name,
                'id': userData?.id,
                'entity_stype': userData ? 'seller' : null,
                'entity_id': userData?.seller_id,
                'email': userData?.email,
                'phone': userData?.phone,
                'is_staff': userData?.is_staff,
                'is_superadmin': userData?.is_superuser,
            },
            'accountData': {
                'id': userData?.account_id,
                'name': localStorage.getItem('X-Account-Name'),
            },
            'sellerData': {
                'id': sellerData?.id,
                'name': sellerData?.name,
                'is_active': sellerData?.is_active,
                'id_categories': sellerData?.id_categories,
                'categories': sellerData?.categories,
                'id_segments': sellerData?.id_segments,
                'segments': sellerData?.segments,
                'id_events': sellerData?.id_events,
                'events': sellerData?.events,
            }
        }
    })
}

async function getSellerData() {
    const sellerData = JSON.parse(sessionStorage.getItem('sellerData'))

    if (sellerData) {
        return sellerData
    }


    try {
        const { data } = await Axios.get(`${API_URL}/v1/admin-seller/brand-page/seller-data`)

        sessionStorage.setItem('sellerData', JSON.stringify(data.data))

        return data.data
    } catch(err) {
        console.error('acesso de dados para o gtm', err)

        return null
    }
}

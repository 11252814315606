import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useApi } from "../api/useApi";
import { noop } from "../external/common/helpers";
import { MeasurementList } from "../external/Measurement/MeasurementList";
import { measurementsActions } from "../external/Measurement/measurements.state";
import { useAsync } from "../external/useAsync";

import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import registerAccessGtm from "../services/gtmRegister";

export const MeasurementListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, fetchMeasurements } = useApi();
  const { t } = useTranslation();

  const { isPending, isSuccess, isFailure, start } =
    useAsync(fetchMeasurements);
  useEffect(() => {
    start(user.brandId)
      .then((measurements) => {
        dispatch(measurementsActions.fetched(measurements));
      })
      .catch(noop);
  }, []);

  useEffect(() => {
    registerAccessGtm()
  }, [])

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return (
      <PageError message={t("Error fetching data", "Error fetching data")} />
    );
  }

  if (isSuccess) {
    return (
      <Page className="measurement-management">
        <div className="page-title">
          {t("Measurement management", "Measurement management")}
        </div>
        <MeasurementList />
      </Page>
    );
  }

  return null;
};

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Sizechart } from '../../domain/Sizechart';
import { RootState } from '../../RootState';

interface NewSizechartArgs {
    id: Sizechart['id'];
    name: Sizechart['name'];
}

const makeNewSizechart = (args: NewSizechartArgs): SizechartState => ({
    id: args.id,
    isNew: true,
    name: args.name,
    image: undefined,
    chart: undefined,
});

/** Reducer */
export interface SizechartState extends Sizechart {
    isNew?: boolean;
}

const sizechartsAdapter = createEntityAdapter<SizechartState>();
export const sizechartsSlice = createSlice({
    name: 'sizecharts',
    initialState: sizechartsAdapter.getInitialState(),
    reducers: {
        fetched: sizechartsAdapter.setAll,
        added: {
            reducer: sizechartsAdapter.addOne,
            prepare: (args: NewSizechartArgs) => ({
                payload: makeNewSizechart(args),
            }),
        },
        nameChanged: {
            reducer: sizechartsAdapter.updateOne,
            prepare: (args: { id: SizechartState['id']; name: SizechartState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        imageChanged: {
            reducer: sizechartsAdapter.updateOne,
            prepare: (args: { id: SizechartState['id']; image: SizechartState['image'] }) => ({
                payload: {
                    id: args.id,
                    changes: { image: args.image },
                },
            }),
        },
        chartChanged: {
            reducer: sizechartsAdapter.updateOne,
            prepare: (args: { id: SizechartState['id']; chart: SizechartState['chart'] }) => ({
                payload: {
                    id: args.id,
                    changes: { chart: args.chart },
                },
            }),
        },
        removed: sizechartsAdapter.removeOne,
    },
});

/** Actions */
export const sizechartsActions = sizechartsSlice.actions;

/** Selectors */
const sizechartsSelectors = sizechartsAdapter.getSelectors((state: RootState) => state.sizecharts);

export const selectSizecharts = sizechartsSelectors.selectAll;
export const selectSizechartsCollection = sizechartsSelectors.selectEntities;
export const selectSizechart = (id: SizechartState['id']) => (
    state: RootState,
): SizechartState | undefined => sizechartsSelectors.selectById(state, id);

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Measurement } from '../../domain/Measurement';
import { RootState } from '../../RootState';

interface NewMeasurementArgs {
    id: Measurement['id'];
    name: Measurement['name'];
}

const makeNewMeasurement = (args: NewMeasurementArgs): MeasurementState => ({
    id: args.id,
    isNew: true,
    name: args.name,
});

/** Reducer */
export interface MeasurementState extends Measurement {
    isNew?: boolean;
}

const measurementAdapter = createEntityAdapter<MeasurementState>();
export const measurementSlice = createSlice({
    name: 'measurement',
    initialState: measurementAdapter.getInitialState(),
    reducers: {
        fetched: measurementAdapter.setAll,
        added: {
            reducer: measurementAdapter.addOne,
            prepare: (args: NewMeasurementArgs) => ({
                payload: makeNewMeasurement(args),
            }),
        },
        nameChanged: {
            reducer: measurementAdapter.updateOne,
            prepare: (args: { id: MeasurementState['id']; name: MeasurementState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        removed: measurementAdapter.removeOne,
    },
});

/** Actions */
export const measurementsActions = measurementSlice.actions;

/** Selectors */
const measurementsSelectors = measurementAdapter.getSelectors(
    (state: RootState) => state.measurements,
);

export const selectMeasurements = measurementsSelectors.selectAll;
export const selectMeasurementsCollection = measurementsSelectors.selectEntities;
export const selectMeasurement = (id: MeasurementState['id']) => (
    state: RootState,
): MeasurementState | undefined => measurementsSelectors.selectById(state, id);

import classNames from "classnames";
import React from "react";

import "./YoutubeEmbedVideo.scss";

export const YoutubeEmbedVideo: React.FC<{
  src: string;
  className?: string;
}> = ({ src, className }) => {
  return (
    <iframe src={src} className={classNames("youtube-container", "youtube-container--slider", className)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  );
};

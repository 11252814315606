import { API_URL, isMockApi } from '../config';
import { BrandProfile } from '../domain/BrandProfile';
import { Measurement } from '../domain/Measurement';
import { NewEntity, randomInt } from '../helpers';

import { Measurements } from './__fixtures__/measurements';
import { HttpClient } from './http-client';

const MEASUREMENTS_API_URL = `${API_URL}/v1/admin-seller/measurements`;

export const fetchMeasurements = (httpClient: HttpClient) => (
    brandId: BrandProfile['id'],
): Promise<Measurement[]> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch measurements for BrandID', brandId);
              setTimeout(() => {
                  resolve(Measurements);
              }, 1000);
          })
        : // TODO: check api response
          httpClient
              .get(`${MEASUREMENTS_API_URL}`)
              .then(res => res.data)
              .then(measurements =>
                  measurements.map((measurement: any) => ({
                      id: measurement.id,
                      name: measurement.name,
                  })),
              );

export const createMeasurement = (httpClient: HttpClient) => () => (
    params: NewEntity<Measurement>,
): Promise<{ id: Measurement['id'] }> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Create measurement for BrandID and data', params);
              setTimeout(() => {
                  resolve({ id: randomInt(50, 99) });
              }, 1000);
          })
        : // TODO: check measurement create
          httpClient.post(`${MEASUREMENTS_API_URL}`, params).then((res) => res.data);

export const updateMeasurement = (httpClient: HttpClient) => (id: Measurement['id']) => (
    params: NewEntity<Measurement>,
): Promise<{ id: Measurement['id'] }> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Update measurement for ID`', id, 'and data', params);
              setTimeout(() => {
                  resolve({ id });
              }, 1000);
          })
        : // TODO: check measurement update
          httpClient.put(`${MEASUREMENTS_API_URL}/${id}`, {
              name: params.name,
          });

export const deleteMeasurement = (httpClient: HttpClient) => (
    id: Measurement['id'],
): Promise<void> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Delete measurement', id);
              setTimeout(() => {
                  resolve();
              }, 1000);
          })
        : httpClient.delete(`${MEASUREMENTS_API_URL}/${id}`);

import { API_URL, isMockApi } from '../config';
import { BrandProfile } from '../domain/BrandProfile';
import { Linesheet } from '../domain/Linesheet';

import { Linesheets } from './__fixtures__/linesheets';
import { HttpClient } from './http-client';

export const fetchLinesheets = (httpClient: HttpClient) => (
    brandId: BrandProfile['id'],
): Promise<Linesheet[]> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch linesheets for BrandID', brandId);
              setTimeout(() => {
                  resolve(Linesheets);
              }, 1000);
          })
        : httpClient.get(`${API_URL}/linesheets/${brandId}`);

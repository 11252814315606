import {
    createSlice,
    createEntityAdapter,
    ThunkAction,
    Action,
    createSelector,
    PayloadAction,
} from '@reduxjs/toolkit';

import {
    ProductVariation,
    ProductVariationColor,
    ProductVariationTexture,
    ProductVariationImage,
} from '../../../domain/Product';
import { RootState } from '../../../RootState';
import { selectColorsCollection, ColorState } from '../../Color/colors.state';
import { TextureState, selectTexturesCollection } from '../../Texture/textures.state';

import { stocksActions, StockState } from './stocks.state';

/** Reducer */

export interface VariationState extends Omit<ProductVariation, 'color' | 'texture' | 'stock'> {
    isNew?: boolean;
    order?: number;
    color: ProductVariationColor | undefined;
    texture: ProductVariationTexture | undefined;
    stock: StockState['id'][];
}

export const makeNewVariation = (): VariationState => ({
    id: Math.random(),
    isNew: true,
    color: undefined,
    texture: undefined,
    label: '',
    images: [],
    stock: [],
    minQuantity: 0,
    maxQuantity: 0,
});

const stocksAdapter = createEntityAdapter<StockState>();
const variationsAdapter = createEntityAdapter<VariationState>();
export const variationsSlice = createSlice({
    name: 'variations',
    initialState: variationsAdapter.getInitialState(),
    reducers: {
        variationsFetched: variationsAdapter.setAll,
        variationAdded: variationsAdapter.addOne,
        variationChanged: variationsAdapter.updateOne,
        variationRemoved: variationsAdapter.removeOne,
        changeColor: (
            state,
            action: PayloadAction<{ id: VariationState['id']; color: ColorState | null }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { color: action.payload.color || undefined },
            }),
        changeTexture: (
            state,
            action: PayloadAction<{
                id: VariationState['id'];
                texture: TextureState | null;
            }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { texture: action.payload.texture || undefined },
            }),
        changeImages: (
            state,
            action: PayloadAction<{
                id: VariationState['id'];
                images: ProductVariationImage[];
            }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { images: action.payload.images },
            }),
        changeMinQuantity: (
            state,
            action: PayloadAction<{
                id: VariationState['id'];
                minQuantity: ProductVariation['minQuantity'];
            }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { minQuantity: action.payload.minQuantity },
            }),
        changeMaxQuantity: (
            state,
            action: PayloadAction<{
                id: VariationState['id'];
                maxQuantity: ProductVariation['maxQuantity'];
            }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { maxQuantity: action.payload.maxQuantity },
            }),
    },
    extraReducers: {
        [stocksActions.stockAdded.type]: (state, action: PayloadAction<any>) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.variationId,
                changes: {
                    stock: [
                        ...(state.entities[action.payload.variationId] as any).stock,
                        action.payload.stock.id,
                    ],
                },
            }),
        [stocksActions.stockRemoved.type]: (
            state,
            action: PayloadAction<{
                variationId: VariationState['id'];
                id: StockState['id'];
            }>,
        ) =>
            variationsAdapter.updateOne(state, {
                id: action.payload.variationId,
                changes: {
                    stock: (state.entities[action.payload.variationId] as any).stock.filter(
                        (stockId: StockState['id']) => stockId !== action.payload.id,
                    ),
                },
            }),
    },
});
export const variationsActions = {
    ...variationsSlice.actions,
    addVariation: (size?: number): ThunkAction<void, RootState, unknown, Action<void>> => {
        const variation = makeNewVariation();
        variation.order = size
        return dispatch => {
            dispatch((variationsActions as any).variationAdded(variation));
        };
    },
};

/** Selectors */
const variationsSelectors = variationsAdapter.getSelectors((state: RootState) => state.variations);

export const selectVariations = variationsSelectors.selectAll;
export const selectVariationsCollection = variationsSelectors.selectEntities;
export const selectVariation = (id: VariationState['id']) =>
    createSelector(
        (state: RootState) => variationsSelectors.selectById(state, id),
        selectColorsCollection,
        selectTexturesCollection,
        (variation, colors, textures) => {
            if (!variation) {
                return undefined;
            }
            return {
                ...variation,
                color: variation.color ? colors[variation.color.id] : undefined,
                texture: variation.texture ? textures[variation.texture.id] : undefined,
            };
        },
    );

import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

export const CroppableImage: React.FC<ReactCrop.ReactCropProps> = (props) => {
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    unit: "%" as const,
    aspect: props.crop?.aspect,
    x: props.crop?.x,
    y: props.crop?.y,
    width: props.crop?.width,
    height: props.crop?.height,
  });

  useEffect(() => {
    setCrop({
      unit: "%" as const,
      aspect: props.crop?.aspect,
      x: props.crop?.x,
      y: props.crop?.y,
      width: props.crop?.width,
      height: props.crop?.height,
    })
  }, [props.src])

  return (
    <ReactCrop
      src={props.src}
      crop={crop}
      onChange={(newCrop, newPercentCrop) => {
        setCrop({ ...newPercentCrop });
        props.onChange({ ...newCrop }, { ...newPercentCrop, unit: "%" });
      }}
    // onComplete={props.onComplete}
    />
  );
};

export type Crop = ReactCrop.Crop;

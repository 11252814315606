import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MUIMenu from "@material-ui/core/Menu";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconDefinition } from "../../common";

import "./Menu.scss";

interface MenuRenderProps {
  close: () => void;
  t: any;
}

export const Menu: React.FC<{
  icon: IconDefinition;
  render: (args: MenuRenderProps) => JSX.Element;
}> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <FontAwesomeIcon
        icon={props.icon}
        className="menu-button"
        onClick={handleClick}
      />
      <MUIMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.render({ close: handleClose, t })}
      </MUIMenu>
    </>
  );
};

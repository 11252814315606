import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BrandProfile } from "../../domain/BrandProfile";
import { stopPropagation } from "../common/helpers";
import { Modal, ModalActions, ModalTitle } from "../common/Modal";
import { PrimaryButton } from "../common/PrimaryButton";
import { TextInput } from "../common/TextInput";

import "./SocialLinks.scss";

interface SocialMediaLink {
  url: string;
  icon: string;
  alt: string;
  title: string;
  path: string;
}

const HistorySocialMediaModal: React.FC<{
  links: SocialMediaLink[];
  onClose: () => void;
  onSave: (link: SocialMediaLink[]) => void;
}> = (props) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState(props.links);
  const { t } = useTranslation();

  const onChange = (path: string) => (url: string) => {
    const newLinks = socialMediaLinks.map((link) =>
      link.path === path
        ? {
            ...link,
            url,
          }
        : link
    );
    setSocialMediaLinks(newLinks);
  };

  return (
    <Modal
      isOpen={true}
      contentLabel="History social media"
      onRequestClose={stopPropagation(() => props.onClose())}
    >
      <ModalTitle>{t("Social media links", "Social media links")}</ModalTitle>

      {socialMediaLinks.map((link) => (
        <div key={link.icon} className="history-social-media-modal-field">
          <img
            src={link.icon}
            alt={link.alt}
            className="history-social-media-modal-icon"
          />
          <TextInput
            name={link.path}
            value={link.url}
            label={link.title}
            onChange={(value) => onChange(link.path)(value)}
          />
        </div>
      ))}

      <ModalActions>
        <PrimaryButton
          onClick={stopPropagation(() => props.onSave(socialMediaLinks))}
        >
          {t("Save", "Save")}
        </PrimaryButton>
      </ModalActions>
    </Modal>
  );
};

export const HistorySocialMedia: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [isEditing, setEditing] = useState(false);
  const { t } = useTranslation();

  const socialMediaLinks = [
    {
      url: values.history.instagramUrl || '',
      icon: "https://www.blancfashion.com/images/social-media/ico-instagram.png",
      alt: "Instagram",
      title: t("Instagram URL", "Instagram URL"),
      path: "history.instagramUrl",
    },
    {
      url: values.history.facebookUrl || '',
      icon: "https://www.blancfashion.com/images/social-media/ico-facebook.png",
      alt: "Facebook",
      title: t("Facebook URL", "Facebook URL"),
      path: "history.facebookUrl",
    },
    {
      url: values.history.siteUrl || '',
      icon: "https://www.blancfashion.com/images/social-media/ico-website.svg",
      alt: values.history.siteUrl,
      title: t("Website URL", "Website URL"),
      path: "history.siteUrl",
    },
  ];

  const availableSocialMediaLinks = socialMediaLinks.filter(
    (link) => link.url.length > 0
  );

  const onClose = () => {
    setEditing(false);
  };

  const onSave = (links: SocialMediaLink[]) => {
    links.forEach((link) => {
      setFieldValue(link.path, link.url);
    });

    onClose();
  };

  return (
    <div className="history-social-media" onClick={() => setEditing(true)}>
      {availableSocialMediaLinks.length > 0
        ? availableSocialMediaLinks.map((link) => (
            <img
              key={link.icon}
              className="history-social-media-icon"
              src={link.icon}
              alt={link.alt}
            />
          ))
        : t("Social media links", "Social media links")}
      <FontAwesomeIcon
        icon={faPen}
        className="history-social-media-edit-icon"
      />
      {isEditing ? (
        <HistorySocialMediaModal
          links={socialMediaLinks}
          onClose={onClose}
          onSave={onSave}
        />
      ) : null}
    </div>
  );
};

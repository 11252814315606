import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Color } from '../../domain/Color';
import { RootState } from '../../RootState';

interface NewColorArgs {
    id: Color['id'];
    name: Color['name'];
    color: Color['color'];
    extraColor?: Color['extraColor'];
}

const makeNewColor = (args: NewColorArgs): ColorState => ({
    id: args.id,
    isNew: true,
    name: args.name,
    color: args.color,
    extraColor: args.extraColor || null,
});

/** Reducer */
export interface ColorState extends Color {
    isNew?: boolean;
}

const colorsAdapter = createEntityAdapter<ColorState>();
export const colorsSlice = createSlice({
    name: 'colors',
    initialState: colorsAdapter.getInitialState(),
    reducers: {
        fetched: colorsAdapter.setAll,
        added: {
            reducer: colorsAdapter.addOne,
            prepare: (args: NewColorArgs) => ({
                payload: makeNewColor(args),
            }),
        },
        nameChanged: {
            reducer: colorsAdapter.updateOne,
            prepare: (args: { id: ColorState['id']; name: ColorState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        colorChanged: {
            reducer: colorsAdapter.updateOne,
            prepare: (args: { id: ColorState['id']; color: ColorState['color'] }) => ({
                payload: {
                    id: args.id,
                    changes: { color: args.color },
                },
            }),
        },
        extraColorChanged: {
            reducer: colorsAdapter.updateOne,
            prepare: (args: { id: ColorState['id']; extraColor: ColorState['extraColor'] }) => ({
                payload: {
                    id: args.id,
                    changes: { extraColor: args.extraColor },
                },
            }),
        },
        removed: colorsAdapter.removeOne,
    },
});

/** Actions */
export const colorsActions = colorsSlice.actions;

/** Selectors */
const colorsSelectors = colorsAdapter.getSelectors((state: RootState) => state.colors);

export const selectColors = colorsSelectors.selectAll;
export const selectColorsCollection = colorsSelectors.selectEntities;
export const selectColor = (id: ColorState['id']) => (state: RootState): ColorState | undefined =>
    colorsSelectors.selectById(state, id);

import classnames from 'classnames';
import React from 'react';

import './Page.scss';

interface PageProps {
    className?: string;
}

export const Page: React.FC<PageProps> = props => {
    return (
        <div className='page'>
            <div className={classnames('page-content', props.className)}>{props.children}</div>
        </div>
    );
};

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Texture } from '../../domain/Texture';
import { RootState } from '../../RootState';

interface NewTextureArgs {
    id?: Texture['id'];
    name: Texture['name'];
    pictureTexture: Texture['pictureTexture'];
}

const makeNewTexture = (args: NewTextureArgs): TextureState => ({
    id: args.id || Math.random(),
    isNew: true,
    name: args.name,
    pictureTexture: args.pictureTexture,
});

/** Reducer */
export interface TextureState extends Texture {
    isNew?: boolean;
}

const texturesAdapter = createEntityAdapter<TextureState>();
export const texturesSlice = createSlice({
    name: 'textures',
    initialState: texturesAdapter.getInitialState(),
    reducers: {
        fetched: texturesAdapter.setAll,
        added: {
            reducer: texturesAdapter.addOne,
            prepare: (args: NewTextureArgs) => ({
                payload: makeNewTexture(args),
            }),
        },
        nameChanged: {
            reducer: texturesAdapter.updateOne,
            prepare: (args: { id: TextureState['id']; name: TextureState['name'] }) => ({
                payload: {
                    id: args.id,
                    changes: { name: args.name },
                },
            }),
        },
        pictureChanged: {
            reducer: texturesAdapter.updateOne,
            prepare: (args: {
                id: TextureState['id'];
                pictureTexture: TextureState['pictureTexture'];
            }) => ({
                payload: {
                    id: args.id,
                    changes: { pictureTexture: args.pictureTexture },
                },
            }),
        },
        removed: texturesAdapter.removeOne,
    },
});

/** Actions */
export const texturesActions = texturesSlice.actions;

/** Selectors */
const texturesSelectors = texturesAdapter.getSelectors((state: RootState) => state.textures);

export const selectTextures = texturesSelectors.selectAll;
export const selectTexturesCollection = texturesSelectors.selectEntities;
export const selectTexture = (id: TextureState['id']) => (
    state: RootState,
): TextureState | undefined => texturesSelectors.selectById(state, id);

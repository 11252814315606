import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../RootState';
import { SizeState } from '../../Size/sizes.state';

import { VariationState } from './variations.state';

/** Reducer */

export interface StockState {
    id: number;
    isNew?: boolean;
    quantity: number;
    referenceCode: string;
    sizeId: SizeState['id'] | null;
}

const makeNewStock = (): StockState => ({
    id: Math.random(),
    isNew: true,
    quantity: 0,
    referenceCode: '',
    sizeId: null,
});

const stocksAdapter = createEntityAdapter<StockState>();
export const stocksSlice = createSlice({
    name: 'stocks',
    initialState: stocksAdapter.getInitialState(),
    reducers: {
        stocksFetched: stocksAdapter.setAll,
        stockAdded: {
            reducer: (state, action) => stocksAdapter.addOne(state, action.payload.stock),
            prepare: ({ variationId }: { variationId: VariationState['id'] }) =>
                ({
                    payload: { variationId, stock: makeNewStock() },
                } as any),
        },
        stockSizeChanged: (
            state,
            action: PayloadAction<{ id: StockState['id']; sizeId: StockState['sizeId'] }>,
        ) =>
            stocksAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { sizeId: action.payload.sizeId },
            }),
        stockQuantityChanged: (
            state,
            action: PayloadAction<{ id: StockState['id']; quantity: StockState['quantity'] }>,
        ) =>
            stocksAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { quantity: action.payload.quantity },
            }),
        stockRemoved: (
            state,
            action: PayloadAction<{ variationId: VariationState['id']; id: StockState['id'] }>,
        ) => stocksAdapter.removeOne(state, action.payload.id),
    },
});
export const stocksActions = stocksSlice.actions;

/** Selectors */
const stocksSelectors = stocksAdapter.getSelectors((state: RootState) => state.stocks);

export const selectStocks = stocksSelectors.selectAll;
export const selectStocksCollection = stocksSelectors.selectEntities;
export const selectStock = (id: StockState['id']) => (state: any) =>
    stocksSelectors.selectById(state, id) || makeNewStock();

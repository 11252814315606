import React from 'react';

import { TabID, useTabbedViewContext } from './common';

interface TabProps {
    id: TabID;
}
export const Tab: React.FC<TabProps> = props => {
    const { selectedTab } = useTabbedViewContext();
    if (selectedTab !== props.id) {
        return null;
    }
    return <div className='tab'>{props.children}</div>;
};

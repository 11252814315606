import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Segment } from '../../../domain/Segment';
import { RootState } from '../../../RootState';

/** Reducer */
export type SegmentState = Segment;

const segmentsAdapter = createEntityAdapter<SegmentState>();
export const segmentsSlice = createSlice({
    name: 'segments',
    initialState: segmentsAdapter.getInitialState(),
    reducers: {
        segmentsFetched: segmentsAdapter.setAll,
    },
});
export const segmentsActions = segmentsSlice.actions;

/** Selectors */
const segmentsSelectors = segmentsAdapter.getSelectors((state: RootState) => state.segments);

export const selectSegments = segmentsSelectors.selectAll;
export const selectSegmentsCollection = segmentsSelectors.selectEntities;
export const selectSegment = segmentsSelectors.selectById;

import React, { useRef } from 'react';

interface FileUploadProps {
    accept?: string;
    onChange: (filelist: FileList | null) => void;
}
export const FileUpload: React.FC<FileUploadProps> = props => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.files);
        event.target.files = null;
        event.target.value = '';
    };

    const onClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <>
            <input
                type='file'
                accept={props.accept}
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onFileSelected}
            />
            <span onClick={onClick}>{props.children}</span>
        </>
    );
};

// @ts-nocheck
import React, { Component, useContext, useState } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { setCookie } from "nookies";
import './Tour.scss';
import "shepherd.js/dist/css/shepherd.css";

const newSteps = [
    {
        id: 'first',
        attachTo: { element: '#Visitors', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("dashboard--balance");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Visitors',
        text: ['Here you see the total unique visitors at BLANC, including logged buyers, the website, and our News and Views section. This metric will help you understand how you are performing as well as the brand’s market potential within the platform.']
    },
    {
        id: 'second',
        attachTo: { element: '#BrandViews', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("dashboard--balance");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Brand Views',
        text: ['Here you get the total views of your brand pages - About the Brand, Brand’s History, and Collections & Products. The more you drive your buyers to the platform, the more the numbers will increase.']
    },
    {
        id: 'third',
        attachTo: { element: '#ProductViews', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("dashboard--balance");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Product Views',
        text: ['Total views of all your product pages are available for you to check here. One of the uses of this data is to compare products that perform better and have more next time.']
    },
    {
        id: 'fourth',
        attachTo: { element: '#DraftOrders', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("dashboard--balance");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Draft Orders',
        text: ['Here you see the total orders initiated with your brand’s products.']
    },
    {
        id: 'five',
        attachTo: { element: '#Orders', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("dashboard--balance");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Orders',
        text: ['The total number of orders (POs) is available here for you to check and optimize your performance. ']
    },
    {
        id: 'six',
        attachTo: { element: '.bar--chart', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("bar--chart");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top - 50;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Collections',
        text: ['Here you can check the total views of each one of the collections available on BLANC. Those include collection pages and product pages.']
    },
    {
        id: 'seven',
        attachTo: { element: '.brandpage--cards', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("brandpage--cards");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top + 100;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Brand Pages',
        text: ['Total views of your About the Brand page are available here.']
    },
    {
        id: 'eight',
        attachTo: { element: '.downloads--cards', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        when: {
            show: function () {
                var element = document.getElementsByClassName("downloads--cards");
                const rect = element[0].getBoundingClientRect();
                const top = rect.top + 150;
                window.scrollTo(0, top)
            }
        },
        cancelIcon: {
            enabled: true,
        },
        title: 'Downloads',
        text: ['Here you can check the number of downloadable available files such as line sheets. ']
    },
];

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true,
        },
    },
    useModalOverlay: true,
};

function Button() {
    const tour = useContext(ShepherdTourContext);
    const [visible, setVisible] = useState(true);

    return (
        <>
            {
                visible ? (
                    <button className='btn-tour' onClick={() => {
                        setCookie(
                            undefined,
                            "Blanc.Tour",
                            true,
                        );
                        setVisible(false)
                        tour.start()
                    }}>
                        ?
                    </button>
                ) : (<></>)
            }
        </>
    );
}

class Tour extends Component {
    render() {
        return (
            <div className='tour-container'>
                <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
                    <Button />
                </ShepherdTour>
            </div>
        );
    }
}

export default Tour;

import { useFormikContext } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useCallback, useState } from "react";

export const FormAutoSave: React.FC<{
  debounceMs?: number;
  initialSave?: boolean;
}> = (props) => {
  const debounceMs = props.debounceMs ?? 1000;
  const initialSave = props.initialSave ?? false;

  const [wasInitialSave, setWasInitialSave] = useState(false);

  const formik = useFormikContext<any>();

  const debouncedSubmit = useCallback(
    debounce(() => {
      return formik.submitForm();
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => {
    if (initialSave || wasInitialSave) {
      debouncedSubmit();
    }
    if (!wasInitialSave) {
      setWasInitialSave(true);
    }
  }, [debouncedSubmit, formik.values]);

  return null;
};

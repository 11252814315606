import cn from 'classnames';
import React from 'react';

import { LoadingSpinner } from '../../components/LoadingSpinner';

type AsyncButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isPending?: boolean;
};
export const AsyncButton: React.FC<AsyncButtonProps> = props => {
    const { className, ...restProps } = props;

    let buttonProps = {...restProps};
    delete buttonProps.isPending;

    return (
        <button
            className={cn('primary-button', className)}
            disabled={props.isPending}
            {...buttonProps}
        >
            {props.isPending ? <LoadingSpinner /> : props.children}
        </button>
    );
};

import React, { useContext } from 'react';

export const OrderableGridHandleContext = React.createContext<React.MutableRefObject<any>>(
    React.createRef(),
);

const useHandleRef = () => {
    return useContext(OrderableGridHandleContext);
};

export const OrderableGridHandle: React.FC = props => {
    const ref = useHandleRef();
    return (
        <div className='orderable-grid-handle' ref={ref}>
            {props.children}
        </div>
    );
};

export const OrderableGridHandleContextProvider = OrderableGridHandleContext.Provider;

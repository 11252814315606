import { API_URL, isMockApi } from "../config";
import { BrandProfile } from "../domain/BrandProfile";
import { Sizechart } from "../domain/Sizechart";
import { NewEntity, randomInt } from "../helpers";

import { Sizecharts } from "./__fixtures__/sizecharts";
import { HttpClient } from "./http-client";
import { toBase64 } from "./brand.api";

const SIZECHARTS_API_URL = `${API_URL}/v1/admin-seller/sizecharts`;

export const fetchSizecharts =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]): Promise<Sizechart[]> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Fetch sizecharts for BrandID", brandId);
          setTimeout(() => {
            resolve(Sizecharts);
          }, 1000);
        })
      : httpClient
          .get(`${SIZECHARTS_API_URL}`)
          .then((res) => res.data)
          .then((sizecharts) => {
            return sizecharts.map((sizechart: any) => ({
              id: sizechart.id,
              name: sizechart.title,
              image: {
                url: sizechart.image,
                remover: false,
              },
              chart: sizechart.chart
            }));
          });

export const createSizechart =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]) =>
  (params: NewEntity<Sizechart>): Promise<{ id: Sizechart["id"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log(
            "[API] Create sizechart for BrandID",
            brandId,
            "and data",
            params
          );
          setTimeout(() => {
            resolve({ id: randomInt(50, 99) });
          }, 1000);
        })
      : // TODO: check sizechart create
        httpClient.post(`${SIZECHARTS_API_URL}`, {
          title: params.name,
          image: params.image,
        }).then(response => response.data);

export const updateSizechart =
  (httpClient: HttpClient) =>
  (id: Sizechart["id"]) =>
  async (params: NewEntity<Sizechart>): Promise<{ id: Sizechart["id"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Update sizechart for ID`", id, "and data", params);
          setTimeout(() => {
            resolve({ id });
          }, 1000);
        })
      : httpClient.put(`${SIZECHARTS_API_URL}/${id}`, {
          title: params.name,
          image: {
            nova_imagem: await toBase64(params.image?.nova_imagem),
          },
          chart: params.chart
        });

export const deleteSizechart =
  (httpClient: HttpClient) =>
  (id: Sizechart["id"]): Promise<void> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Delete sizechart", id);
          setTimeout(() => {
            resolve();
          }, 1000);
        })
      : httpClient.delete(`${SIZECHARTS_API_URL}/${id}`);

enum AsyncStateStatus {
    EMPTY = 'EMPTY',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

type AsyncStatusEmpty = { status: AsyncStateStatus.EMPTY };
type AsyncStatusPending = { status: AsyncStateStatus.PENDING };
type AsyncStatusSuccess<T> = { status: AsyncStateStatus.SUCCESS; data: T };
type AsyncStatusFailure<E> = { status: AsyncStateStatus.FAILURE; error: E };
export type AsyncState<T, E> =
    | AsyncStatusEmpty
    | AsyncStatusPending
    | AsyncStatusSuccess<T>
    | AsyncStatusFailure<E>;

export const setEmpty = <T, E>(): AsyncState<T, E> => ({ status: AsyncStateStatus.EMPTY });
export const setPending = <T, E>(): AsyncState<T, E> => ({ status: AsyncStateStatus.PENDING });
export const setSuccess = <T>(data: T): AsyncState<T, never> => ({
    status: AsyncStateStatus.SUCCESS,
    data,
});
export const setFailure = <E>(error: E): AsyncState<never, E> => ({
    status: AsyncStateStatus.FAILURE,
    error,
});

export const isEmpty = <T, E>(state: AsyncState<T, E>): state is AsyncStatusEmpty =>
    state.status === AsyncStateStatus.EMPTY;
export const isPending = <T, E>(state: AsyncState<T, E>): state is AsyncStatusPending =>
    state.status === AsyncStateStatus.PENDING;
export const isSuccess = <T, E>(state: AsyncState<T, E>): state is AsyncStatusSuccess<T> =>
    state.status === AsyncStateStatus.SUCCESS;
export const isFailure = <T, E>(state: AsyncState<T, E>): state is AsyncStatusFailure<E> =>
    state.status === AsyncStateStatus.FAILURE;

export const getData = <T, E>(state: AsyncState<T, E>): T | undefined =>
    isSuccess(state) ? state.data : undefined;

export const getError = <T, E>(state: AsyncState<T, E>): E | undefined =>
    isFailure(state) ? state.error : undefined;

export const mapData = <T, E = any>(mappingFn: (args: T) => T) => (
    state: AsyncState<T, E>,
): AsyncState<T, E> => {
    if (!isSuccess(state)) {
        return state;
    }
    return setSuccess(mappingFn(state.data));
};

/** Helpers */
export const prop = <T extends string, V>(prop: T) => (obj: Record<T, V>): V | undefined =>
    Object.prototype.hasOwnProperty.call(obj, prop) ? obj[prop] : undefined;

import React, { createContext, useContext, useEffect, useState } from "react";

import { PageLoadingSpinner } from "../pages/common/PageLoadingSpinner";

import { login } from "./auth.api";
import {
  createBrandHistoryDetail,
  createBrandInfoBlock,
  fetchBrandFooter,
  fetchBrandHistoryDetails,
  fetchBrandInfoBlocks,
  fetchBrandProfile,
  removeBrandHistoryDetail,
  removeBrandInfoBlock,
  updateBrandFooter,
  updateBrandHistoryDetail,
  updateBrandInfoBlock,
  updateBrandProfile,
  submitForReview,
} from "./brand.api";
import { fetchCategories } from "./categories.api";
import {
  fetchCollections,
  fetchCollection,
  updateCollection,
  createCollection,
  updateCollectionProducts,
} from "./collections.api";
import {
  fetchColors,
  createColor,
  updateColor,
  deleteColor,
} from "./colors.api";
import { createClient, setClientToken, unsetClientToken } from "./http-client";
import { fetchLinesheets } from "./linesheets.api";
import {
  createMeasurement,
  deleteMeasurement,
  fetchMeasurements,
  updateMeasurement,
} from "./measurements.api";
import {
  fetchProduct,
  fetchProductList,
  fetchCollectionProducts,
  updateProduct,
  createProduct,
  fetchProductListCollection,
} from "./product.api";
import { fetchSegments } from "./segments.api";
import {
  createSizechart,
  deleteSizechart,
  fetchSizecharts,
  updateSizechart,
} from "./sizecharts.api";
import { createSize, deleteSize, fetchSizes, updateSize } from "./sizes.api";
import {
  fetchTextures,
  createTexture,
  updateTexture,
  deleteTexture,
} from "./textures.api";

const TOKEN_LOCALSTORAGE_KEY = "api_token";
const BRANDID_LOCALSTORAGE_KEY = "brand_id";
const X_ACCOUNT_HASH_LOCALSTORAGE_KEY = "X-Account-Hash";
const X_ACCOUNT_NAME_LOCALSTORAGE_KEY = "X-Account-Name";
const USER_DATA_LOCALSTORAGE_KEY = "userData";
const SELLER_DATA_LOCALSTORAGE_KEY = "sellerData";

interface ApiInterface {
  isLoggedIn: boolean;
  user: {
    brandId: number;
  };
  isUpdatingBrand: boolean;
  infoBlocksSubmitting: boolean[];
  setInfoBlocksSubmitting: (infoBlockId: any, state: boolean) => void;
  updatingBrand: (value: boolean) => void;
  authenticate: (args: { token: string; brandId: number }) => void;
  logout: () => void;

  login: ReturnType<typeof login>;

  fetchCategories: ReturnType<typeof fetchCategories>;

  fetchCollections: ReturnType<typeof fetchCollections>;
  fetchCollection: ReturnType<typeof fetchCollection>;
  createCollection: ReturnType<typeof createCollection>;
  updateCollection: ReturnType<typeof updateCollection>;
  updateCollectionProducts: ReturnType<typeof updateCollectionProducts>;

  fetchColors: ReturnType<typeof fetchColors>;
  createColor: ReturnType<typeof createColor>;
  updateColor: ReturnType<typeof updateColor>;
  deleteColor: ReturnType<typeof deleteColor>;

  fetchBrandProfile: ReturnType<typeof fetchBrandProfile>;
  updateBrandProfile: ReturnType<typeof updateBrandProfile>;
  fetchBrandInfoBlocks: ReturnType<typeof fetchBrandInfoBlocks>;
  createBrandInfoBlock: ReturnType<typeof createBrandInfoBlock>;
  updateBrandInfoBlock: ReturnType<typeof updateBrandInfoBlock>;
  removeBrandInfoBlock: ReturnType<typeof removeBrandInfoBlock>;
  submitForReview: ReturnType<typeof submitForReview>;
  fetchBrandHistoryDetails: ReturnType<typeof fetchBrandHistoryDetails>;
  createBrandHistoryDetail: ReturnType<typeof createBrandHistoryDetail>;
  updateBrandHistoryDetail: ReturnType<typeof updateBrandHistoryDetail>;
  removeBrandHistoryDetail: ReturnType<typeof removeBrandHistoryDetail>;
  fetchBrandFooter: ReturnType<typeof fetchBrandFooter>;
  updateBrandFooter: ReturnType<typeof updateBrandFooter>;

  fetchLinesheets: ReturnType<typeof fetchLinesheets>;

  fetchMeasurements: ReturnType<typeof fetchMeasurements>;
  createMeasurement: ReturnType<typeof createMeasurement>;
  updateMeasurement: ReturnType<typeof updateMeasurement>;
  deleteMeasurement: ReturnType<typeof deleteMeasurement>;

  fetchProduct: ReturnType<typeof fetchProduct>;
  fetchProductListCollection: ReturnType<typeof fetchProductListCollection>;
  fetchProductList: ReturnType<typeof fetchProductList>;
  fetchCollectionProducts: ReturnType<typeof fetchCollectionProducts>;
  createProduct: ReturnType<typeof createProduct>;
  updateProduct: ReturnType<typeof updateProduct>;

  fetchSegments: ReturnType<typeof fetchSegments>;

  fetchSizecharts: ReturnType<typeof fetchSizecharts>;
  createSizechart: ReturnType<typeof createSizechart>;
  updateSizechart: ReturnType<typeof updateSizechart>;
  deleteSizechart: ReturnType<typeof deleteSizechart>;

  fetchSizes: ReturnType<typeof fetchSizes>;
  createSize: ReturnType<typeof createSize>;
  updateSize: ReturnType<typeof updateSize>;
  deleteSize: ReturnType<typeof deleteSize>;

  fetchTextures: ReturnType<typeof fetchTextures>;
  createTexture: ReturnType<typeof createTexture>;
  updateTexture: ReturnType<typeof updateTexture>;
  deleteTexture: ReturnType<typeof deleteTexture>;
}

const missingProvider = (): never => {
  throw new Error("ApiProvider is missing");
};

const ApiContext = createContext<ApiInterface>({
  isLoggedIn: false,
  user: {
    brandId: -2,
  },
  isUpdatingBrand: false,
  infoBlocksSubmitting: [],
  setInfoBlocksSubmitting: (infoBlockId: any, state: boolean) => null,
  updatingBrand: (value: boolean) => null,
  authenticate: missingProvider,
  logout: missingProvider,

  login: missingProvider,

  fetchCategories: missingProvider,

  fetchCollections: missingProvider,
  fetchCollection: missingProvider,
  createCollection: missingProvider,
  updateCollection: missingProvider,
  updateCollectionProducts: missingProvider,

  fetchColors: missingProvider,
  createColor: missingProvider,
  updateColor: missingProvider,
  deleteColor: missingProvider,

  fetchBrandProfile: missingProvider,
  updateBrandProfile: missingProvider,
  fetchBrandInfoBlocks: missingProvider,
  createBrandInfoBlock: missingProvider,
  updateBrandInfoBlock: missingProvider,
  removeBrandInfoBlock: missingProvider,
  submitForReview: missingProvider,
  fetchBrandHistoryDetails: missingProvider,
  createBrandHistoryDetail: missingProvider,
  updateBrandHistoryDetail: missingProvider,
  removeBrandHistoryDetail: missingProvider,
  fetchBrandFooter: missingProvider,
  updateBrandFooter: missingProvider,

  fetchLinesheets: missingProvider,

  fetchMeasurements: missingProvider,
  createMeasurement: missingProvider,
  updateMeasurement: missingProvider,
  deleteMeasurement: missingProvider,

  fetchProduct: missingProvider,
  fetchProductList: missingProvider,
  fetchProductListCollection: missingProvider,
  fetchCollectionProducts: missingProvider,
  createProduct: missingProvider,
  updateProduct: missingProvider,

  fetchSegments: missingProvider,

  fetchSizecharts: missingProvider,
  createSizechart: missingProvider,
  updateSizechart: missingProvider,
  deleteSizechart: missingProvider,

  fetchSizes: missingProvider,
  createSize: missingProvider,
  updateSize: missingProvider,
  deleteSize: missingProvider,

  fetchTextures: missingProvider,
  createTexture: missingProvider,
  updateTexture: missingProvider,
  deleteTexture: missingProvider,
});

export const ApiProvider: React.FC = (props) => {
  const httpClient = createClient();
  const [bootstraped, setBootstraped] = useState(false);
  const [isUpdatingBrand, setIsUpdatingBrand] = useState(false);
  const [infoBlocksSubmitting, setInfoBlocksSubmitting] = useState([]);
  const setHTTPClientAuthToken = setClientToken(httpClient);
  const unsetHTTPClientAuthToken = unsetClientToken(httpClient);

  const [profile, setProfile] = useState<
    { token: string; brandId: number } | undefined
  >();

  const onAuthenticate = (args: { token: string; brandId: number }) => {
    localStorage.setItem(BRANDID_LOCALSTORAGE_KEY, `${args.brandId}`);
    localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, args.token);
    setHTTPClientAuthToken(args.token);
    setProfile(args);
  };
  const onLogout = () => {
    setProfile(undefined);
    localStorage.removeItem(X_ACCOUNT_HASH_LOCALSTORAGE_KEY);
    localStorage.removeItem(X_ACCOUNT_NAME_LOCALSTORAGE_KEY);
    localStorage.removeItem(BRANDID_LOCALSTORAGE_KEY);
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
    sessionStorage.removeItem(USER_DATA_LOCALSTORAGE_KEY);
    sessionStorage.removeItem(SELLER_DATA_LOCALSTORAGE_KEY);
    unsetHTTPClientAuthToken();
  };

  useEffect(() => {
    const existingToken = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
    const existingBrandId = localStorage.getItem(BRANDID_LOCALSTORAGE_KEY);
    if (existingToken && existingBrandId && +existingBrandId) {
      setHTTPClientAuthToken(existingToken);
      setProfile({ token: existingToken, brandId: +existingBrandId });
    }
    setBootstraped(true);
  }, []);

  if (!bootstraped) {
    return <PageLoadingSpinner />;
  }

  return (
    <ApiContext.Provider
      value={{
        isLoggedIn:
          (profile &&
            !!profile.token &&
            profile.token.length > 0 &&
            profile.brandId > -1) ??
          false,
        user: {
          brandId: profile?.brandId ?? -1,
        },

        isUpdatingBrand: isUpdatingBrand,
        updatingBrand: (state: boolean) => setIsUpdatingBrand(state),

        infoBlocksSubmitting: infoBlocksSubmitting,

        setInfoBlocksSubmitting: (infoBlockId: any, state: any) =>
          setInfoBlocksSubmitting((oldValue) => {
            const newOld = [...oldValue] as any;
            newOld[infoBlockId] = state;
            return newOld;
          }),
        authenticate: onAuthenticate,
        logout: onLogout,

        login: login(httpClient),

        fetchCategories: fetchCategories(httpClient),

        fetchCollections: fetchCollections(httpClient),
        fetchCollection: fetchCollection(httpClient),
        createCollection: createCollection(httpClient),
        updateCollection: updateCollection(httpClient),
        updateCollectionProducts: updateCollectionProducts(httpClient),

        fetchColors: fetchColors(httpClient),
        createColor: createColor(httpClient),
        updateColor: updateColor(httpClient),
        deleteColor: deleteColor(httpClient),

        fetchBrandProfile: fetchBrandProfile(httpClient),
        updateBrandProfile: updateBrandProfile(httpClient),
        fetchBrandInfoBlocks: fetchBrandInfoBlocks(httpClient),
        createBrandInfoBlock: createBrandInfoBlock(httpClient),
        updateBrandInfoBlock: updateBrandInfoBlock(httpClient),
        removeBrandInfoBlock: removeBrandInfoBlock(httpClient),
        submitForReview: submitForReview(httpClient),
        fetchBrandHistoryDetails: fetchBrandHistoryDetails(httpClient),
        createBrandHistoryDetail: createBrandHistoryDetail(httpClient),
        updateBrandHistoryDetail: updateBrandHistoryDetail(httpClient),
        removeBrandHistoryDetail: removeBrandHistoryDetail(httpClient),
        fetchBrandFooter: fetchBrandFooter(httpClient),
        updateBrandFooter: updateBrandFooter(httpClient),

        fetchLinesheets: fetchLinesheets(httpClient),

        fetchMeasurements: fetchMeasurements(httpClient),
        createMeasurement: createMeasurement(httpClient),
        updateMeasurement: updateMeasurement(httpClient),
        deleteMeasurement: deleteMeasurement(httpClient),

        fetchProduct: fetchProduct(httpClient),
        fetchProductList: fetchProductList(httpClient),
        fetchProductListCollection: fetchProductListCollection(httpClient),
        fetchCollectionProducts: fetchCollectionProducts(httpClient),
        createProduct: createProduct(httpClient),
        updateProduct: updateProduct(httpClient),

        fetchSegments: fetchSegments(httpClient),

        fetchSizecharts: fetchSizecharts(httpClient),
        createSizechart: createSizechart(httpClient),
        updateSizechart: updateSizechart(httpClient),
        deleteSizechart: deleteSizechart(httpClient),

        fetchSizes: fetchSizes(httpClient),
        createSize: createSize(httpClient),
        updateSize: updateSize(httpClient),
        deleteSize: deleteSize(httpClient),

        fetchTextures: fetchTextures(httpClient),
        createTexture: createTexture(httpClient),
        updateTexture: updateTexture(httpClient),
        deleteTexture: deleteTexture(httpClient),
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export const useApi = (): ApiInterface => useContext(ApiContext);

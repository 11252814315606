import cn from 'classnames';
import React from 'react';

import { BaseComponent } from './_BaseProps';
import styles from './Section.module.scss';

export const Section: BaseComponent<{ title?: string }> = props => (
    <div className={cn(styles.section, props.className)}>
        <div className={styles.title}>{props.title}</div>
        {props.children}
    </div>
);

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { BrandProfile } from "../../domain/BrandProfile";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { Modal } from "../common/Modal";
import { PlaceholderImage } from "../common/PlaceholderImage";
import { YoutubeEmbedVideo } from "../common/YoutubeEmbedVideo";
import { PrimaryButton } from "../common/PrimaryButton";
import { Showcase } from "../common/Showcase";
import { TextToggledit } from "../common/TextToggledit";
import getCroppedImg from "../getCroppedImg";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/pagination/pagination.min.css";

import "./FeaturedInfoBlock.scss";
import { InternationalRetailers } from "./InternationalRetailers";
import { resolve } from "dns";

SwiperCore.use([Pagination, Autoplay]);

export const TheBrandInfoButtonEdit: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [isEditing, setEditing] = useState(false);
  const [label, setLabel] = useState(
    values.theBrand.featuredInfoBlock?.buttonLabel
  );
  const { t } = useTranslation();
  const [link, setLink] = useState(values.theBrand.featuredInfoBlock?.link);

  const onSave = () => {
    setFieldValue("theBrand.featuredInfoBlock.buttonLabel", label);
    setFieldValue("theBrand.featuredInfoBlock.link", link);

    setEditing(false);
  };

  return (
    <div className="the-brand-info-button">
      <PrimaryButton className="" onClick={() => setEditing(true)}>
        {label || "Order now"}{" "}
        <FontAwesomeIcon style={{ marginLeft: "4px" }} icon={faPencilAlt} />
      </PrimaryButton>
      <Modal
        isOpen={isEditing}
        contentLabel="The Brand info button"
        onRequestClose={(event) => {
          event.stopPropagation();
          setEditing(false);
        }}
      >
        <div className="featured-infoblock-modal-content">
          <div className="the-brand-info-button-field">
            <span>{t("Label", "Label")}:</span>
            <input
              type="text"
              placeholder={t("Label", "Label")}
              maxLength={20}
              value={label}
              onChange={(event) => setLabel(event.target.value)}
            />
            <div style={{ marginLeft: "8px" }}>
              ({label?.length} / 20 {t('characters', 'characters')})
            </div>
          </div>
          <div className="the-brand-info-button-field">
            <span>{t("Link", "Link")}:</span>
            <input
              type="text"
              placeholder={t("Link", "Link")}
              value={link}
              onChange={(event) => setLink(event.target.value)}
            />
          </div>
          <div>
            <PrimaryButton onClick={onSave}>{t("Save", "Save")}</PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const FeaturedInfoBlock: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();

  const [images, setImages] = useState<any>([]);
  const [croppedimages, setCroppedimages] = useState<any>([]);

  const [imgSrc, setImgSrc] = useState<any>([]);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

  const [videoUrl, setVideoUrl] = useState<any>({});

  const loadImagesPreview = (newImage: Array<any>, i: number, newImageIndex: number) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = async () => {
        const croppedImg: any = await getCroppedImg(img, newImage[i].nova_imagem_crop);
        var objectURL = URL.createObjectURL(croppedImg);
        imgSrc[newImageIndex] = objectURL;
        setImgSrc(imgSrc);
        resolve(true);
      };

      img.onerror = () => resolve(false)
      img.crossOrigin = "anonymous";

      if (!newImage[i].file) {
        img.src = newImage[i].src + `?x=123`;
      } else {
        img.src = newImage[i].src;
      }
    });
  };

  const { t } = useTranslation();

  const transformCrop = (image: any) => {
    return new Promise((resolve) => {
      if (!image.imageCropping) {
        resolve(image.cropOriginal);
      }
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = auxImage?.imageCropping?.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: 605 / 417,
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.src = image?.originalImage;
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      if (
        values.theBrand.featuredInfoBlock &&
        values.theBrand.featuredInfoBlock.images
      ) {
        let temporaryImages = await Promise.all(
          values.theBrand.featuredInfoBlock.images.map(async (img, index) => {
            return {
              id: img.id || `${Math.random()}`,
              src: img.featuredImage,
              originalImage: img.originalImage || "",
              imageCropping: img.imageCropping,
              remover: img.remover,
              file: img.file,
              crop: await transformCrop(img),
              videoUrl: img.videoUrl,
            };
          })
        );
        if(images.length === 0) {
          setImages(temporaryImages as any);
        }

        const srcAndVideoUrl = temporaryImages.reduce((acc: any, img: any) => {
          acc.src.push(img.src);
          acc.videoUrl.push(img.videoUrl);

          return acc;
        }, {src: [], videoUrl: []} as any);

        setImgSrc(srcAndVideoUrl.src);
        setVideoUrl(srcAndVideoUrl.videoUrl);
      }
    };
    loadImages();
  }, [values.theBrand.featuredInfoBlock?.images]);

  const updateCroppedImages = (newImage: any) => {
    return new Promise((resolve, reject) => {
      let currentImgIndex = croppedimages.findIndex((imagem: any) => imagem.id === newImage.id);

      if (currentImgIndex !== -1) {
        const newCrop = {
          ...croppedimages[currentImgIndex],
          img: {
            ...croppedimages[currentImgIndex].img,
            imageResp: newImage.src,
            portraitImage: newImage.src,
            landscapeImage: newImage.src,
            featuredImage: newImage.src,
            url: newImage.src,
            nova_imagem: newImage.file,
            nova_imagem_crop: newImage.crop,
            file: newImage.file,
            remover: newImage.remover,
            videoUrl: newImage.videoUrl,
          },
        };

        const newImages = [
          ...croppedimages.slice(0, currentImgIndex),
          newCrop,
          ...croppedimages.slice(currentImgIndex + 1),
        ];
        setCroppedimages(newImages);
      } else {
        setCroppedimages([...croppedimages, { img: newImage, id: newImage.id }])
      }

      resolve(croppedimages);
    })
  }

  const image = (
    <Swiper
      pagination={{
        type: "bullets",
        clickable: true,
      }}
      initialSlide={1}
      slidesPerView={1}
      spaceBetween={10}
      style={{ maxWidth: "590px" }}
      loop={images.filter((item: any) => !item.remover).length > 1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className="mySwiper"
    >
      {images
        .filter((item: any) => !item.remover)
        .map((img: any, i: number) => (
          <SwiperSlide key={i}>
            <Imagedit
              allowEmpty={true}
              title={t("Edit brand images", "Edit brand images")}
              desiredDimensions={{ width: 605, height: 417 }}
              multiple
              imageLimit={3}
              openIndex={i}
              onSave={async (images) => {
                const newImage = [
                  ...images.map((image: any) => ({
                    ...image,
                    imageResp: image.src,
                    portraitImage: image.src,
                    landscapeImage: image.src,
                    featuredImage: image.src,
                    url: image.src,
                    nova_imagem: image.file,
                    nova_imagem_crop: image.crop,
                    file: image.file,
                    remover: image.remover,
                    videoUrl: image.videoUrl,
                  })),
                ];

                const promises = [];
                for (let i = 0; i < newImage.length; i++) {
                  promises.push(loadImagesPreview(newImage, i, i));
                }

                Promise.all(promises)
                  .then(() => setLoadingPreview(false))
                  .catch((error) => {
                    console.error(error);
                    setLoadingPreview(false);
                  })

                const croppedImages: any = await updateCroppedImages(newImage[0])

                let newImages: any = []

                images.map((image: any) => {
                  const index = croppedImages.findIndex((i: any) => i.id === image.id);
                  if (index !== -1) {
                    newImages.push(croppedimages[index].img)
                  } else {
                    newImages.push({...image, nova_imagem: image.file})
                  }
                });

                setFieldValue("theBrand.featuredInfoBlock.images", newImages);

                const featureInfoblockImages = [
                  ...images.map((image: any) => ({
                    ...image,
                    file: image.file,
                    crop: image.cropOriginal,
                    videoUrl: image.videoUrl,
                  })),
                ];

                setImages(featureInfoblockImages);
              }}
              videoUrlHandler={{
                defaultHandler: (url, image, images) => {
                  if (!image || !images) {
                    return;
                  }

                  const newImages = [
                    ...images.map((img: any) => ({
                      ...img,
                      videoUrl: img.id === image.id ? url : img.videoUrl,
                    })),
                  ];

                  setImages(newImages)
                },

                handler: _ => {
                  throw Error('O método precisa ser sobrescrito.');
                },
                url: img.videoUrl,
              }}
              src={images}
              render={() => {
                let shouldRender = 'placeholder'

                if (videoUrl[i]) {
                  shouldRender = 'videoUrl'
                } else if (imgSrc[i]) {
                  shouldRender = 'img'
                }

                return (
                  <div className="the-brand-info-images">
                    {loadingPreview ? (
                      <PlaceholderImage loading />
                    ) : (
                      <>
                        {({
                          videoUrl: <YoutubeEmbedVideo src={videoUrl[i]} />,
                          img: <img src={imgSrc[i]} />,
                          placeholder: <PlaceholderImage loading />,
                        } as any)[shouldRender]}
                      </>
                    )}
                  </div>
                )
              }}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
  const topContent = (
    <div className="brand-stats">
      <div>
        <div className="subtitle">{t("Brand from", "Brand from")}</div>
        <div className="value">
          <TextToggledit
            text={values.theBrand.brandFrom}
            placeholder=""
            name="theBrand.brandFrom"
            onChange={(value) => setFieldValue("theBrand.brandFrom", value)}
          />
        </div>
      </div>
      <div>
        <div className="subtitle">
          {t("Year established", "Year established")}
        </div>
        <div className="value">
          <TextToggledit
            text={values.theBrand.yearEstablished}
            placeholder=""
            name="theBrand.yearEstablished"
            onChange={(value) =>
              setFieldValue("theBrand.yearEstablished", value)
            }
          />
        </div>
      </div>
      <div>
        <div className="subtitle">
          {t("International Retailers", "International Retailers")}
        </div>
        <div className="value">
          <TextToggledit
            text={values.theBrand.multibrandRetailers}
            placeholder=""
            name="theBrand.multibrandRetailers"
            onChange={(value) =>
              setFieldValue("theBrand.multibrandRetailers", value)
            }
          />
          <InternationalRetailers />
        </div>
      </div>
    </div>
  );
  const title = (
    <h2>
      <TextToggledit
        text={values.theBrand.featuredInfoBlock?.title || ""}
        placeholder={t("Title", "Title")}
        maxLength={60}
        name="theBrand.featuredInfoBlock.title"
        onChange={(value) =>
          setFieldValue("theBrand.featuredInfoBlock.title", value)
        }
      />
    </h2>
  );
  const bottomContent = (
    <div>
      <TextToggledit
        text={values.theBrand.featuredInfoBlock?.text || ""}
        placeholder={t("Full description", "Full description")}
        maxLength={900}
        multipleLines
        name="theBrand.featuredInfoBlock.text"
        onChange={(value) =>
          setFieldValue("theBrand.featuredInfoBlock.text", value)
        }
      />
      <TheBrandInfoButtonEdit />
    </div>
  );

  return (
    <Showcase
      className="the-brand-info"
      title={title}
      image={image}
      topContent={topContent}
      bottomContent={bottomContent}
      dots={false}
    />
  );
};

import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import Swal from "sweetalert2";

import { useApi } from "../../api/useApi";
import {
  BrandProfile,
  BrandProfileHistoryDetails,
} from "../../domain/BrandProfile";
import { PageLoadingSpinner } from "../../pages/common/PageLoadingSpinner";
import { FormAutoSave } from "../common/FormAutoSave";
import { noop } from "../common/helpers";
import { TextToggledit } from "../common/TextToggledit";
import { useAsync } from "../useAsync";

const HistoryDetail: React.FC<{
  detail: BrandProfileHistoryDetails;
  onRemove: (detailId: BrandProfileHistoryDetails["id"]) => void;
  onSave: (detailId: BrandProfileHistoryDetails) => void;
}> = (props) => {
  const { detail } = props;
  const { updateBrandHistoryDetail, removeBrandHistoryDetail } = useApi();
  const removeBrandHistoryDetailsAsync = useAsync(removeBrandHistoryDetail);
  const updateBrandHistoryDetailsAsync = useAsync(
    updateBrandHistoryDetail(detail.id)
  );
  const formRef = useRef<any>();

  const onSave = (args: BrandProfileHistoryDetails) => {
    updateBrandHistoryDetailsAsync
      .start(args)
      .then((response) => {
        props.onSave(response);
        formRef.current.resetForm({ values: args });
      })
      .catch(noop);
  };

  const { t } = useTranslation();

  const onRemove = (id: number) => {
    Swal.fire({
      title: t(
        "Are you sure you want to remove this info block?",
        "Are you sure you want to remove this info block?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Remove", "Remove"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        removeBrandHistoryDetailsAsync.start(id).then(() => props.onRemove(id));
      }
    });
  };

  return (
    <div className="entry">
      <Formik innerRef={formRef} initialValues={detail} onSubmit={onSave}>
        {({ values, setFieldValue, dirty }) => (
          <>
            <Prompt
              when={!!dirty}
              message={(location) =>
                `${t(
                  "You have unsaved changes",
                  "You have unsaved changes"
                )}. ${t(
                  "Are you sure you want leave?",
                  "Are you sure you want leave?"
                )}`
              }
            />
            <div className="subtitle">
              <TextToggledit
                text={values.title}
                placeholder={t("Title", "Title")}
                name={`title`}
                onChange={(event) => setFieldValue(`title`, event)}
              />
            </div>
            <div className="value">
              <TextToggledit
                text={values.text}
                placeholder={t("Text", "Text")}
                name={`text`}
                onChange={(event) => setFieldValue(`text`, event)}
              />
            </div>
            <div className="actions">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => onRemove(detail.id)}
              />
            </div>
            <FormAutoSave />
          </>
        )}
      </Formik>
    </div>
  );
};

const makeEmptyDetail = (params: {
  brandId: BrandProfile["id"];
}): BrandProfileHistoryDetails => ({
  id: -1,
  title: "",
  text: "",
  order: 0,
  seller: params.brandId,
});

export const HistoryDetails: React.FC = () => {
  const { values } = useFormikContext<BrandProfile>();
  const {
    user,
    fetchBrandHistoryDetails,
    createBrandHistoryDetail: createBrandHistoryDetails,
  } = useApi();
  const fetchBrandHistoryDetailsAsync = useAsync(fetchBrandHistoryDetails);
  const createBrandHistoryDetailsAsync = useAsync(createBrandHistoryDetails());
  const [details, setDetails] = useState<BrandProfileHistoryDetails[]>(
    values.history.details
  );

  if (fetchBrandHistoryDetailsAsync.isPending) {
    return <PageLoadingSpinner />;
  }

  const onAddDetail = () => {
    const newDetail = makeEmptyDetail({ brandId: user.brandId });
    setDetails((oldDetails) => [...oldDetails, { ...newDetail, id: -1 }]);
  };

  const onRemoveDetail = (id: number) => {
    setDetails((oldDetails) => oldDetails.filter((detail) => detail.id !== id));
  };

  const onSaveDetail = (
    detailUpdated: BrandProfileHistoryDetails,
    indexDetail: number
  ) => {
    setDetails((oldDetails) =>
      oldDetails.map((detail, index) => {
        if (detail.id === -1 && index === indexDetail) {
          detail = detailUpdated;
        }
        return detail;
      })
    );
  };

  return (
    <div className="brand-stats">
      {details.map((detail, indexDetail) => (
        <HistoryDetail
          key={detail.id}
          detail={detail}
          onRemove={onRemoveDetail}
          onSave={(detail) => onSaveDetail(detail, indexDetail)}
        />
      ))}
      <div className="entry add-entry">
        <div className="add-entry-button" onClick={onAddDetail}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    </div>
  );
};

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Category } from '../../../domain/Category';
import { RootState } from '../../../RootState';

/** Reducer */
export type CategoryState = Category;

const categoriesAdapter = createEntityAdapter<CategoryState>();
export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: categoriesAdapter.getInitialState(),
    reducers: {
        categoriesFetched: categoriesAdapter.setAll,
    },
});
export const categoriesActions = categoriesSlice.actions;

/** Selectors */
const categoriesSelectors = categoriesAdapter.getSelectors((state: RootState) => state.categories);

export const selectCategories = categoriesSelectors.selectAll;
export const selectCategoriesCollection = categoriesSelectors.selectEntities;
export const selectCategory = categoriesSelectors.selectById;

import { configureStore } from '@reduxjs/toolkit';

import { collectionsSlice } from './external/Collection/collections.state';
import { colorsSlice } from './external/Color/colors.state';
import { measurementSlice } from './external/Measurement/measurements.state';
import { categoriesSlice } from './external/Products/state/categories.state';
import { detailsSlice } from './external/Products/state/details.state';
import { productSlice } from './external/Products/state/product.state';
import { productListSlice } from './external/Products/state/productList.state';
import { segmentsSlice } from './external/Products/state/segments.state';
import { stocksSlice } from './external/Products/state/stocks.state';
import { variationsSlice } from './external/Products/state/variations.state';
import { sizesSlice } from './external/Size/sizes.state';
import { sizechartsSlice } from './external/Sizechart/sizechart.state';
import { texturesSlice } from './external/Texture/textures.state';

export const store = configureStore({
    reducer: {
        product: productSlice.reducer,
        products: productListSlice.reducer,
        sizes: sizesSlice.reducer,
        colors: colorsSlice.reducer,
        textures: texturesSlice.reducer,
        details: detailsSlice.reducer,
        variations: variationsSlice.reducer,
        stocks: stocksSlice.reducer,
        measurements: measurementSlice.reducer,
        categories: categoriesSlice.reducer,
        segments: segmentsSlice.reducer,
        collections: collectionsSlice.reducer,
        sizecharts: sizechartsSlice.reducer,
    },
});

import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { useOrderableGridContext } from "./OrderableGrid";
import { OrderableGridHandleContextProvider } from "./OrderableGridHandle";

import "./OrderableGridItem.scss";

type ID = string | number;

interface OrderableGridItemProps {
  id: ID;
  onMoveItem: (source: any, destination: any) => void;
  onDropItem?: (source: any) => void;
}
export const OrderableGridItem: React.FC<OrderableGridItemProps> = (props) => {
  const ref = useRef(null);
  const previewRef = useRef(null);
  const { itemsType } = useOrderableGridContext();

  const [{ isDragging }, connectDrag, preview] = useDrag({
    item: { id: props.id, type: itemsType },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const [, connectDrop] = useDrop<{ id: ID; type: string }, any, any>({
    accept: itemsType,
    hover(hoveredOverItem) {
      if (hoveredOverItem.id !== props.id) {
        props.onMoveItem(hoveredOverItem.id, props.id);
      }
    },
    drop(hoveredOverItem) {
      if (props.onDropItem) {
        props.onDropItem(hoveredOverItem.id);
      }
    }
  });

  connectDrag(ref);
  connectDrop(preview(previewRef));

  const classNames = `orderable-grid-item${
    isDragging ? " orderable-grid-item-dragged" : ""
  }`;

  return (
    <OrderableGridHandleContextProvider value={ref}>
      <div className={classNames} ref={previewRef}>
        {props.children}
      </div>
    </OrderableGridHandleContextProvider>
  );
};

OrderableGridItem.defaultProps = {
  onDropItem: () => null
}

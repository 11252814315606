import { useState } from 'react';
import Swal from 'sweetalert2';

import {
    isPending,
    isSuccess,
    isFailure,
    setEmpty,
    setPending,
    setFailure,
    setSuccess,
    AsyncState,
    getData,
    getError,
} from './Products/state/AsyncState.helper';

export const useAsync = <P, R>(resolver: (params: P) => Promise<R>) => {
    const [state, setState] = useState<AsyncState<R, any>>(setEmpty());

    const start = (params: P) => {
        setState(setPending);
        return resolver(params)
            .then(results => {
                setState(setSuccess(results));
                return results;
            })
            .catch((error: any) => {
                if (error.response && error.response.status === 422) {
                  let errors = ''
                  const keysErrors = Object.keys(error.response.data);
                  if (keysErrors.length > 0) {
                      for (let index = 0; index < keysErrors.length; index++) {
                          errors += error.response.data[keysErrors[index]] + "\n";   
                      }
                  }  
                  Swal.fire('Error!', errors || 'An error ocurred, please review the inserted data.', 'error');
                }

                setState(setFailure(error));
                throw error;
            });
    };

    return {
        isPending: isPending(state),
        isSuccess: isSuccess(state),
        isFailure: isFailure(state),
        start,
        results: getData(state),
        error: getError(state),
    };
};

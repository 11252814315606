import { API_URL, isMockApi } from '../config';
import { Category } from '../domain/Category';

import { Categories } from './__fixtures__/categories';
import { HttpClient } from './http-client';

export const fetchCategories = (httpClient: HttpClient) => (): Promise<Category[]> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch categories');
              setTimeout(() => {
                  resolve(Categories);
              }, 1000);
          })
        : httpClient.get(`${API_URL}/v1/admin-seller/categories`)
            .then(res => res.data);

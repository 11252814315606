import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { useApi } from "../api/useApi";
import { AsyncButton, BottomBar } from "../common/BottomBar";
import { Page } from "../common/Page";
import { CollectionFields } from "../components/Collection/CollectionFields";
import { CollectionProducts } from "../components/Collection/CollectionProducts";
import { Collection, CollectionProduct } from "../domain/Collection";
import { LinesheetTemplate } from "../domain/Linesheet";
import { noop } from "../external/common/helpers";
import { useAsync } from "../external/useAsync";
import { isNil } from "../helpers";
import { useRoutes } from "../useRoutes";
import { CollectionFieldsSchema } from "./CollectionEdit.page";
import registerAccessGtm from "../services/gtmRegister";

const makeNewCollection = ({ brandId }: { brandId: number }): Collection => ({
  id: -1,
  sellerId: brandId,
  listImage: "",
  listImageThumbnail: null,
  orderWindowStart: null,
  orderWindowEnd: null,
  productionWindowStart: null,
  productionWindowEnd: null,
  lookbookUrl: "",
  name: "",
  isActive: false,
  isShopifyActive: false,
  typeButton: "o",
  deliveryMsg: "",
  currency: "",
  pressRelease: "",
  created: new Date(),
  updated: new Date(),
  linesheetImage: "",
  linesheetText: "",
  linesheetCover: "",
  linesheetTemplate: LinesheetTemplate.Template1,
  linesheetMoodboard: "",
  linesheetBackCover: "",
  linesheetShowVariation: false,
  collectionType: "future_delivery",
  markup: 0,
  wholesalePriceTip: "",
  retailerPriceTip: "",
  products: [],
});

const toUpdatedProducts = (
  products: CollectionProduct[]
): Record<CollectionProduct["id"], CollectionProduct["collectionOrder"]> => {
  return products.reduce(
    (acc, product, index) => ({ ...acc, [product.id]: index }),
    {}
  );
};

export const CollectionCreatePage: React.FC = () => {
  const { user, createCollection, updateCollectionProducts } = useApi();
  const createCollectionAsync = useAsync(createCollection(user.brandId));
  const updateCollectionProductsAsync = useAsync(
    updateCollectionProducts(user.brandId)
  );
  const routes = useRoutes();
  const formRef = useRef<any>(null);

  const [fields, setFields] = useState<Collection | undefined>();
  const [products, setProducts] = useState<CollectionProduct[] | undefined>();

  const { t } = useTranslation();

  useEffect(() => {
    registerAccessGtm()
  }, [])

  const createCollectionProcess = async (): Promise<Collection["id"]> => {
    if (isNil(fields)) {
      formRef?.current?.submitForm();
      Swal.fire(
        t("Error!", "Error!"),
        t("The name is required", "The name is required"),
        "error"
      );
      throw new Error("400");
    }
    const collection = await createCollectionAsync.start(fields);

    return collection.id;
  };
  const createCollectionProcessAsync = useAsync(createCollectionProcess);

  const onCreate = () =>
    createCollectionProcessAsync
      .start(undefined)
      .then(routes.collections.navigateById)
      .catch(noop);

  return (
    <Page
      title={t("Create collection", "Create collection")}
      after={
        <BottomBar>
          <AsyncButton
            status={createCollectionProcessAsync}
            label={t("Create collection", "Create collection")}
            successMsg={t(
              "Collection successfully created",
              "Collection successfully created"
            )}
            failureMsg={t(
              "Collection creation failed",
              "Collection creation failed"
            )}
            onClick={onCreate}
          />
        </BottomBar>
      }
    >
      <Formik
        initialValues={makeNewCollection({ brandId: user.brandId })}
        validationSchema={CollectionFieldsSchema}
        onSubmit={setFields}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <>
            <CollectionFields
              fields={makeNewCollection({ brandId: user.brandId })}
              onChange={setFields}
            />
            <CollectionProducts
              products={[]}
              onChange={(products) => setFieldValue("products", products)}
            />
          </>
        )}
      </Formik>
    </Page>
  );
};

import {
  faPen,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useState, useRef, useEffect } from "react";

import "./TextToggledit.scss";
import { useAsync } from "../useAsync";

import { AsyncButton } from "./AsyncButton";
import { noop, stopPropagation } from "./helpers";
import { PrimaryButton } from "./PrimaryButton";

interface TextToggleditProps {
  text: string;
  placeholder?: string;
  className?: string;
  multipleLines?: boolean;
  maxLength?: number;
  name: string;
  onChange: (event: string) => Promise<unknown> | void;
}
export const TextToggledit: React.FC<TextToggleditProps> = (props) => {
  const propsText = props.text;
  const [text, setText] = useState(propsText || "");
  const [isHovered, setHovered] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const onChangeAsPromise = (value: string) => {
    const onChangeRetValue = props.onChange(value);
    return !onChangeRetValue
      ? Promise.resolve(onChangeRetValue)
      : onChangeRetValue;
  };
  const onChangeAsync = useAsync(onChangeAsPromise);

  const multipleLines = props.multipleLines ?? false;
  const maxLength = props.maxLength || -1;

  const onDone = stopPropagation((event: any) => {
    event.preventDefault(); // TODO investigate why this is needed to not make an API call

    onChangeAsync
      .start(text)
      .then(() => setEditing(false))
      .catch(noop);
  });

  const onCancel = stopPropagation(() => {
    setEditing(false);
    setText(propsText);
  });

  useEffect(() => {
    setText(propsText || "");
  }, [propsText]);

  const editor = (
    <div
      className={cn("texttoggledit-editor", {
        "texttoggledit-async-error": onChangeAsync.isFailure,
      })}
      style={{
        display: isEditing ? "block" : "none",
      }}
    >
      <textarea
        className="texttoggledit-input"
        name={props.name}
        value={text}
        placeholder={props.placeholder}
        autoFocus
        maxLength={maxLength}
        onChange={(event) => setText(event.target.value)}
      />
      <div className="texttoggledit-editor-save">
        {maxLength > -1 ? (
          <span className="texttoggledit-editor-chars-left">
            {text.length} / {maxLength}
          </span>
        ) : null}

        <AsyncButton
          isPending={onChangeAsync.isPending}
          title={onChangeAsync.isFailure ? "Operation failed" : undefined}
          onClick={onDone}
        >
          <FontAwesomeIcon
            icon={onChangeAsync.isFailure ? faExclamationTriangle : faCheck}
          />
        </AsyncButton>
      </div>
    </div>
  );

  const content = (
    <div className="texttoggledit-wrapper">
      {editor}
      <div style={{ visibility: isEditing ? "hidden" : "visible" }}>
        <span className={cn({ "texttoggledit-placeholder": !text })}>
          {text || props.placeholder}
        </span>
        <FontAwesomeIcon icon={faPen} className="texttoggledit-icon" />
      </div>
    </div>
  );

  return (
    <div
      className={cn("texttoggledit", props.className, {
        "texttoggledit-active": isEditing,
        "texttoggledit-hovered": isHovered,
      })}
      onMouseOver={stopPropagation(() => setHovered(true))}
      onMouseOut={stopPropagation(() => setHovered(false))}
      onClick={stopPropagation(() => setEditing(true))}
    >
      {content}
    </div>
  );
};

export const FormTextToggledit: React.FC<{
  name: string;
  value: string;
  onChange: (event: any) => any;
  placeholder?: string;
}> = (props) => {
  const onChange = (value: string) => {
    props.onChange(props.name)({ target: { value } });
  };
  return (
    <TextToggledit
      name={props.name}
      text={props.value}
      onChange={onChange}
      placeholder={props.placeholder}
    />
  );
};

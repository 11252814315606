import { noop } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../api/useApi";

import { PrimaryButton } from "../common/PrimaryButton";
import { useAsync } from "../useAsync";

import "./AddNewSizechart.scss";

export const AddNewSizechart: React.FC<{
  onSave: (args: { name: string; id: number }) => void;
}> = (props) => {
  const [name, setName] = useState("");
  const { user, createSizechart } = useApi();
  const createSizechartAsync = useAsync(createSizechart(user.brandId));
  const { t } = useTranslation();

  const nameIsValid = name.length > 0;

  const onSave = () => {
    if (nameIsValid) {
      createSizechartAsync
        .start({ name })
        .then((response) => {
          props.onSave({ name, id: response.id });
        })
        .catch(noop);
    }
  };

  return (
    <form className="add-sizechart-form" onSubmit={(e) => e.preventDefault}>
      <h3>{t("Add new size chart", "Add new size chart")}</h3>
      <input
        type="text"
        className="sizechart-name-input"
        placeholder={t("Name", "Name")}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <div className="save-button">
        <PrimaryButton onClick={onSave} type="button">
          {t("Save", "Save")}
        </PrimaryButton>
      </div>
    </form>
  );
};

// @ts-nocheck
import React, {useEffect, useState} from 'react';
import './Dashboard.scss';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import Axios from 'axios';
import DynamicCard from '../../components/Analytics/DynamicCard/DynamicCard';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar} from 'recharts';
import Tour from '../../components/Analytics/Tour/Tour';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {parseCookies} from "nookies";
import registerAccessGtm from '../../services/gtmRegister';
interface Values {
    length: number;
    month: Month;
    year: number;
    day: number;
}

interface Month {
    name: string;
    shortName: string;
    number: number;
}

function formatNumber(number: any) {
    if (number === null) {
        return number;
    }
    number.toString();
    if (number > 1000) {
        number = (number / 1000).toFixed(1) + 'k';
    } else if (number > 10000) {
        number = (number / 1000).toFixed(0) + 'k';
    } else if (number > 100000) {
        number = (number / 1000).toFixed(0) + 'k';
    } else if (number > 100000000) {
        number = (number / 1000000).toFixed(1) + 'm';
    }

    return number;
}

function calculatePercentage(number1: any, number2: any) {
    if (number1 === null) {
        number1 = 0;
    }

    if (number2 === null) {
        number2 = 0;
    }
    if (number1 !== 0 && number2 !== 0) {
        let percentage = (number1 / number2) * 100;

        if (percentage <= 100) {
            percentage = percentage - 100;
            percentage = percentage - percentage * 2;
        } else if (percentage !== 0) {
            percentage = percentage - 100;
        }

        return percentage.toFixed(2);
    } else {
        let percentage = 0;
        return percentage;
    }
}

export const Dashboard = () => {
    const {t} = useTranslation();
    const [results, setResults]: any = useState([]);
    const [comparative, setComparative]: any = useState([]);
    const [data, setData]: any = useState(false);
    const [value, setValue] = useState<Values>({} as Values);
    const [dataChart, setDataChart]: any = useState([
        {
            views: 0,
            name: 'Select a range of months',
        },
    ]);
    const [barKeysView, setBarKeysView] = useState([]);
    const [dataChartColumn, setDataChartColumn]: any = useState([
        {
            views: 0,
            name: 'Select a range of months',
        },
    ]);
    const colors = [
        {
            color: '#A1A1A1',
        },
        {
            color: '#3B3B3B',
        },
        {
            color: '#A1A1B2',
        },
        {
            color: '#A1A1C3',
        },
        {
            color: '#A1A1D3',
        },
        {
            color: '#A1A1E3',
        },
        {
            color: '#A1A1F3',
        },
        {
            color: '#A1A1G3',
        },
        {
            color: '#A1A1H3',
        },
        {
            color: '#A1A1I3',
        },
    ];
    const [lastMonth, setLastMonth] = useState('-');
    const [currentMonth, setCurrentMonth] = useState('-');
    const [mesBeforeComeco, setMesBeforeComeco] = useState('-');
    const [mesBeforeFim, setMesBeforeFim] = useState('-');
    const [calendarMonth, setCalendarMonth] = useState(true);
    const [mesFilter, setMesFilter] = useState(false);
    const [showInputChangePicker, setShowInputChangePicker] = useState(false);
    const { "Blanc.Tour": TourMaded } = parseCookies();

    // visitors variables
    const [view, setView] = useState(0);
    const [arrowBalanceVisitors, setArrowBalanceVisitors]: any = useState(false);
    const [visitorsPercentage, setVisitorsPercentage] = useState(0);
    const [visitorsBalance, setVisitorsBalance] = useState(0);
    const [classVisitors, setClassVisitors] = useState('container--card--active');

    // brand visitors variables
    const [brandTotalView, setBrandTotalView] = useState(0);
    const [arrowBalanceBrandTotal, setArrowBalanceBrandTotal] = useState(false);
    const [brandTotalBalance, setBrandTotalBalance] = useState(0);
    const [brandTotalPercentage, setBrandTotalPercentage] = useState(0);
    const [classBrandVisitors, setClassBrandVisitors] = useState('container--card');

    // product views variables
    const [productView, setProductView] = useState(0);
    const [productViewArrow, setProductViewArrow] = useState(false);
    const [productViewBalance, setProductViewBalance] = useState(0);
    const [productViewPercentage, setProductViewPercentage] = useState(0);
    const [classProductVisitors, setClassProductVisitors] = useState('container--card');

    // Draft variables
    const [draftView, setDraftView] = useState(0);
    const [draftViewArrow, setDraftViewArrow] = useState(false);
    const [draftViewBalance, setDraftViewBalance] = useState(0);
    const [draftViewPercentage, setDraftViewPercentage] = useState(0);
    const [classDraftVisitors, setClassDraftVisitors] = useState('container--card');

    // Orders variables
    const [ordersView, setOrdersView] = useState(0);
    const [ordersViewArrow, setOrdersViewArrow] = useState(false);
    const [ordersViewBalance, setOrdersViewBalance] = useState(0);
    const [ordersViewPercentage, setOrdersViewPercentage] = useState(0);
    const [classOrdersVisitors, setClassOrdersVisitors] = useState('container--card');

    // About variables
    const [aboutView, setAboutView] = useState(0);
    const [aboutViewArrow, setAboutViewArrow] = useState(false);
    const [aboutViewBalance, setAboutViewBalance] = useState(0);
    const [aboutViewPercentage, setAboutViewPercentage] = useState(0);

    // Linesheet variables
    const [linesheetView, setLinesheetView] = useState(0);
    const [linesheetViewArrow, setLinesheetViewArrow] = useState(false);
    const [linesheetViewBalance, setLinesheetViewBalance] = useState(0);
    const [linesheetViewPercentage, setLinesheetViewPercentage] = useState(0);

    // Make a Buy variables
    const [makeABuyView, setMakeABuyView] = useState(0);
    const [makeABuyViewArrow, setMakeABuyViewArrow] = useState(false);
    const [makeABuyViewBalance, setMakeABuyViewBalance] = useState(0);
    const [makeABuyViewPercentage, setMakeABuyViewPercentage] = useState(0);

    // Product List variables
    const [productListView, setProductListView] = useState(0);
    const [productListViewArrow, setProductListViewArrow] = useState(false);
    const [productListViewBalance, setProductListViewBalance] = useState(0);
    const [productListViewPercentage, setProductListViewPercentage] = useState(0);

    // Brand History variables
    const [brandHistoryView, setBrandHistoryView] = useState(0);
    const [brandHistoryViewArrow, setBrandHistoryViewArrow] = useState(false);
    const [brandHistoryViewBalance, setBrandHistoryViewBalance] = useState(0);
    const [brandHistoryViewPercentage, setBrandHistoryViewPercentage] = useState(0);

    // Editable Lists variables
    const [editableListsView, setEditableListsView] = useState(0);
    const [editableListsViewArrow, setEditableListsViewArrow] = useState(false);
    const [editableListsViewBalance, setEditableListsViewBalance] = useState(0);
    const [editableListsViewPercentage, setEditableListsViewPercentage] = useState(0);

    useEffect(() => {
        registerAccessGtm()
    }, [])

    // Pegar o mês atual ao carregar a pagina
    useEffect(() => {
        const months = [
            {
                name: 'January',
                shortName: 'Jan',
                length: 31,
                index: 0,
                number: 1,
            },
            {
                name: 'February',
                shortName: 'Feb',
                length: 28,
                index: 1,
                number: 2,
            },
            {
                name: 'March',
                shortName: 'Mar',
                length: 31,
                index: 2,
                number: 3,
            },
            {
                name: 'April',
                shortName: 'Apr',
                length: 30,
                index: 3,
                number: 4,
            },
            {
                name: 'May',
                shortName: 'May',
                length: 31,
                index: 4,
                number: 5,
            },
            {
                name: 'June',
                shortName: 'Jun',
                length: 30,
                index: 5,
                number: 6,
            },
            {
                name: 'July',
                shortName: 'Jul',
                length: 31,
                index: 6,
                number: 7,
            },
            {
                name: 'August',
                shortName: 'Aug',
                length: 31,
                index: 7,
                number: 8,
            },
            {
                name: 'September',
                shortName: 'Sep',
                length: 30,
                index: 8,
                number: 9,
            },
            {
                name: 'October',
                shortName: 'Oct',
                length: 31,
                index: 9,
                number: 10,
            },
            {
                name: 'November',
                shortName: 'Nov',
                length: 30,
                index: 10,
                number: 11,
            },
            {
                name: 'December',
                shortName: 'Dec',
                length: 31,
                index: 11,
                number: 12,
            },
        ];
        const dataAtual = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)); // Dia anterior
        const ano = dataAtual.getFullYear();
        const untilMonth = dataAtual.getDate() === 1 ? dataAtual.getMonth() : dataAtual.getMonth() + 1;
        const fromMonth = dataAtual.getDate() === 1 ? dataAtual.getMonth() : dataAtual.getMonth() + 1;
        const dia = dataAtual.getDate() === 1 ? months[dataAtual.getMonth() - 1].length : dataAtual.getDate();
        const untilMonthName = dia === 1 
            ? new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, dataAtual.getDate()).toLocaleString('en-US', { month: 'long' }) 
            : dataAtual.toLocaleString('en-US', {month: 'long'});
        const fromMonthName = dia === 1 
            ? new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, dataAtual.getDate()).toLocaleString('en-US', { month: 'long' }) 
            : dataAtual.toLocaleString('en-US', {month: 'long'});

        const definirVariaveis = [
            {
                year: ano,
                month: {
                    number: fromMonth,
                    name: fromMonthName,
                },
                day: 1,
                months: months,
            },
            {
                year: ano,
                month: {
                    number: untilMonth,
                    name: untilMonthName,
                },
                day: dia,
                months: months,
            },
        ];

        setValue(definirVariaveis);
    }, []);

    function handleChange(valor: any) {
        setValue(valor);
    }

    function handleChangeMonth(valor: any) {
        setMesFilter(true);
        setValue(valor);
    }

    function calculateBalance(number1: number, number2: number) {
        if (number1 === null) {
            number1 = 0;
        }
        if (number2 === null) {
            number2 = 0;
        }
        let viewBalance = number1 - number2;

        if (viewBalance < 0) {
            viewBalance = viewBalance - viewBalance * 2;
        }
        return viewBalance;
    }

    function calculateArrow(number1: number, number2: number) {
        let viewBalance = number1 - number2;
        return viewBalance;
    }

    useEffect(() => {
        if (value !== undefined && value.length > 1) {
            setCurrentMonth(value[0].month.name);
            setLastMonth(value[1].month.name);

            let from = `${value[0].year}-${value[0].month.number}-${value[0].day}`;
            let until = `${value[1].year}-${value[1].month.number}-${value[1].day}`;

            if (mesFilter) {
                dayjs.extend(utc);
                dayjs.extend(timezone);

                until = dayjs(until).endOf('M').tz(dayjs.tz.guess()).format('YYYY-MM-DD');
                setMesFilter(false);
            }

            from = dayjs(from).format('YYYY-MM-DD');
            until = dayjs(until).format('YYYY-MM-DD');

            Axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/v1/admin-seller/metrics`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                params: {
                    from: from,
                    until: until,
                },
            })
                .then((response) => {
                    setData(true);
                    setResults(response.data.data.results);
                    setComparative(response.data.data.comparative);
                })
                .catch((response) => {
                    console.log(response);
                });
        } else {
            return;
        }
    }, [value]);

    function processData(visitors, comparative, property) {
        if (!visitors || !comparative || !property) {
            return [];
        }

        const data = Object.entries(visitors).map(([date, values]) => ({
            views: values[property],
            name: `${date.substring(5, 7)}/${date.substring(8, 10)}`,
        }));

        const dataBefore = Object.entries(comparative).map(([date, values]) => ({
            views: values[property],
            name: `${date.substring(5, 7)}/${date.substring(8, 10)}`,
        }));

        const newData = data.map((item, index) => ({
            ...item,
            lastMonth: dataBefore[index]?.views,
            date_before: dataBefore[index]?.name,
        }));

        const dataCumulative = newData.reduce((acc, el) => {
            const last = acc[acc.length - 1];
            const views = (last ? last.views : 0) + el.views;
            const lastMonth = (last ? last.lastMonth : 0) + el.lastMonth;
            return [...acc, {...el, views, lastMonth}];
        }, []);

        return dataCumulative;
    }

    function Visitors() {
        const dataCumulative = processData(results.visitors, comparative.visitors, 'views');
        setClassVisitors('container--card--active');
        setClassBrandVisitors('container--card');
        setClassProductVisitors('container--card');
        setClassDraftVisitors('container--card');
        setClassOrdersVisitors('container--card');
        setDataChart(dataCumulative);
    }

    function BrandVisitors() {
        const dataCumulative = processData(results.brand_visitors, comparative.brand_visitors, 'views');
        setClassVisitors('container--card');
        setClassBrandVisitors('container--card--active');
        setClassProductVisitors('container--card');
        setClassDraftVisitors('container--card');
        setClassOrdersVisitors('container--card');
        setDataChart(dataCumulative);
    }

    function ProductViews() {
        const dataCumulative = processData(results.brand_visitors, comparative.brand_visitors, 'products');
        setClassVisitors('container--card');
        setClassBrandVisitors('container--card');
        setClassProductVisitors('container--card--active');
        setClassDraftVisitors('container--card');
        setClassOrdersVisitors('container--card');
        setDataChart(dataCumulative);
    }

    function DraftOrders() {
        const newData = processData(results.brand_visitors, comparative.brand_visitors, 'draft_orders');
        setClassVisitors('container--card');
        setClassBrandVisitors('container--card');
        setClassProductVisitors('container--card');
        setClassDraftVisitors('container--card--active');
        setClassOrdersVisitors('container--card');
        setDataChart(newData);
    }

    function Orders() {
        const newData = processData(results.brand_visitors, comparative.brand_visitors, 'orders');
        setDataChart(newData);
        setClassVisitors('container--card');
        setClassBrandVisitors('container--card');
        setClassProductVisitors('container--card');
        setClassDraftVisitors('container--card');
        setClassOrdersVisitors('container--card--active');
    }

    useEffect(() => {
        if (data) {
            // visitors
            const totalVisitors = results.total.visitors.views;
            const totalVisitorsComparative = comparative.total.visitors.views;
            setView(formatNumber(totalVisitors));
            setArrowBalanceVisitors(calculateArrow(totalVisitors, totalVisitorsComparative) >= 0);
            setVisitorsBalance(formatNumber(calculateBalance(totalVisitors, totalVisitorsComparative)));
            setVisitorsPercentage(calculatePercentage(totalVisitors, totalVisitorsComparative));

            // brand visitors
            const totalBrandVisitors = results.total.brand_visitors.views;
            const totalBrandVisitorsComparative = comparative.total.brand_visitors.views;
            setBrandTotalView(formatNumber(totalBrandVisitors));
            setArrowBalanceBrandTotal(calculateArrow(totalBrandVisitors, totalBrandVisitorsComparative) >= 0);
            setBrandTotalBalance(formatNumber(calculateBalance(totalBrandVisitors, totalBrandVisitorsComparative)));
            setBrandTotalPercentage(calculatePercentage(totalBrandVisitors, totalBrandVisitorsComparative));

            // product views
            const totalProductViews = results.total.brand_visitors.products;
            const totalProductViewsComparative = comparative.total.brand_visitors.products;
            setProductView(formatNumber(totalProductViews));
            setProductViewArrow(calculateArrow(totalProductViews, totalProductViewsComparative) >= 0);
            setProductViewBalance(formatNumber(calculateBalance(totalProductViews, totalProductViewsComparative)));
            setProductViewPercentage(calculatePercentage(totalProductViews, totalProductViewsComparative));

            // Draft views
            const totalDraft = results.total.brand_visitors.draft_orders;
            const totalDraftComparative = comparative.total.brand_visitors.draft_orders;
            setDraftView(formatNumber(totalDraft));
            setDraftViewArrow(calculateArrow(totalDraft, totalDraftComparative) >= 0);
            setDraftViewBalance(formatNumber(calculateBalance(totalDraft, totalDraftComparative)));
            setDraftViewPercentage(calculatePercentage(totalDraft, totalDraftComparative));

            // Orders views
            const totalOrders = results.total.brand_visitors.orders;
            const totalOrdersComparative = comparative.total.brand_visitors.orders;
            setOrdersView(formatNumber(totalOrders));
            setOrdersViewArrow(calculateArrow(totalOrders, totalOrdersComparative) >= 0);
            setOrdersViewBalance(formatNumber(calculateBalance(totalOrders, totalOrdersComparative)));
            setOrdersViewPercentage(calculatePercentage(totalOrders, totalOrdersComparative));

            // About views
            const totalAbout = results.total.brand_visitors.about;
            const totalAboutComparative = comparative.total.brand_visitors.about;
            setAboutView(formatNumber(totalAbout));
            setAboutViewArrow(calculateArrow(totalAbout, totalAboutComparative) >= 0);
            setAboutViewBalance(formatNumber(calculateBalance(totalAbout, totalAboutComparative)));
            setAboutViewPercentage(calculatePercentage(totalAbout, totalAboutComparative));

            // Linesheet views
            const totalLinesheet = results.total.brand_visitors.download_linesheet;
            const totalLinesheetComparative = comparative.total.brand_visitors.download_linesheet;
            setLinesheetView(formatNumber(totalLinesheet));
            setLinesheetViewArrow(calculateArrow(totalLinesheet, totalLinesheetComparative) >= 0);
            setLinesheetViewBalance(formatNumber(calculateBalance(totalLinesheet, totalLinesheetComparative)));
            setLinesheetViewPercentage(calculatePercentage(totalLinesheet, totalLinesheetComparative));

            // Make a buy views
            const totalMakeABuy = results.total.brand_visitors.make_a_buy;
            const totalMakeABuyComparative = comparative.total.brand_visitors.make_a_buy;
            setMakeABuyView(formatNumber(totalMakeABuy));
            setMakeABuyViewArrow(calculateArrow(totalMakeABuy, totalMakeABuyComparative) >= 0);
            setMakeABuyViewBalance(formatNumber(calculateBalance(totalMakeABuy, totalMakeABuyComparative)));
            setMakeABuyViewPercentage(calculatePercentage(totalMakeABuy, totalMakeABuyComparative));

            // Product List views
            const totalProductList = results.total.brand_visitors.download_product_list;
            const totalProductListComparative = comparative.total.brand_visitors.download_product_list;
            setProductListView(formatNumber(totalProductList));
            setProductListViewArrow(calculateArrow(totalProductList, totalProductListComparative) >= 0);
            setProductListViewBalance(formatNumber(calculateBalance(totalProductList, totalProductListComparative)));
            setProductListViewPercentage(calculatePercentage(totalProductList, totalProductListComparative));

            // Brand History views
            const totalBrandHistory = results.total.brand_visitors.history;
            const totalBrandHistoryComparative = comparative.total.brand_visitors.history;
            setBrandHistoryView(formatNumber(totalBrandHistory));
            setBrandHistoryViewArrow(calculateArrow(totalBrandHistory, totalBrandHistoryComparative) >= 0);
            setBrandHistoryViewBalance(formatNumber(calculateBalance(totalBrandHistory, totalBrandHistoryComparative)));
            setBrandHistoryViewPercentage(calculatePercentage(totalBrandHistory, totalBrandHistoryComparative));

            // Editable List views
            const totalEditableLists = results.total.brand_visitors.history;
            const totalEditableListsComparative = comparative.total.brand_visitors.history;
            setEditableListsView(formatNumber(totalEditableLists));
            setEditableListsViewArrow(calculateArrow(totalEditableLists, totalEditableListsComparative) >= 0);
            setEditableListsViewBalance(
                formatNumber(calculateBalance(totalEditableLists, totalEditableListsComparative))
            );
            setEditableListsViewPercentage(calculatePercentage(totalEditableLists, totalEditableListsComparative));

            const visitors = results.visitors;
            var charData = [];

            for (let date in visitors) {
                const data = date.toString().substring(5, 7) + '/' + date.toString().substring(8, 10);

                var chart = {
                    views: visitors[date].views,
                    name: data,
                };

                charData.push(chart);
            }

            const visitorsBefore = comparative.visitors;
            var charDataBefore: any = [];
            var charDate: any = [];

            for (let date in visitorsBefore) {
                const data = date.toString().substring(5, 7) + '/' + date.toString().substring(8, 10);
                const mes = parseInt(date.toString().substring(5, 7));

                var chart = {
                    views: visitorsBefore[date].views,
                    name: data,
                };

                var datas = {
                    data: mes,
                };

                charDate.push(datas);
                charDataBefore.push(chart);
            }

            const lastArray = charDate.length - 1;

            const mesComeco = value[0].months.filter((mes) => mes.number === charDate[0].data);
            setMesBeforeComeco(mesComeco[0].name);
            const mesFim = value[0].months.filter((mes) => mes.number === charDate[lastArray].data);
            setMesBeforeFim(mesFim[0].name);

            const newData = charData.map((item, index) => ({
                ...item,
                lastMonth: charDataBefore[index]?.views,
                date_before: charDataBefore[index]?.name,
            }));

            const dataCumulative = [];

            newData.map((el, helper) => {
                if (helper !== 0) {
                    const nav = helper - 1;
                    const agora = dataCumulative[nav].views + el.views;
                    const passado = dataCumulative[nav].lastMonth + el.lastMonth;
                    el.views = agora;
                    el.lastMonth = passado;
                    dataCumulative.push(el);
                } else {
                    dataCumulative.push(el);
                }
            });

            setDataChart(dataCumulative);

            const collections = results.total.collection_visitors;

            if (collections.length === 0) {
                setDataChartColumn([
                    {
                        views: 0,
                        name: 'No data in the selected range of months',
                    },
                ]);
                return;
            } else {
                var collectionsData = [];

                for (let collection in collections) {
                    var collectionNumber = collections[collection];
                    collectionsData.push(collectionNumber);
                }

                const dataset = Object.values(collectionsData).reduce((acc, metrics) => {
                    const obj = metrics.reduce((acc2: any, metric: any) => {
                        if (metric.collection !== null) {
                            acc2[metric.collection.name] = {
                                name: metric.collection.name,
                                views: metric.views,
                            };
                        }

                        return acc2;
                    }, {});

                    acc.push(obj);
                    return acc;
                }, []);

                setDataChartColumn(dataset);

                const barKeys = dataset.reduce((acc: any, data: any) => {
                    const keys = Object.keys(data).filter((key) => key !== 'name');

                    keys.forEach((key) => {
                        acc.indexOf(key) === -1 && acc.push(key);
                    });

                    return acc;
                }, []);

                setBarKeysView(barKeys);
            }
        } else {
            return;
        }
    }, [comparative]);

    // Resetando card
    const resetCard = () => {
        mesFilter && Visitors()
    }
    useEffect(() => {
        resetCard()
    }, [mesFilter])


    return (
        <div className='dashboard'>
            <div className='dashboard--container'>
                <div className='dashboard--container--periodSelector'>
                    <div className='periodSelector'>
                        <p>{t('Period', 'Period')}</p>
                        <>
                            {calendarMonth ? (
                                <DatePicker
                                    onlyMonthPicker
                                    range
                                    plugins={[<DatePanel />]}
                                    onChange={handleChangeMonth}
                                    render={<InputIcon />}
                                />
                            ) : (
                                <DatePicker
                                    rangeHover
                                    range
                                    plugins={[<DatePanel />]}
                                    onChange={handleChange}
                                    render={<InputIcon />}
                                />
                            )}
                        </>

                        <div className='inputChangePicker'>
                            <button
                                type='button'
                                title='change picker'
                                className='inputChangePicker--button'
                                onClick={(e) => {
                                    e.currentTarget.classList.toggle('active');
                                    setShowInputChangePicker(!showInputChangePicker);
                                }}
                            >
                                <svg className='inputChangePicker--button-svg' viewBox='0 0 512 512'>
                                    <path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z' />
                                </svg>
                            </button>

                            {showInputChangePicker ? (
                                <ul className='inputChangePicker--list'>
                                    <li className='inputChangePicker--list--item'>
                                        <input
                                            type='radio'
                                            id='MonthSelector'
                                            name='selector'
                                            onClick={() => {
                                                setCalendarMonth(true);
                                            }}
                                            defaultChecked={calendarMonth}
                                        />
                                        <label htmlFor='MonthSelector'>{t('Month Selector', 'Month Selector')}</label>
                                    </li>
                                    <li className='inputChangePicker--list--item'>
                                        <input
                                            type='radio'
                                            id='DaySelector'
                                            name='selector'
                                            onClick={() => {
                                                setCalendarMonth(false);
                                            }}
                                            defaultChecked={!calendarMonth}
                                        />
                                        <label htmlFor='DaySelector'>{t('Day Selector', 'Day Selector')}</label>
                                    </li>
                                </ul>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    {
                        TourMaded ? (
                            <></>
                        ):(
                            <Tour />
                        )
                    }
                </div>
                <div className='dashboard--balance'>
                    <DynamicCard
                        id='Visitors'
                        title={'Visitors'}
                        number={view}
                        averagePercentage={visitorsPercentage}
                        averageNumber={visitorsBalance}
                        balance={arrowBalanceVisitors}
                        display={'column'}
                        classe={classVisitors}
                        onClick={() => {
                            Visitors();
                        }}
                    />
                    <DynamicCard
                        title={'Brand views'}
                        id='BrandViews'
                        number={brandTotalView}
                        averagePercentage={brandTotalPercentage}
                        averageNumber={brandTotalBalance}
                        balance={arrowBalanceBrandTotal}
                        display={'column'}
                        classe={classBrandVisitors}
                        onClick={() => {
                            BrandVisitors();
                        }}
                    />
                    <DynamicCard
                        title={'Product views'}
                        id='ProductViews'
                        number={productView}
                        averagePercentage={productViewPercentage}
                        averageNumber={productViewBalance}
                        balance={productViewArrow}
                        display={'column'}
                        classe={classProductVisitors}
                        onClick={() => {
                            ProductViews();
                        }}
                    />
                    <DynamicCard
                        title={'Draft Orders'}
                        id='DraftOrders'
                        number={draftView}
                        averagePercentage={draftViewPercentage}
                        averageNumber={draftViewBalance}
                        balance={draftViewArrow}
                        display={'column'}
                        classe={classDraftVisitors}
                        onClick={() => {
                            DraftOrders();
                        }}
                    />
                    <DynamicCard
                        title={'Orders'}
                        id='Orders'
                        number={ordersView}
                        averagePercentage={ordersViewPercentage}
                        averageNumber={ordersViewBalance}
                        balance={ordersViewArrow}
                        display={'column'}
                        classe={classOrdersVisitors}
                        onClick={() => {
                            Orders();
                        }}
                    />
                </div>
                <div className='chart'>
                    <div className='dashboard--chart'>
                        <ResponsiveContainer>
                            <AreaChart
                                data={dataChart}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis dataKey='name' />
                                <YAxis />
                                <Tooltip />
                                <Area
                                    type='monotone'
                                    dataKey='views'
                                    stroke='#9c59e4'
                                    fill='#8884d849'
                                    strokeWidth={2}
                                    name='Views'
                                />
                                <Area
                                    type='monotone'
                                    dataKey='lastMonth'
                                    stroke='#00000070'
                                    fill='#00000010'
                                    strokeWidth={2}
                                    strokeDasharray='2 2'
                                    name='A Month Behind'
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                        <div className='chart--desc'>
                            <div className='chart--desc__current'>
                                <span></span>
                                <p>
                                    <b>from</b> {currentMonth} <b>to</b> {lastMonth}
                                </p>
                            </div>
                            <div className='chart--desc__last'>
                                <span></span>
                                <p>
                                    <b>from</b> {mesBeforeComeco} <b>to</b> {mesBeforeFim}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='chart-cards'>
                    <div className='final--content'>
                        <div className='bar--chart'>
                            <ResponsiveContainer>
                                <BarChart
                                    data={dataChartColumn}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    {barKeysView.map((key, helper) => {
                                        return (
                                            <Bar
                                                dataKey={`${key}.views`}
                                                name={`${key}`}
                                                fill={colors[helper].color}
                                                id={`${key}`}
                                            />
                                        );
                                    })}
                                </BarChart>
                            </ResponsiveContainer>
                            <div className='description'>
                                {barKeysView.map((key, helper) => {
                                    const color = colors[helper].color;

                                    return (
                                        <div className='span__elements'>
                                            <span style={{color: color, border: `5px solid ${color}`}}></span>
                                            <p>{key}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='cards--bellow'>
                            <div className='brandpage--cards'>
                                <div className='cards--download'>
                                    <p>{t('BRAND PAGE', 'BRAND PAGE')}</p>
                                </div>
                                <DynamicCard
                                    title={'ABOUT THE BRAND'}
                                    number={aboutView}
                                    averagePercentage={aboutViewPercentage}
                                    averageNumber={aboutViewBalance}
                                    balance={aboutViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                                <DynamicCard
                                    title={'MAKE A BUY'}
                                    number={makeABuyView}
                                    averagePercentage={makeABuyViewPercentage}
                                    averageNumber={makeABuyViewBalance}
                                    balance={makeABuyViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                                <DynamicCard
                                    title={'BRAND HISTORY'}
                                    number={brandHistoryView}
                                    averagePercentage={brandHistoryViewPercentage}
                                    averageNumber={brandHistoryViewBalance}
                                    balance={brandHistoryViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                            </div>
                            <div className='downloads--cards'>
                                <div className='cards--download'>
                                    <p>{t('DOWNLOADS', 'DOWNLOADS')}</p>
                                </div>
                                <DynamicCard
                                    title={'LINESHEETS'}
                                    number={linesheetView}
                                    averagePercentage={linesheetViewPercentage}
                                    averageNumber={linesheetViewBalance}
                                    balance={linesheetViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                                <DynamicCard
                                    title={'LIST OF PRODUCTS'}
                                    number={productListView}
                                    averagePercentage={productListViewPercentage}
                                    averageNumber={productListViewBalance}
                                    balance={productListViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                                <DynamicCard
                                    title={'EDITABLE LISTS'}
                                    number={editableListsView}
                                    averagePercentage={editableListsViewPercentage}
                                    averageNumber={editableListsViewBalance}
                                    balance={editableListsViewArrow}
                                    display={'flex'}
                                    classe={'cards--bellow__individual'}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

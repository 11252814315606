import { ThemeProvider, createTheme } from '@material-ui/core';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ApiProvider } from './api/useApi';
import App from './App';
import { store } from './store';

import './index.scss';

const theme = createTheme({
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
                <ApiProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </ApiProvider>
            </DndProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

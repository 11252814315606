import React from 'react';

import './MainHeader.scss';

interface MainHeaderProps {
    children: React.ReactNode;
}
export const MainHeader: React.FC<MainHeaderProps> = props => {
    return (
        <h3 className='simple-title'>
            {/* <span className='underline' /> */}
            <span className='title'>{props.children}</span>
            {/* <span className='underline' /> */}
        </h3>
    );
};

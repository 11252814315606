import {
  faImage,
  faInfo,
  faInfoCircle,
  faLink,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { BrandProfile } from "../../domain/BrandProfile";
import { Imagedit } from "../../external/common/Imageedit/Imagedit";
import { PlaceholderImage } from "../../external/common/PlaceholderImage";
import { SmartModal } from "../../external/common/SmartModal";
import { TextToggledit } from "../../external/common/TextToggledit";

const OneBigFourSmall: React.FC<{
  images: any[];
  loadingPreview: boolean | number;
}> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<BrandProfile>();

  const handleSaveImage = (image: any, i: any) => {
    const imageAtual = image.length > 1 ? image[1] : image[0];
    let index = values.theBrand.brandStyle.images.findIndex(
      (image) => image.id === props.images[i]?.id && image.remover !== true
    );

    if (imageAtual.remover) {
      let auxImages = { ...values.theBrand.brandStyle.images[index] };
      auxImages.remover = true;
      auxImages.novaImagem = undefined;
      auxImages.imageSrc = "";
      setFieldValue(`theBrand.brandStyle.images[${index}]`, auxImages);
    } else {
      setFieldValue(`theBrand.brandStyle.imagePreviewIndex`, i);
      if (index === -1) {
        let auxImages = {
          ...values.theBrand.brandStyle.images[
            values.theBrand.brandStyle.images.length
          ],
        };
        auxImages.id = Math.random();
        auxImages.title = values.theBrand.brandStyle.images[index]?.title || "";
        auxImages.novaImagem = imageAtual.file;
        auxImages.imageSrc = imageAtual.src;
        auxImages.novaImagemCrop = imageAtual.crop;
        auxImages.originalImage = imageAtual.src;
        auxImages.cropOriginal = imageAtual.cropOriginal;

        const filteredImages = values.theBrand.brandStyle.images.filter(
          (item) =>
            !(
              item.remover === false &&
              item.imageSrc === "" &&
              item.id === null
            )
        );
        
        setFieldValue(
          `theBrand.brandStyle.images[${filteredImages.length}]`,
          auxImages
        );
        
      } else {
        let auxImages = { ...values.theBrand.brandStyle.images[index] };
        if (imageAtual.file) {
          auxImages.novaImagem = imageAtual.file;
        }
        auxImages.imageSrc = imageAtual.src;
        auxImages.novaImagemCrop = imageAtual.crop;
        auxImages.cropOriginal = imageAtual.cropOriginal;
        setFieldValue(`theBrand.brandStyle.images[${index}]`, auxImages);
      }
    }
  };

  let imgSrc = "";
  let items = [];
  for (let i = 1; i < 5; i++) {
    items.push(
      <div className="item" key={i}>
        <Imagedit
          allowEmpty={true}
          desiredDimensions={{ width: 285, height: 285 }}
          helpDimensions={{ width: 285, height: 285 }}
          render={() => (
            <>
              {props.loadingPreview === i ? (
                <PlaceholderImage loading />
              ) : (
                <>
                  {props.images[i]?.src ? (
                    <img src={props.images[i].src} />
                  ) : (
                    <div className="placeholder-image">
                      <FontAwesomeIcon icon={faImage} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          onSave={(image) => {
            handleSaveImage(image, i);
          }}
          title={t("Brand Style image", "Brand Style image")}
          src={props.images[i] || { src: "", crop: {}, id: Math.random() }}
        />
        <div className="title-box">
          <TextToggledit
            text={props.images[i]?.title || ""}
            placeholder={t("Image text", "Image text")}
            maxLength={50}
            name={`text`}
            onChange={(e) => {
              let index = values.theBrand.brandStyle.images
                .map((item) => item.id)
                .indexOf(props.images[i].id);
              setFieldValue(`theBrand.brandStyle.images[${index}].title`, e);
            }}
          />
        </div>
        <div className="open-link">
          <SmartModal
            body={({ closeModal }) => (
              <div className="smartmodal-brandstyle">
                <h3>{t("Edit Link/Label", "Edit Link/Label")}</h3>
                <TextToggledit
                  text={props.images[i]?.button_label || ""}
                  placeholder={t("SEE MORE", 'SEE MORE')}
                  maxLength={255}
                  name={`text`}
                  className="smartmodal-brandstyle-button-label"
                  onChange={(e: any) => {
                    let index = values.theBrand.brandStyle.images
                      .map((item) => item.id)
                      .indexOf(props.images[i]?.id);
                    setFieldValue(
                      `theBrand.brandStyle.images[${index}].button_label`,
                      e
                    );
                  }}
                />
                <TextToggledit
                  text={props.images[i]?.link || ""}
                  placeholder={t("Link", "Link")}
                  maxLength={255}
                  name={`text`}
                  className="smartmodal-brandstyle-link"
                  onChange={(e: any) => {
                    let index = values.theBrand.brandStyle.images
                      .map((item) => item.id)
                      .indexOf(props.images[i]?.id);
                    setFieldValue(
                      `theBrand.brandStyle.images[${index}].link`,
                      e
                    );
                  }}
                />
                <div className="save-button">
                  <PrimaryButton onClick={() => closeModal()}>
                    {t("Save", "Save")}
                  </PrimaryButton>
                </div>
              </div>
            )}
          >
            <FontAwesomeIcon
              title={t("Edit link", "Edit link")}
              icon={faLink}
              className="icon-button"
            />
          </SmartModal>
        </div>
      </div>
    );
  }
  return (
    <div className="one-big-four-small">
      <div className="left">
        <div className="item">
          <Imagedit
            allowEmpty={true}
            desiredDimensions={{ width: 580, height: 600 }}
            helpDimensions={{ width: 580, height: 600 }}
            render={() => (
              <>
                {props.loadingPreview === 0 ? (
                  <PlaceholderImage loading />
                ) : (
                  <>
                    {props.images[0]?.src ? (
                      <img src={props.images[0].src} />
                    ) : (
                      <div className="placeholder-image">
                        <FontAwesomeIcon icon={faImage} />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            onSave={(image) => {
              handleSaveImage(image, 0);
            }}
            title={t("Brand Style image", "Brand Style image")}
            src={props.images[0] || { src: "", crop: {}, id: Math.random() }}
          />
          <div className="title-box">
            <TextToggledit
              text={props.images[0]?.title || ""}
              placeholder={t("Image text", "Image text")}
              maxLength={50}
              name={`text`}
              onChange={(e) => {
                let index = values.theBrand.brandStyle.images
                  .map((item) => item.id)
                  .indexOf(props.images[0]?.id);
                setFieldValue(`theBrand.brandStyle.images[${index}].title`, e);
              }}
            />
          </div>
          <div className="open-link">
            <SmartModal
              body={({ closeModal }) => (
                <div className="smartmodal-brandstyle">
                  <h3>{t("Edit Link/Label", "Edit Link/Label")}</h3>
                  <TextToggledit
                    text={props.images[0]?.button_label || ""}
                    placeholder={t("SEE MORE", "SEE MORE")}
                    maxLength={255}
                    name={`text`}
                    className="smartmodal-brandstyle-button-label"
                    onChange={(e: any) => {
                      let index = values.theBrand.brandStyle.images
                        .map((item) => item.id)
                        .indexOf(props.images[0]?.id);
                      setFieldValue(
                        `theBrand.brandStyle.images[${index}].button_label`,
                        e
                      );
                    }}
                  />
                  <TextToggledit
                    text={props.images[0]?.link || ""}
                    placeholder={t("Link", "Link")}
                    maxLength={255}
                    name={`text`}
                    className="smartmodal-brandstyle-link"
                    onChange={(e) => {
                      let index = values.theBrand.brandStyle.images
                        .map((item) => item.id)
                        .indexOf(props.images[0]?.id);
                      setFieldValue(
                        `theBrand.brandStyle.images[${index}].link`,
                        e
                      );
                    }}
                  />
                  <div className="save-button">
                    <PrimaryButton onClick={() => closeModal()}>
                      {t("Save", "Save")}
                    </PrimaryButton>
                  </div>
                </div>
              )}
            >
              <FontAwesomeIcon
                title={t("Edit link", "Edit link")}
                icon={faLink}
                className="icon-button"
              />
            </SmartModal>
          </div>
        </div>
      </div>
      <div className="right">{items}</div>
    </div>
  );
};

export default OneBigFourSmall;

import { API_URL, isMockApi, STORAGE_URL } from '../config';
import { BrandProfile } from '../domain/BrandProfile';
import { Collection, CollectionProduct } from '../domain/Collection';
import { CollectionList } from '../domain/CollectionList';
import { Collections } from './__fixtures__/collections';
import { HttpClient } from './http-client';
import { toBase64 } from './brand.api';

export const fetchCollections = (httpClient: HttpClient) => (
    brandId: BrandProfile['id'],
): Promise<CollectionList> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch collections for BrandID', brandId);
              setTimeout(() => {
                  resolve(Collections);
              }, 1000);
          })
        : httpClient
              .get(`${API_URL}/v1/admin-seller/collections`)
              .then(res => res.data.data)
              .then(collections =>
                  collections.map((collection: any) => ({
                      id: collection.id,
                      name: collection.name,
                      listImage: collection.list_image,
                  })),
              )
              .then(collections => collections.slice(0, 20))
              .then(collections => ({ results: collections, pagination: {} as any }));

export const fetchCollection = (httpClient: HttpClient) => (
    id: Collection['id'],
): Promise<Collection> =>
    isMockApi
        ? new Promise((resolve, reject) => {
              console.log('[API] Fetch collection for ID', id);
              setTimeout(() => {
                //   resolve(AkraCollectionCollection);
              }, 1000);
          })
        : httpClient
              .get(`${API_URL}/v1/admin-seller/collections/${id}`)
              .then(res => res.data)
              .then(
                  collection =>
                      ({
                          id: collection.id,
                          sellerId: collection.seller_id,
                          listImage: `${collection.list_image}`,
                          listImageThumbnail: null,
                          orderWindowStart: collection.order_window_start,
                          orderWindowEnd: collection.order_window_end,
                          productionWindowStart: collection.production_window_start,
                          productionWindowEnd: collection.production_window_end,
                          lookbookUrl: collection.lookbook_url ?? '',
                          name: collection.name ?? '',
                          isActive: collection.is_active === 1,
                          isShopifyActive: collection.shopify_is_active,
                          typeButton: collection.type_button,
                          deliveryMsg: collection.delivery_msg ?? '',
                          currency: collection.currency ?? '',
                          pressRelease: collection.press_release ?? '',
                          created: collection.created,
                          updated: collection.updated,
                          linesheetImage: collection.linesheet_image,
                          linesheetText: collection.linesheet_text,
                          linesheetCover: null,
                          linesheetTemplate: null,
                          linesheetMoodboard: null,
                          linesheetBackCover: null,
                          linesheetShowVariation: null,
                          collectionType: collection.collection_type,
                          markup: +collection.markup,
                          wholesalePriceTip: collection.wholesale_price_tip ?? '',
                          retailerPriceTip: collection.retailer_price_tip ?? '',
                          products: collection.products,
                      } as any),
              );

const toAPICollection = async (collection: Collection) => ({
    list_image: collection.listImage.replace(new RegExp(`${STORAGE_URL}`, 'g'), ''),
    order_window_start: collection.orderWindowStart,
    order_window_end: collection.orderWindowEnd,
    production_window_start: collection.productionWindowStart,
    production_window_end: collection.productionWindowEnd,
    lookbook_url: collection.lookbookUrl,
    name: collection.name,
    is_active: collection.isActive ? 1 : 0,
    shopify_is_active: collection.isShopifyActive ? 1 : 0,
    type_button: collection.typeButton,
    delivery_msg: collection.deliveryMsg,
    currency: collection.currency,
    press_release: collection.pressRelease,
    created: collection.created,
    updated: collection.updated,
    nova_imagem: await toBase64(collection.nova_imagem),
    linesheet_image: collection.linesheetImage,
    linesheet_text: collection.linesheetText,
    linesheet_cover: collection.linesheetCover,
    linesheet_template: collection.linesheetTemplate,
    linesheet_moodboard: collection.linesheetMoodboard,
    linesheet_back_cover: collection.linesheetBackCover,
    linesheet_show_variation: collection.linesheetShowVariation,
    collection_type: collection.collectionType,
    markup: `${collection.markup}`,
    wholesale_price_tip: collection.wholesalePriceTip,
    retailer_price_tip: collection.retailerPriceTip,
    products: collection.products.map(product => product.id),
});

export const createCollection = (httpClient: HttpClient) => (brandId: number) => async (
    collection: Collection,
): Promise<Collection> =>
    // TODO: check collection create
    httpClient
        .post(`${API_URL}/v1/admin-seller/collections`, {
            ...await toAPICollection(collection),
            seller_id: brandId, // TODO: remove this field once the API is updated
        })
        .then(res => res.data);

export const updateCollection = (httpClient: HttpClient) => (
    collectionId: Collection['id'],
    brandId: number,
) => async (collection: Collection): Promise<void> =>
    // TODO: check collection update
    httpClient.put(`${API_URL}/v1/admin-seller/collections/${collectionId}`, {
        seller_id: brandId, // TODO: remove this field once the API is updated
        ...await toAPICollection(collection),
    });

export const updateCollectionProducts = (httpClient: HttpClient) => (brandId: number) => (args: {
    collectionId: Collection['id'];
    updatedProducts: Record<CollectionProduct['id'], CollectionProduct['collectionOrder']>;
}): Promise<void> =>
    // new Promise(resolve => setTimeout(resolve, 1000));
    // TODO: check collection update
    httpClient.put(`${API_URL}/v1/admin-seller/collections/${args.collectionId}/products`, args.updatedProducts);

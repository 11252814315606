import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useApi } from '../api/useApi';
import { CollectionList } from '../external/Collection/List/CollectionList';
import { noop } from '../external/common/helpers';
import { useAsync } from '../external/useAsync';

import { Page } from './common/Page';
import { PageError } from './common/PageError';
import { PageLoadingSpinner } from './common/PageLoadingSpinner';
import registerAccessGtm from '../services/gtmRegister';

export const CollectionListPage: React.FC = () => {
    const { user, fetchCollections } = useApi();
    const { isPending, isSuccess, isFailure, start, results } = useAsync(fetchCollections);
    const { t } = useTranslation();

    useEffect(() => {
        start(user.brandId).catch(noop);
    }, []);

    useEffect(() => {
        registerAccessGtm()
    }, [])

    if (isPending) {
        return <PageLoadingSpinner />;
    }

    if (isFailure) {
        return <PageError message={t("Error fetching data", "Error fetching data")} />;
    }

    if (isSuccess && !!results) {
        return (
            <Page className='collection-management'>
                <CollectionList collections={results.results} />
            </Page>
        );
    }

    return null;
};

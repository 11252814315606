import { TextField } from "@material-ui/core";
import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../../api/useApi";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { useCollectionState } from "../../common/useCollectionState";
import { CollectionProduct } from "../../domain/Collection";
import { ListCard } from "../../external/common/ListCard";
import { ModalTitle, ModalActions } from "../../external/common/Modal";
import { useAsync } from "../../external/useAsync";
import { useDebouncedEffect } from "../../external/useDebouncedEffect";
import { isNil } from "../../helpers";
import { LoadingSpinner } from "../LoadingSpinner";

import styles from "./AddProduct.module.scss";

export const AddProduct: React.FC<{
  disabledProductIds: CollectionProduct["id"][];
  onSave: (products: CollectionProduct[]) => void;
}> = (props) => {
  const [filter, setFilter] = useState<string>("");
  const selectedProducts = useCollectionState<CollectionProduct>([]);
  const { user, fetchProductListCollection } = useApi();
  const fetchProductListCollectionAsync = useAsync(
    fetchProductListCollection(user.brandId)
  );
  const [results, setResults] = useState<CollectionProduct[]>([]);

  useDebouncedEffect(
    () => {
      if (filter) {
        fetchProductListCollectionAsync
          .start({ name: filter })
          .then((results) => setResults(results));
      }
    },
    500,
    [filter]
  );

  const onToggleSelect = (product: CollectionProduct) => {
    if (isNil(selectedProducts.findById(product.id))) {
      selectedProducts.add(product);
    } else {
      selectedProducts.remove(product.id);
    }
  };

  const { t } = useTranslation();

  return (
    <div className={styles.addProductToCollection}>
      <ModalTitle>
        {t("Select a product to the collection", "Select a product to the collection")}
      </ModalTitle>
      <div className={styles.productFilter}>
        <TextField
          label={t("Filter by name", "Filter by name")}
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
        />
        {fetchProductListCollectionAsync.isPending ? <LoadingSpinner /> : null}
      </div>

      <div className={styles.productList}>
        {results.map((product) => (
          <ListCard
            className={classnames({
              [styles.selectedProduct]:
                selectedProducts.items.findIndex((p) => p.id === product.id) >
                -1,
              [styles.disabledProduct]:
                props.disabledProductIds.findIndex((p) => p === product.id) >
                -1,
            })}
            key={product.id}
            title={product.name}
            image={""}
            // image={product.images[0].pictureThumbnail}
            onClick={() => {
              if (!props.disabledProductIds.includes(product.id)) {
                onToggleSelect(product);
              }
            }}
          ></ListCard>
        ))}
      </div>

      <ModalActions>
        <PrimaryButton
          onClick={() => {
            if (selectedProducts !== undefined) {
              props.onSave(selectedProducts.items);
            }
          }}
        >
          {t("Save", "Save")}
        </PrimaryButton>
      </ModalActions>
    </div>
  );
};

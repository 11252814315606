import { API_URL, isMockApi } from "../config";
import { CollectionProduct } from "../domain/Collection";
import { Product } from "../domain/Product";
import { ProductSummary } from "../domain/ProductList";
import { normalizeProduct } from "../external/Products/state/product.schema";
import { NewEntity, transformProduct } from "../helpers";

import { AkraCollectionProductList } from "./__fixtures__/akra-collection.product-list";
import { OrixasBagProduct } from "./__fixtures__/orixas-bag.product";
import { ListResponse } from "./common";
import { HttpClient } from "./http-client";
import { toBase64 } from "./brand.api";

export type ProductDTO = Product;

export type ProductList = ListResponse<ProductSummary>;
const API_PRODUCTS_URL = `${API_URL}/v1/admin-seller/products`;

export const fetchProduct =
  (httpClient: HttpClient) =>
  (id: Product["id"]): Promise<Product> =>
    isMockApi
      ? new Promise((resolve) => {
          console.log("[API] Fetch Product", id);
          const product = normalizeProduct(OrixasBagProduct);

          setTimeout(() => resolve(product), 1000);
        })
      : httpClient
          .get(`${API_PRODUCTS_URL}/${id}`)
          .then((res) => res.data)
          .then((product) => transformProduct(product));

export const fetchProductList =
  (httpClient: HttpClient) =>
  (page: number) =>
  (params: any): Promise<ProductList> =>
    isMockApi
      ? new Promise((resolve) => {
          console.log("[API] Fetch Product List", params);
          setTimeout(() => resolve(AkraCollectionProductList), 1000);
        })
      : httpClient
          .get(`${API_PRODUCTS_URL}?page=${page}`)
          .then((res) => res.data)
          .then((productsData) => {
            productsData.data = productsData.data.map((product: any) => ({
              id: product.id,
              name: product.name,
              wholesalePrice: {
                currency: product.wholesale_price_currency,
                amount: product.wholesale_price,
              },
              retailerPrice: {
                currency: product.retailer_price_currency,
                amount: product.retailer_price,
              },
              variations: product.variations.map((v: any) => ({
                id: v.id,
                color: {
                  id: v.color?.id,
                  name: v.color?.name,
                  color: v.color?.color,
                  extraColor: v.color?.extra_color,
                },
                order: v.order,
                textureId: v.texture_id,
                texture: {
                  id: v.texture?.id,
                  name: v.texture?.name,
                  pictureTexture: v.texture?.picture,
                },
                images: v.images.map((image: any) => ({
                  pictureThumbnail: image.picture,
                })),
                stock: v.stock ?? [],
              })),
            }));
            return productsData;
          })
          .then((productsData) => {
            console.log(productsData);
            if (params && params.name) {
              productsData.data.filter((p: any) =>
                p.name.toUpperCase().match(params.name.toUpperCase())
              );
            }

            return productsData;
          });

export const fetchProductListCollection =
  (httpClient: HttpClient) =>
  (brandId: number) =>
  (params: any): Promise<CollectionProduct[]> =>
    isMockApi
      ? new Promise((resolve) => {
          console.log("[API] Fetch Product List", params);
          // setTimeout(() => resolve(AkraCollectionProductList), 1000);
        })
      : httpClient
          .get(`${API_PRODUCTS_URL}/collections?name=${params.name}`)
          .then((res) => res.data.data)
          .then((products) =>
            products.map((product: any) => ({
              id: product.id,
              name: product.name,
            }))
          )
          .then((products) => {
            if (params && params.name) {
              return products.filter((p: any) =>
                p.name.toUpperCase().match(params.name.toUpperCase())
              );
            }
            return products;
          });

export const fetchCollectionProducts =
  (httpClient: HttpClient) =>
  (collectionId: number) =>
  (): Promise<CollectionProduct[]> =>
    httpClient
      .get(`${API_URL}/v1/admin-seller/collections/${collectionId}/product`)
      .then((res) => res.data)
      .then((products) =>
        products
          .map(
            (product: any): CollectionProduct => ({
              id: product.id,
              name: product.name,
              wholesalePrice: {
                currency: product.wholesale_price,
                amount: product.wholesale_price_currency,
              },
              retailerPrice: {
                currency: product.retailer_price,
                amount: product.retailer_price_currency,
              },
              collectionOrder: product.collection_order,
            })
          )
          .sort((a: CollectionProduct, b: CollectionProduct) =>
            a.collectionOrder < b.collectionOrder ? -1 : 1
          )
      );
//   .then(products => ({ results: products, pagination: {} as any }));

const toAPIProduct = (product: NewEntity<Product>, sellerId?: number) => ({
  name: product.name,
  description: product.description,
  is_active: product.isActive,
  reference_code: product.referenceCode,
  internal_name: product.internalName,
  wholesale_price_currency: product.wholesalePrice?.currency ?? 0,
  wholesale_price: product.wholesalePrice?.amount ?? 0,
  retailer_price_currency: product.retailerPrice?.currency ?? 0,
  retailer_price: product.retailerPrice?.amount ?? 0,
  order_minimum_quantity: product.orderMinimumQuantity,
  order_maximum_quantity: product.orderMaximumQuantity,
  order_delivery_days: product.orderDeliveryDays,
  unit_weight: product.unitWeight,
  variations: product.variations
    ? product.variations
        .filter((v: any) => v.texture || v.color)
        .map((v: any) => ({
          id: v.id,
          color_id: v.color ? v.color.id : null,
          order: v.order,
          texture_id: v.texture ? v.texture.id : null,
          images: v.images ?? [],
          stock: v.stock ?? [],
          remover: v.remover ? 1 : 0,
        }))
    : [],
  sizes: product.sizes,
  collection_id: product.collection?.id ?? 0,
  collection_order: 0,
  details: product.details,
  categories: product.categories,
  segments: product.segments,
  sizechart_id: product.sizechart_id,
});

export const createProduct =
  (httpClient: HttpClient) =>
  (sellerId: number) =>
  (product: NewEntity<Product>): Promise<Product["id"]> => {
    const productData = toAPIProduct(product, sellerId);

    return isMockApi
      ? new Promise((resolve) => {
          console.log("[API] Create Product");
          setTimeout(() => resolve(123), 1000);
        })
      : httpClient
          .post(`${API_URL}/v1/admin-seller/products`, {
            ...productData,
          })
          .then((res) => res.data)
          .then((data) => data.id);
  };

export const updateProduct =
  (httpClient: HttpClient) =>
  (id: Product["id"]) =>
  (product: NewEntity<Product>): Promise<void> => {
    return isMockApi
      ? new Promise((resolve) => {
          console.log("[API] Update Product", id);
          setTimeout(() => resolve(), 1000);
        })
      : // TODO: check product edit
        httpClient.put(`${API_PRODUCTS_URL}/${id}`, {
          ...toAPIProduct(product),
        });
  };

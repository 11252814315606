import { createContext, useContext } from 'react';

export type TabID = string;

export interface TabbedViewContextContent {
    selectedTab: TabID;
    selectTab: (tab: TabID) => void;
}

const TabbedViewContext = createContext<TabbedViewContextContent>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedTab: undefined!,
    selectTab: () => {
        throw new Error(
            'TabbedViewContext used before initialization. Please use TabbedViewContextProvider before trying to access the TabbedViewContext',
        );
    },
});

export const TabbedViewContextProvider = TabbedViewContext.Provider;

export const useTabbedViewContext = (): TabbedViewContextContent => useContext(TabbedViewContext);

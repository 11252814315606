import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactModal from "react-modal";

import "./Modal.scss";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "32px",
    transform: "translate(-50%, -50%)",
  },
};

type ModalProps = ReactModal.Props;
export const Modal: React.FC<ModalProps> = (props) => {
  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender
      role="dialog"
      overlayClassName="modal-overlay"
      style={customStyles}
      {...props}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className="modal-close-button"
        onClick={props.onRequestClose}
      />
      {props.children}
    </ReactModal>
  );
};

export const ModalTitle: React.FC = (props) => (
  <div className="modal-title">{props.children}</div>
);

export const ModalActions: React.FC = (props) => (
  <div className="modal-actions">{props.children}</div>
);

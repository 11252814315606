import classnames from 'classnames';
import React from 'react';

import styles from './PrimaryButton.module.scss';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
export const PrimaryButton: React.FC<ButtonProps> = props => {
    const { className, ...restProps } = props;
    return (
        <button className={classnames(styles.primaryButton, className)} {...restProps}>
            {props.children}
        </button>
    );
};

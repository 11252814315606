import { useFormikContext, FieldArray } from "formik";
import React, { useEffect, useState } from "react";

import { InfoBlocks } from "../../components/InfoBlocks/InfoBlocks";
import { BrandProfile, SiteArea } from "../../domain/BrandProfile";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { ImageditImage } from "../common/Imageedit/ImageditImage.model";
import { PlaceholderImage } from "../common/PlaceholderImage";
import getCroppedImg from "../getCroppedImg";

import { HistoryDetails } from "./HistoryDetails";
// import { HistoryLookbooks } from "./Lookbooks";
import { HistorySocialMedia } from "./SocialLinks";

import "./History.scss";
import Lookbooks from "../../components/Lookbooks/Lookbooks";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { TextToggledit } from "../common/TextToggledit";
import { isMobile } from "../../helpers";
import { useTranslation } from "react-i18next";
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const perChunk = [8, 5, 2];

const HistoryInfo: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();

  const [images, setImages] = useState<any>([]);

  const [imgSrc, setImgSrc] = useState<string>();

  const transformCrop = (image: any, cropImage: any) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = cropImage.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: 300 / 450,
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.onerror = err => resolve({})
      img.src = image || "";
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      if (values.history && values.history.historyImageCropping !== null && values.history.historyImageCropping !== undefined) {
        const newImage = {
          id: values.history.historyImage,
          src: values.history.historyImage,
          originalImage: values.history.historyImageOriginal,
          imageCropping: values.history.historyImageCropping,
          crop: await transformCrop(
            values.history.historyImageOriginal,
            values.history.historyImageCropping.match(/(\d+,){3}\d+/)?.[0] || ''
          ),
        };
  
        setImgSrc(newImage.src);
        setImages([newImage]);
      }
    };
  
    loadImages();
  }, []);
  

  const { t } = useTranslation();

  const image = (
    <FieldArray
      name="history.historyImage"
      render={() => {
        return (
          <Imagedit
            allowEmpty={false}
            desiredDimensions={{ width: 300, height: 450 }}
            title={t(
              "Featured Info Block Images",
              "Featured Info Block Images"
            )}
            src={
              images?.map((image: any) => ({
                id: image.id,
                src: image.src,
                originalImage: image.originalImage,
                imageCropping: image.imageCropping,
                crop: image.crop,
              })) || []
            }
            render={() => (
              <div className="history-info-images">
                {imgSrc ? <img src={imgSrc} /> : <PlaceholderImage />}
              </div>
            )}
            onSave={(newImages) => {
              const newImage: any = newImages
                .map((img) => ({
                  featuredImage: img.src,
                  file: img.file,
                  crop: img.crop,
                }))
                .shift();
              if (newImage) {
                let img = new Image();
                img.onload = async () => {
                  const croppedImg: any = await getCroppedImg(
                    img,
                    newImages[0].crop
                  );
                  var objectURL = URL.createObjectURL(croppedImg);
                  setImgSrc(objectURL);
                };

                img.crossOrigin = "anonymous";

                if (!newImages[0].file && !newImages[0].src.startsWith("blob:")) {
                  img.src = newImages[0].src + `?x=123`;
                } else if(!newImages[0].file && newImages[0].src.startsWith("blob:")) {
                  img.src = newImages[0].src ;
                } else {
                  img.src = newImages[0].src;
                } 

                const historyImage = [
                  ...newImages.map((image: any) => ({
                    ...image,
                    crop: image.cropOriginal,
                  })),
                ];

                setImages(historyImage)
                setFieldValue("history.historyImage", newImages[0].src);
                setFieldValue("history.nova_imagem", newImages[0].file);
                setFieldValue("history.nova_imagem_crop", newImages[0].crop);
              }
            }}
          />
        );
      }}
    />
  );

  return (
    <div className="history-info">
      <div className="image">{image}</div>
      <div>
        <HistoryDetails />
        <div className="bottom-info">
          <HistorySocialMedia />
        </div>
      </div>
    </div>
  );

  // const topContent = (
  //     <div className='history-stats'>
  //         {values.history.details.map((detail, index) => (
  //             <div key={detail.id} className='entry'>
  //                 <div className='subtitle'>{detail.title}</div>
  //                 <div className='value'>
  //                     <TextToggledit
  //                         text={detail.text}
  //                         placeholder=''
  //                         name={`history.details.${index}.text`}
  //                         onChange={event =>
  //                             setFieldValue(`history.details.${index}.text`, event)
  //                         }
  //                     />
  //                 </div>
  //             </div>
  //         ))}
  //     </div>
  // );

  // const title = null;

  // const bottomContent = <HistorySocialMedia />;

  // return (
  //     <Showcase
  //         className='history-info'
  //         title={title}
  //         image={image}
  //         topContent={topContent}
  //         bottomContent={bottomContent}
  //     />
  // );
};

export const History: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [imgLookbook, setImgLookbook] = useState<any[]>([]);
  const { t } = useTranslation();
  const [loadingPreview, setLoadingPreview] = useState<boolean | number>(false);

  const handleSetTemplate = (template: number) => {
    setFieldValue("history.lookbooks.template", template);
  };

  const transformCrop = (image: any) => {
    return new Promise((resolve) => {
      if (!image.imageCropping580) {
        resolve({});
      }
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = auxImage?.imageCropping580?.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: 605 / 417,
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.src = image?.originalImage || "";
    });
  };

  const loadImagesPreview = (newImage: any) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = async () => {
        const croppedImg: any = await getCroppedImg(
          img,
          newImage.novaImagemCrop
        );
        var objectURL = URL.createObjectURL(croppedImg);
        resolve(objectURL);
      };
      img.crossOrigin = "anonymous";

      if (!newImage.file && newImage.imageCropping285) {
        img.src = newImage.imageSrc + `?x=123`;
      } else if(!newImage.file && !newImage.imageCropping285) {
        img.src = newImage.imageSrc;
      } else {
        img.src = newImage.imageSrc;
      } 
    });
  };

  useEffect(() => {
    setLoadingPreview(values.history.lookbooks.imagePreviewIndex || 0);
    let results: any[] = values.history.lookbooks.images
      .filter((item) => !item.remover)
      .reduce((resultArray: any[], item, index) => {
        const chunkIndex = Math.floor(
          index / perChunk[values.history.lookbooks.template - 1]
        );

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

    Promise.all(
      results.map((slide) => {
        return Promise.all(
          slide.map(async (img: any) => {
            return {
              id: img.id || `${Math.random()}`,
              src: img.novaImagemCrop
                ? await loadImagesPreview(img)
                : img.imageSrc,
              originalImage: img.originalImage || "",
              imageCropping: img.imageCropping580,
              title: img.title,
              cropOriginal: img.cropOriginal,
              crop: img.cropOriginal
                ? img.cropOriginal
                : await transformCrop(img),
            };
          })
        );
      })
    )
      .then((images: any) => {
        setImgLookbook(images);
      })
      .finally(() => setLoadingPreview(false))
      .catch(() => setLoadingPreview(false));
  }, [values.history.lookbooks.template, values.history.lookbooks.images]);

  return (
    <div className="history">
      <HistoryInfo />
      <InfoBlocks
        infoBlocks={values.history.infoBlock}
        pathPrefix={"history.infoBlock"}
        name={"history-infoblocks"}
        siteArea={SiteArea.History}
      />
      <div className="container-lookbooks">
        <h1>{t("Lookbooks", "Lookbooks")}</h1>
        <div className="text">
          <TextToggledit
            text={values.history.lookbooks.description || ""}
            placeholder={t("Lookbooks text", "Lookbooks text")}
            maxLength={900}
            multipleLines
            name={`text`}
            onChange={(e) => setFieldValue("history.lookbooks.description", e)}
          />
        </div>
        <Swiper
          pagination={{
            type: "bullets",
            clickable: true,
          }}
          allowTouchMove={isMobile()}
          slidesPerView={1}
          spaceBetween={10}
          navigation={true}
          className="mySwiper"
        >
          {imgLookbook.length > 0 ? (
            imgLookbook.map((item, index) => (
              <SwiperSlide>
                <Lookbooks
                  loadingPreview={loadingPreview}
                  template={values.history.lookbooks.template}
                  onSetTemplate={(val) => handleSetTemplate(val)}
                  images={imgLookbook[index]}
                  index={index}
                  chunkSize={imgLookbook.length}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <Lookbooks
                loadingPreview={loadingPreview}
                template={values.history.lookbooks.template}
                onSetTemplate={(val) => handleSetTemplate(val)}
                images={[]}
                index={0}
                chunkSize={1}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      {/* <HistoryLookbooks /> */}
    </div>
  );
};

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";

import "./SmartModal.scss";

interface SmartModalBodyProps {
  closeModal: () => void;
}

export const SmartModal: React.FC<{
  body: (props: SmartModalBodyProps) => JSX.Element;
  autoWidth?: boolean;
}> = (props) => {
  const [isOpened, setOpened] = useState(false);

  const onCloseModal = () => {
    setOpened(false);
  };

  const onOpenModal = () => {
    setOpened(true);
  };

  const body = (
    <div className={`smart-modal-body${props.autoWidth ? " auto-width" : ""}`}>
      {props.body({ closeModal: onCloseModal })}{" "}
      <FontAwesomeIcon
        className="smart-modal-close-button"
        icon={faTimes}
        onClick={onCloseModal}
      />
    </div>
  );

  return (
    <>
      <div onClick={onOpenModal}>{props.children}</div>
      <Modal
        className="smart-modal"
        open={isOpened}
        onClose={onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};

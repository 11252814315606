// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

type StopPropagationEvents = React.MouseEvent<unknown, MouseEvent> | React.KeyboardEvent<Element>;
export const stopPropagation = (fn: (event?: StopPropagationEvents) => void) => (
    event?: StopPropagationEvents,
): void => {
    if (event) {
        event.stopPropagation();
    }
    fn(event);
};

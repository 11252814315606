import React, { useState } from 'react';

import { TabbedViewContextProvider } from './common';

interface TabbedViewProps {
    defaultTab: string;
}
export const TabbedView: React.FC<TabbedViewProps> = props => {
    const [selectedTab, setSelectedTab] = useState(props.defaultTab);

    return (
        <TabbedViewContextProvider
            value={{
                selectedTab,
                selectTab: setSelectedTab,
            }}
        >
            {props.children}
        </TabbedViewContextProvider>
    );
};

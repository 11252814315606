import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { Menu } from "../common/Menu";
import { TextToggledit } from "../common/TextToggledit";

import {
  selectMeasurement,
  MeasurementState,
  measurementsActions,
} from "./measurements.state";

import "./MeasurementCard.scss";
import { Measurement } from "../../domain/Measurement";
import { useApi } from "../../api/useApi";
import { useTranslation } from "react-i18next";

export const MeasurementCard: React.FC<{ id: MeasurementState["id"] }> = (
  props
) => {
  const measurement = useSelector(selectMeasurement(props.id));
  const dispatch = useDispatch();
  const api = useApi();
  const { t } = useTranslation();

  if (!measurement) {
    return null;
  }

  const updateMeasurement = (changes: Partial<Measurement>) =>
    api.updateMeasurement(measurement.id)({
      ...measurement,
      ...changes,
    } as any);

  const deleteMeasurement = (id: number) => {
    Swal.fire({
      title: t(
        "Are you sure you want to delete this measurement?",
        "Are you sure you want to delete this measurement?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Delete", "Delete"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .deleteMeasurement(id)
          .then(() => dispatch(measurementsActions.removed(measurement.id)))
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              let errors = "";
              const keysErrors = Object.keys(error.response.data);
              if (keysErrors.length > 0) {
                for (let index = 0; index < keysErrors.length; index++) {
                  errors += error.response.data[keysErrors[index]] + "\n";
                }
              }
              Swal.fire(
                t("Error!", "Error!"),
                errors ||
                  t(
                    "An error ocurred, please review the inserted data.",
                    "An error ocurred, please review the inserted data."
                  ),
                "error"
              );
            }

            throw Promise.reject(error);
          });
      }
    });
  };

  return (
    <Card className="measurement-card">
      <CardContent>
        <TextToggledit
          name="measurement-name"
          text={measurement.name}
          onChange={(name) => {
            dispatch(
              measurementsActions.nameChanged({ id: measurement.id, name })
            );
            return updateMeasurement({ name });
          }}
        />
      </CardContent>
      <CardActions className="measurement-actions">
        <Menu
          icon={faEllipsisH}
          render={({ close }) => {
            const onDelete = () => {
              deleteMeasurement(measurement.id);
              close();
            };

            return (
              <MenuItem onClick={onDelete}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary={t("Delete", "Delete")} />
              </MenuItem>
            );
          }}
        ></Menu>
      </CardActions>
    </Card>
  );
};

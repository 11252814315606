import { faImage, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Compressor from "compressorjs";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CroppableImage, Crop } from "../../../components/CroppableImage";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { FileUpload } from "../FileUpload";
import { YoutubeEmbedVideo } from "../YoutubeEmbedVideo";
import { useAsyncImage } from "../useAsyncImage";
import { VideoUrl, VideoUrlHandler } from "./VideoUrl";

export interface Dimensions {
  width: number;
  height: number;
}

const dimensionIsLower = (dim1: Dimensions, dim2: Dimensions): boolean =>
  dim1.width < dim2.width || dim1.height < dim2.height;

const renderDimensions = (dimensions: Dimensions): string =>
  `${Math.ceil(dimensions.width)}x${Math.ceil(dimensions.height)} px`;

const defaultCrop: Crop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: "%",
};

export interface ImageditEditorChangeEvent {
  src: string;
  crop: Crop;
  file?: File;
}

const getPercentage = (value1: number, value2: number): number =>
  (value1 / value2) * 100;

interface ImageditEditorProps {
  allowEmpty: boolean;
  crop: Crop | undefined;
  desiredDimensions: Dimensions;
  onChange: (event: ImageditEditorChangeEvent) => void;
  onRemove: () => void;
  videoUrlHandler?: VideoUrlHandler;
  src: string;
  file?: File;
  noCrop: boolean;
  helpDimensions?: Dimensions;
}
export const ImageditEditor: React.FC<ImageditEditorProps> = (props) => {
  const {
    allowEmpty,
    crop,
    desiredDimensions,
    src,
    file,
    onRemove,
    videoUrlHandler,
    noCrop,
    helpDimensions,
  } = props;

  const { isPending, isFailure, isSuccess, width, height } = useAsyncImage({
    src,
  });

  const [currentCrop, setCurrentCrop] = useState<Crop>();
  const [imageChange, setImageChange] = useState<File | undefined>(file);

  const { t } = useTranslation();

  useEffect(() => {
    const aspect = calculateAspectRatio();
    setCurrentCrop({
      ...(crop || defaultCrop),
      aspect,
    });
  }, []);

  function calculateAspectRatio() {
    return desiredDimensions?.width / desiredDimensions?.height || undefined;
  }

  const onCropChange = (crop: Crop, cropPercent: ReactCrop.PercentCrop) => {
    const newCurrentCrop = {
      ...crop,
      ...cropPercent,
    };

    setCurrentCrop(newCurrentCrop);
    props.onChange({
      src: src || "",
      crop: newCurrentCrop,
      file: imageChange || props.file || file,
    });
  };

  const onImageChange = (filelist: FileList | null) => {
    if (!filelist) {
      return;
    }

    new Compressor(filelist[0], {
      convertSize: 2000000,
      quality: 0.8,
      maxWidth: 1920,
      success(result: any) {
        setImageChange(result);
        const src = URL.createObjectURL(result);
        onChange(src, result);
      },
    });
  };

  const onChange = (newSrc?: string, file?: File) => {
    if (!src && !newSrc) {
      return;
    }

    if (!currentCrop) {
      return;
    }

    props.onChange({
      src: newSrc || src || "",
      crop: { x: 0, y: 0, width: 0, height: 0 },
      file: file || imageChange || props.file,
    });
  };

  if (isPending) {
    return (
      <div className="crop-image-loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (isFailure) {
    return <div>Error</div>;
  }

  if (isSuccess) {
    let shouldRender = 'placeholder';

    if (videoUrlHandler?.url) {
      shouldRender = 'videoUrl';
    } else if (src) {
      shouldRender = noCrop ? 'img' : 'croppableImg';
    }

    return (
      <>
        <div className="crop-container">
          {({
            videoUrl: <YoutubeEmbedVideo src={videoUrlHandler?.url as string} className="editor" />,
            img: <img src={src} />,
            croppableImg: (
              <CroppableImage
                className="crop-image"
                src={src}
                crop={{ ...crop, aspect: calculateAspectRatio() }}
                minWidth={desiredDimensions?.width}
                minHeight={desiredDimensions?.height}
                onChange={onCropChange}
                onComplete={() => onChange()}
              />
            ),
            placeholder: (
              <div className="crop-image-unavailable">
                <FontAwesomeIcon icon={faImage} />
              </div>
            ),
          } as any)[shouldRender]}

          <div className="actions">
            <FileUpload accept="image/*" onChange={onImageChange}>
              <FontAwesomeIcon icon={faPen} />
            </FileUpload>
            {allowEmpty ? (
              <FontAwesomeIcon icon={faTrashAlt} onClick={onRemove} />
            ) : null}
            {videoUrlHandler && <VideoUrl videoUrlHandler={videoUrlHandler} />}
          </div>
        </div>
        <div className="crop-stats">
          {src && !noCrop && !helpDimensions ? (
            <>
              <div>
                {t("Minimum Dimensions", "Minimum Dimensions")}:{" "}
                {renderDimensions(desiredDimensions)}
              </div>
            </>
          ) : null}
          {helpDimensions ? (
            <div>
              {t("Best Dimensions", "Best Dimensions")}:{" "}
              {renderDimensions(helpDimensions)}
            </div>
          ) : null}
        </div>
      </>
    );
  }

  return null;
};

import React from 'react';

import { PlaceholderImage } from './PlaceholderImage';

interface ImageWithPlaceholderProps {
    className?: string;
    src: string | undefined | null;
}
export const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = props => {
    if (!props.src) {
        return <PlaceholderImage className={props.className} />;
    }
    return <img className={props.className} src={props.src} />;
};

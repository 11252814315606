import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';

import { IconDefinition } from '../../common';
import { BaseComponent } from '../_BaseProps';

import styles from './IconButton.module.scss';

export const IconButton: BaseComponent<{
    icon: IconDefinition;
    onClick?: () => void;
}> = props => {
    return (
        <button className={cn(styles.iconButton, props.className)} onClick={props.onClick}>
            <FontAwesomeIcon icon={props.icon} />
            <span>{props.children}</span>
        </button>
    );
};

import classnames from 'classnames';
import React from 'react';

import './PrimaryButton.scss';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
export const PrimaryButton: React.FC<ButtonProps> = props => {
    const { className, ...restProps } = props;
    return (
        <button className={classnames('primary-button', className)} {...restProps}>
            {props.children}
        </button>
    );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useApi } from "../api/useApi";
import { noop } from "../external/common/helpers";
import { measurementsActions } from "../external/Measurement/measurements.state";
import { sizesActions } from "../external/Size/sizes.state";
import { sizechartsActions } from "../external/Sizechart/sizechart.state";
import { SizechartList } from "../external/Sizechart/SizechartList";
import { useAsync } from "../external/useAsync";

import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import registerAccessGtm from "../services/gtmRegister";

export const SizechartListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, fetchSizecharts, fetchSizes, fetchMeasurements } = useApi();
  const { t } = useTranslation();

  const fetchData = () =>
    Promise.all([
      fetchSizecharts(user.brandId),
      fetchSizes(user.brandId),
      fetchMeasurements(user.brandId),
    ]);

  const { isPending, isSuccess, isFailure, start } = useAsync(fetchData);
  useEffect(() => {
    start(user.brandId)
      .then(([sizecharts, sizes, measurements]) => {
        dispatch(sizechartsActions.fetched(sizecharts));
        dispatch(sizesActions.fetched(sizes));
        dispatch(measurementsActions.fetched(measurements));
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    registerAccessGtm()
  }, [])

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return (
      <PageError
        message={String(t("Error fetching data", "Error fetching data"))}
      />
    );
  }

  if (isSuccess) {
    return (
      <Page className="sizechart-management">
        <div className="page-title">
          {t("Size chart management", "Size chart management")}
        </div>

        <SizechartList />
      </Page>
    );
  }

  return null;
};

import React from 'react';

import './ErrorMessage.scss';

export const ErrorMessage: React.FC<{ className?: string; message: string }> = props => {
    return <div className={`error-message ${props.className}`}>{props.message}</div>;
};

export const ErrorText: React.FC<{ className?: string; message: string }> = props => {
    return <div className={`error-text ${props.className}`}>{props.message}</div>;
};

import cn from 'classnames';
import React from 'react';

import { BaseComponent } from '../_BaseProps';

import styles from './HintText.module.scss';

export const HintText: BaseComponent = props => (
    <div className={cn(styles.hintText, props.className)}>{props.children}</div>
);

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../../api/useApi";
import { BrandFooter, BrandProfile } from "../../domain/BrandProfile";
import { PageLoadingSpinner } from "../../pages/common/PageLoadingSpinner";
import { FormAutoSave } from "../common/FormAutoSave";
import { noop } from "../common/helpers";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { TextToggledit } from "../common/TextToggledit";
import getCroppedImg from "../getCroppedImg";
import { useAsync } from "../useAsync";

import "./Footer.scss";

export const Footer: React.FC = (props) => {
  const [showEditLink, setShowEditLink] = useState(false);
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const { fetchBrandFooter, updateBrandFooter } = useApi();
  const updateBrandFooterAsync = useAsync(
    updateBrandFooter(values.callToAction?.id || -1)
  );

  const [imgSrc, setImgSrc] = useState(values.callToAction?.imageOriginal);

  const onSave = (footer: BrandFooter) => {
    updateBrandFooterAsync
      .start({
        title: footer.title,
        text: footer.text,
        image: footer.image,
      })
      .catch(noop);
  };

  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="edit-link" onClick={() => setShowEditLink(!showEditLink)}>
        <FontAwesomeIcon icon={faPencilAlt} />
        <div>{t("Edit link", "Edit link")}</div>
      </div>
      <Imagedit
        allowEmpty={false}
        title={t("Edit footer image", "Edit footer image")}
        desiredDimensions={{ width: 1920, height: 305 }}
        className="footer-image-imagedit"
        zIndex={1}
        src={{
          id: "1",
          src: values.callToAction?.image || "",
          originalImage: values.callToAction?.imageOriginal || "",
          imageCropping: values.callToAction?.imageCropping || "",
          crop: {
            unit: "%",
            aspect: 1920 / 305,
          },
        }}
        onSave={(images) => {
          let img = new Image();
          img.onload = async () => {
            const croppedImg: any = await getCroppedImg(img, images[0].crop);
            var objectURL = URL.createObjectURL(croppedImg);
            setImgSrc(objectURL);
          };
          img.crossOrigin = "anonymous";
          if (!images[0].file) {
            img.src = images[0].src + `?x=123`;
          } else {
            img.src = images[0].src;
          }

          setFieldValue("callToAction.image", images[0].src);
          setFieldValue("callToAction.nova_imagem", images[0].file);
          setFieldValue("callToAction.nova_imagem_crop", images[0].crop);
        }}
        overlay={() => (
          <div
            className="imagedit-overlay footer-image-overlay"
            style={{
              zIndex: 2,
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </div>
        )}
        render={() => (
          <div
            className={`footer-image ${
              !values.callToAction?.title && !values.callToAction?.text
                ? "footer-image--no-overlay"
                : ""
            }`}
            style={{
              backgroundImage: `url(${imgSrc})`,
            }}
          >
            <div className="content title">
              <TextToggledit
                text={values.callToAction?.title || ""}
                name="title"
                onChange={(newValue) =>
                  setFieldValue("callToAction.title", newValue)
                }
              />
            </div>
            <div className="content text">
              <TextToggledit
                text={values.callToAction?.text || ""}
                name="text"
                onChange={(newValue) =>
                  setFieldValue("callToAction.text", newValue)
                }
              />
            </div>
            {showEditLink && (
              <div className="content text">
                <TextToggledit
                  text={values.callToAction?.link || ""}
                  placeholder={t("Insert a link", "Insert a link")}
                  name="link"
                  maxLength={255}
                  onChange={(newValue) =>
                    setFieldValue("callToAction.link", newValue)
                  }
                />
              </div>
            )}
          </div>
        )}
      ></Imagedit>
      {/* <FormAutoSave /> */}
    </div>
  );
  return null;
};

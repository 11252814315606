import axios, {AxiosInstance} from 'axios';
import Swal from 'sweetalert2';

export type HttpClient = AxiosInstance;

export const createClient = (): HttpClient => {
    axios.interceptors.request.use(
        (request) => {
            return request;
        },
        (error) => {
            if (error.response) {
                if (401 === error.response.status) {
                    window.localStorage.clear();
                    return (window.location.href = '/');
                }

                if (422 === error.response.status) {
                    Swal.fire('Erro!', 'Erro de validação', 'error');
                }
            }
            return Promise.reject(error);
        }
    );
    return axios;
};

export const setClientToken =
    (client: HttpClient) =>
    (token: string): void => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tokenURL = urlParams.get('token');
        if (tokenURL) {
            client.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('temp_token')}`;
        } else {
            client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    };

export const unsetClientToken = (client: HttpClient) => (): void => {
    delete client.defaults.headers.common['Authorization'];
};

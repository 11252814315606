import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { LoadingSpinner } from '../components/LoadingSpinner';

import { BaseComponent } from './_BaseProps';
import styles from './BottomBar.module.scss';
import { PrimaryButton } from './buttons/PrimaryButton';

export const AsyncButton: React.FC<{
    status: { isPending: boolean; isFailure: boolean; isSuccess: boolean };
    label: string;
    successMsg: string;
    failureMsg: string;
    onClick: () => void;
}> = props => {
    const [isMsgShown, setMsgShown] = useState(false);

    useEffect(() => {
        setMsgShown(true);
        const timerId = setTimeout(() => {
            if (!props.status.isFailure) {
                setMsgShown(false);
            }
        }, 5000);
        return () => clearTimeout(timerId);
    }, [props.status.isPending, props.status.isFailure, props.status.isSuccess]);

    const message = props.status.isFailure ? (
        <span className={styles.failureMsg}>{props.failureMsg}</span>
    ) : props.status.isSuccess ? (
        <span className={styles.successMsg}>{props.successMsg}</span>
    ) : null;

    return (
        <div>
            {isMsgShown ? message : null}
            <PrimaryButton
                className={styles.asyncButton}
                disabled={props.status.isPending}
                onClick={props.onClick}
            >
                {props.status.isPending ? <LoadingSpinner /> : props.label}
            </PrimaryButton>
        </div>
    );
};

export const BottomBar: BaseComponent = props => (
    <div className={cn(styles.bottomBar, props.className)}>{props.children}</div>
);

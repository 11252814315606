// @ts-nocheck
import Axios from 'axios';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useApi } from './api/useApi';
import { Header } from './external/Header/Header';
import ptBRTranslations from './languages/pt_BR/translation.json';
import { BrandEdit } from './pages/BrandEdit.page';
import { CollectionCreatePage } from './pages/CollectionCreate.page';
import { CollectionEditPage } from './pages/CollectionEdit.page';
import { CollectionListPage } from './pages/CollectionList.page';
import { PageLoadingSpinner } from './pages/common/PageLoadingSpinner';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { LoginPage } from './pages/Login.page';
import { MeasurementListPage } from './pages/MeasurementList.page';
import { SizechartListPage } from './pages/SizechartList.page';
import { useRoutes } from './useRoutes';

const xAccountLanguageCode = localStorage.getItem('X-Account-Language-Code');

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            pt_BR: {
                translation: ptBRTranslations,
            },
        },
        lng: xAccountLanguageCode || 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

const App: React.FC = () => {
    const [loadingXAccount, setLoadingXAccount] = useState(true);
    const {t} = useTranslation();
    let urlWithoutSearchParams = '';
    const {authenticate} = useApi();

    async function redirectAfterTokenLogin() {
        setTimeout(() => {
            window.location.replace(urlWithoutSearchParams);
        }, 1000);
    }

    async function loginByToken() {
        urlWithoutSearchParams = window.location.origin + window.location.pathname;

        try {
            await Axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('temp_token')}`,
                },
            }).then((res) => {
                authenticate({token: localStorage.getItem('temp_token'), brandId: res.data.id});
            });

            await localStorage.removeItem('temp_token');

            setTimeout(() => {
                redirectAfterTokenLogin();
            }, 0);
            return;
        } catch (ex) {
            console.log(ex);
        }

        try {
            await Axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/refresh`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('temp_token')}`,
                },
            }).then((refresh) => {
                authenticate({token: localStorage.getItem('temp_token'), brandId: refresh.data.id});
            });

            await localStorage.removeItem('temp_token');

            setTimeout(() => {
                redirectAfterTokenLogin();
            }, 0);
        } catch (ex) {
            console.log(ex);
        }
    }

    useEffect(() => {
        async function getXAccountHash() {
            if (localStorage.getItem('X-Account-Host') !== null && localStorage.getItem('X-Account-Host') !== window.location.host) {
                localStorage.clear();
                window.location.href = '/';
            }

            var xAccountHash = localStorage.getItem('X-Account-Hash');
            var xAccountLanguageCode = localStorage.getItem('X-Account-Language-Code');
            var xAccountLogo = localStorage.getItem('X-Account-Logo');
            var xAccountName = localStorage.getItem('X-Account-Name');
            var gtm_id_admin_seller = localStorage.getItem('gtm_id_admin_seller');

            if (!xAccountHash || !gtm_id_admin_seller) {
                localStorage.setItem('X-Account-Host', window.location.host);

                const response = await Axios.get(`${process.env.REACT_APP_API_URL}/v1/x-account`);

                if (response.status !== 200 || !response.data.data || !response.data.data.hash) {
                    Swal.fire({
                        title: t('Error!', 'Error!'),
                        html: `${t('An error ocurred', 'An error ocurred')} . ${t(
                            'Please try again later.',
                            'Please try again later.'
                        )}`,
                        confirmButtonColor: '#000000',
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 30000);

                    return;
                }

                xAccountHash = response.data.data.hash;
                xAccountLanguageCode = response.data.data.language_code;
                xAccountLogo = response.data.data.logo;
                xAccountName = response.data.data.name;
                gtm_id_admin_seller = response.data.data.gtm_id_admin_seller
            }


            localStorage.setItem('X-Account-Hash', String(xAccountHash));
            localStorage.setItem('X-Account-Language-Code', String(xAccountLanguageCode));
            localStorage.setItem('X-Account-Logo', String(xAccountLogo));
            localStorage.setItem('X-Account-Name', String(xAccountName));
            localStorage.setItem('gtm_id_admin_seller', String(gtm_id_admin_seller))

            i18n.changeLanguage(xAccountLanguageCode || 'en');

            setTimeout(() => setLoadingXAccount(false), 1000);

            Axios.defaults.headers.common['X-Account-Hash'] = xAccountHash;

            Axios.interceptors.request.use(
                (request) => {
                    request.headers['X-Account-Hash'] = xAccountHash;
                    return request;
                },
                (error) => {
                    if (error.response) {
                        if (401 === error.response.status) {
                            window.localStorage.clear();
                            return (window.location.href = '/');
                        }

                        if (422 === error.response.status) {
                            let errors = '';
                            const keysErrors = Object.keys(error.response.data);
                            if (keysErrors.length > 0) {
                                for (let index = 0; index < keysErrors.length; index++) {
                                    errors += error.response.data[keysErrors[index]] + '\n';
                                }
                            }
                            Swal.fire(
                                t('Error!', 'Error!'),
                                errors ||
                                    t(
                                        'An error ocurred, please review the inserted data.',
                                        'An error ocurred, please review the inserted data.'
                                    ),
                                'error'
                            );
                        }
                    }

                    return Promise.reject(error);
                }
            );
        }

        async function initialize() {
            await getXAccountHash();
    
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('token');
    
            if (token) {
                localStorage.setItem('temp_token', token);
                loginByToken();
            }
        }
    
        initialize();
    }, []);

    const routes = useRoutes();
    const api = useApi();

    if (loadingXAccount) {
        return <PageLoadingSpinner />;
    }

    if (!api.isLoggedIn) {
        return !loadingXAccount ? <LoginPage /> : <PageLoadingSpinner />;
    }

    return (
        <Router>
            <Header />

            <Switch>
                {!loadingXAccount ? (
                    <>
                        <Route path='/' component={() => <Redirect to={{pathname: '/brand-page'}} />} exact={true} />
                        <Route path={routes.brandPage.url}>
                            <BrandEdit />
                        </Route>
                        <Route path={routes.dashboard.url}>
                            <Dashboard />
                        </Route>
                        {/* <Route path={routes.products.createUrl}>
                            <ProductCreatePage />
                        </Route>
                        <Route path={routes.products.byIdUrl}>
                            <ProductEditPage />
                        </Route> */}
                        {/* <Route path={routes.products.listUrl}>
                            <ProductListPage />
                        </Route> */}
                        <Route path={routes.measurements.listUrl}>
                            <MeasurementListPage />
                        </Route>
                        {/* <Route path={routes.sizes.listUrl}>
                            <SizeListPage />
                        </Route> */}
                        {/* <Route path={routes.colorsAndTextures.listUrl}>
                            <ColorAndTextureListPage />
                        </Route> */}
                        <Route path={routes.sizecharts.listUrl}>
                            <SizechartListPage />
                        </Route>
                        <Route path={routes.collections.createUrl}>
                            <CollectionCreatePage />
                        </Route>
                        <Route path={routes.collections.byIdUrl}>
                            <CollectionEditPage />
                        </Route>
                        <Route path={routes.collections.listUrl}>
                            <CollectionListPage />
                        </Route>
                    </>
                ) : (
                    <PageLoadingSpinner />
                )}
            </Switch>
        </Router>
    );
};

export default App;

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { ProductSummary } from '../../../domain/ProductList';
import { RootState } from '../../../RootState';

const productListAdapter = createEntityAdapter<ProductSummary>();
export const productListSlice = createSlice({
    name: 'products',
    initialState: productListAdapter.getInitialState(),
    reducers: {
        fetched: productListAdapter.setAll,
    },
});
export const productListActions = productListSlice.actions;

/** Selectors */
const productListSelectors = productListAdapter.getSelectors((state: RootState) => state.products);

export const selectProductList = productListSelectors.selectAll;
export const selectProductListCollection = productListSelectors.selectEntities;

import React from 'react';
import { useTranslation } from 'react-i18next';

import './PageLoadingSpinner.scss';

export const PageLoadingSpinner: React.FC = () => {
    const { t } = useTranslation(); 

    return (
        <div className='page-loading-spinner-container'>
            <div className='page-loading-spinner'>
                <img className='spinner' src='https://blancfashion.com/images/loading.png' />
                <div className='text'>{ t('Loading', 'Loading') }</div>
            </div>
        </div>
    );
};

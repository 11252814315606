import { MenuItem } from "@material-ui/core";
import cn from "classnames";
import { Field, Formik, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { HintTitle } from "../../common/typography/HintTitle";
import { Collection } from "../../domain/Collection";
import { FormAutoSave } from "../../external/common/FormAutoSave";
import { Imagedit } from "../../external/common/Imageedit/Imagedit";
import { ImageWithPlaceholder } from "../../external/common/ImageWithPlaceholder";
import { InfoTooltip } from "../../external/common/InfoTooltip";
import { RichTextEditor } from "../../external/common/RichTextEditor";
import { FormSelect } from "../../external/common/Select";
import { SwitcherRaw } from "../../external/common/Switcher";
import {
  FormTextInput,
  FormDateInput,
  MultilineFormTextInput,
} from "../../external/common/TextInput";

import styles from "./CollectionFields.module.scss";
import i18n from "i18next";

const CollectionFieldsSchema = Yup.object().shape({
  name: Yup.string().required(),
  productionWindowStart: Yup.date().transform((value, original) =>
    original ? value : new Date(0)
  ),
  productionWindowEnd: Yup.date()
    .transform((value, original) => (original ? value : new Date())),
  orderWindowStart: Yup.date().transform((value, original) =>
    original ? value : new Date(0)
  ),
  orderWindowEnd: Yup.date()
    .transform((value, original) => (original ? value : new Date()))
});

// t('name is a required field', 'name is a required field')
// t('name.required', 'nome is a required')

export const CollectionFields: React.FC<{
  fields: Collection;
  onChange: (fields: Collection) => void;
}> = (props) => {
  const { values, setFieldValue, errors } = useFormikContext<Collection>();
  const { t } = useTranslation();
  return (
    <div className={styles.fields}>
      <div>
        <div className={styles.fieldGroup}>
          <Field
            name="name"
            as={FormTextInput}
            label={t("Name*", "Name*")}
            className="field--required"
          />
        </div>

        <div className={styles.fieldGroup}>
          <Field
            name="isActive"
            as={SwitcherRaw}
            label={t("Is active", "Is active")}
          />
        </div>

        {/* <div className={styles.fieldGroup}>
          <Field
            name="markup"
            as={FormTextInput}
            type="number"
            inputProps={{ step: "1" }}
            label={t("Markup", "Markup")}
          />
        </div> */}

        <div className={styles.fieldGroup}>
          <div className={cn(styles.fieldGroup, styles.priceTooltips)}>
            {/* <div>
              <Field
                name="wholesalePriceTip"
                as={FormTextInput}
                label={t("Wholesale Price Tooltip", "Wholesale Price Tooltip")}
              />
              <InfoTooltip
                className={styles.tooltip}
                text={t("Incoterm", "Incoterm")}
              />
            </div> */}

            {/* <div>
              <Field
                name="retailerPriceTip"
                as={FormTextInput}
                label={t("Retailer Price Tooltip", "Retailer Price Tooltip")}
              />
              <InfoTooltip
                className="tooltip"
                text={t("Incoterm", "Incoterm")}
              />
            </div> */}
          </div>
        </div>

        <div className={styles.fieldGroup}>
          <Field
            name="typeButton"
            as={FormSelect}
            label={t("Button type", "Button type")}
          >
            <MenuItem value={"o"}>{t("Order now", "Order now")}</MenuItem>
            <MenuItem value={"b"}>{t("Buy now", "Buy now")}</MenuItem>
          </Field>
        </div>

        <div className={styles.fieldGroup}>
          <Field
            name="collectionType"
            as={FormSelect}
            label={t("Type", "Type")}
          >
            <MenuItem value={"future_delivery"}>
              {t("Future delivery", "Future delivery")}
            </MenuItem>
            <MenuItem value={"in_stock"}>{t("In Stock", "In Stock")}</MenuItem>
          </Field>
        </div>

        <div className={styles.fieldGroup}>
          <Field
            name="deliveryMsg"
            as={FormTextInput}
            label={t("Delivery message", "Delivery message")}
          />
        </div>

        {/* <div className={styles.fieldGroup}>
          <Field
            name="currency"
            as={FormSelect}
            label={t("Currency", "Currency")}
          >
            <MenuItem selected value="USD">
              {t("US Dollar", "US Dollar")}
            </MenuItem>
            <MenuItem value="EUR">{t("EUR", "EUR")}</MenuItem>
            <MenuItem value="GBP">{t("GBP", "GBP")}</MenuItem>
          </Field>
        </div> */}

        <div className={cn(styles.fieldGroup, styles.orderWindow)}>
          <Field
            name="orderWindowStart"
            as={FormDateInput}
            label={t("Order window start", "Order window start")}
          />
          <Field
            name="orderWindowEnd"
            as={FormDateInput}
            label={t("Order window end", "Order window end")}
          />
        </div>

        <div className={cn(styles.fieldGroup, styles.productionWindow)}>
          <Field
            name="productionWindowStart"
            as={FormDateInput}
            label={t("Delivery window start", "Delivery window start")}
          />
          <Field
            name="productionWindowEnd"
            as={FormDateInput}
            label={t("Delivery window end", "Delivery window end")}
          />
        </div>

        <div className={styles.fieldGroup}>
          <div className={cn(styles.fieldGroup, styles.subtitle)}>
            {t("Press release", "Press release")}
          </div>
          <Field name="pressRelease" as={RichTextEditor} />
        </div>

        <div className={styles.fieldGroup}>
          <div className={cn(styles.fieldGroup, styles.subtitle, styles.withMoreMargin)}>
            {t("Shopify integration", "Shopify integration")}
          </div>

          <Field
            name="isShopifyActive"
            as={SwitcherRaw}
            label={t("Is active", "Is active")}
          />
        </div>
      </div>

      <div className={styles.listImage}>
        <HintTitle>{t("Collection image", "Collection image")}</HintTitle>
        <Imagedit
          allowEmpty={false}
          noCrop
          desiredDimensions={{ width: 0, height: 0 }}
          title={t("Edit collection image", "Edit collection image")}
          src={[
            {
              id: values.id,
              src: values.listImage,
              originalImage: values.listImage,
              crop: {},
            },
          ]}
          render={() => <img src={values.listImage} />}
          onSave={(images) => {
            setFieldValue("listImage", images[0].src);
            setFieldValue("nova_imagem", images[0].file);
          }}
        />
      </div>

      <FormAutoSave debounceMs={100} />
    </div>
  );
};

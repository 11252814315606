// @ts-nocheck
import React from 'react';
import "./DynamicCard.scss";

function DynamicCard({title, number, averagePercentage, averageNumber, balance, display, onClick, active, classe, id}:any) {

    return (
        <>
            <div className={classe} onClick={onClick} active={active} id={id}>
                <p className="title">
                    {title}
                </p>
                <div className={display}>
                    <div className="number">
                        {
                            number === null ? (
                                <p>
                                    N/A
                                </p>
                            ) : (
                                <p>{number}</p>
                            )
                        }
                    </div>
                    <div className="average">
                        {
                            balance === false ? (
                                <span className='arrow__down'>
                                    ↓
                                </span>
                            ) : (
                                <span className='arrow__up'>
                                    ↑
                                </span>
                            )
                        }
                        <p className="porcentage-average">
                            {
                                averagePercentage === null ?
                                (
                                    <>
                                        0%
                                    </>
                                ):(
                                    <>
                                        {
                                            averagePercentage === "NaN" ? (
                                                <>
                                                    0%
                                                </>
                                            ):(
                                                <>
                                                    {averagePercentage}%
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </p>
                        <p className="porcentage-number">
                            ({averageNumber})
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DynamicCard;

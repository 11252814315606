import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { useApi } from "../api/useApi";
import { AsyncButton, BottomBar } from "../common/BottomBar";
import { Page } from "../common/Page";
import { CollectionFields } from "../components/Collection/CollectionFields";
import { CollectionProducts } from "../components/Collection/CollectionProducts";
import { Collection, CollectionProduct } from "../domain/Collection";
import { noop } from "../external/common/helpers";
import { useAsync } from "../external/useAsync";
import { isNil } from "../helpers";
import * as Yup from "yup";

import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import registerAccessGtm from "../services/gtmRegister";

export const CollectionFieldsSchema = Yup.object().shape({
  name: Yup.string().required(),
  isShopifyActive: Yup.boolean(),
  productionWindowStart: Yup.date().transform((value, original) =>
    original ? value : new Date(0)
  ),
  productionWindowEnd: Yup.date()
    .transform((value, original) => (original ? value : new Date())),
  orderWindowStart: Yup.date().transform((value, original) =>
    original ? value : new Date(0)
  ),
  orderWindowEnd: Yup.date()
    .transform((value, original) => (original ? value : new Date())),
});

const getProductsDiff = (
  oldProducts: CollectionProduct[],
  products: CollectionProduct[]
): Record<CollectionProduct["id"], CollectionProduct["collectionOrder"]> => {
  const diff = new Map();
  products.forEach((product, index) => {
    const oldIndex = oldProducts.findIndex((p) => p.id === product.id);
    if (oldIndex === -1) {
      diff.set(product.id, index);
    }
    if (oldIndex !== index) {
      diff.set(product.id, index);
    }
  });
  oldProducts.forEach((oldProduct) => {
    if (products.findIndex((p) => p.id === oldProduct.id) === -1) {
      diff.set(oldProduct.id, null);
    }
  });
  return Object.fromEntries(diff);
};

export const CollectionEditPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const collectionId = +params.id;
  const { t } = useTranslation();

  const {
    user,
    fetchCollection,
    fetchCollectionProducts,
    updateCollection,
    updateCollectionProducts,
  } = useApi();
  const fetchCollectionAsync = useAsync(fetchCollection);
  const fetchCollectionProductsAsync = useAsync(
    fetchCollectionProducts(collectionId)
  );
  const updateCollectionAsync = useAsync(
    updateCollection(collectionId, user.brandId)
  );
  const updateCollectionProductsAsync = useAsync(
    updateCollectionProducts(collectionId)
  );

  const [fields, setFields] = useState<Collection | undefined>();

  useEffect(() => {
    registerAccessGtm()
  }, [])

  useEffect(() => {
    fetchCollectionAsync.start(collectionId).catch(noop);
  }, [collectionId]);

  const updateCollectionProcess = async () => {
    await Promise.all(
      [!isNil(fields) ? updateCollectionAsync.start(fields) : undefined].filter(
        (p) => !isNil(p)
      )
    );
  };
  const updateCollectionProcessAsync = useAsync(updateCollectionProcess);

  const onUpdate = () => updateCollectionProcessAsync.start(undefined);

  if (
    fetchCollectionAsync.isFailure ||
    fetchCollectionProductsAsync.isFailure
  ) {
    return <PageError message={t("Error fetching data", "Error fetching data")} />;
  }

  return (
    <Page
      title={t("Edit collection", "Edit collection")}
      after={
        <BottomBar>
          <AsyncButton
            status={updateCollectionProcessAsync}
            label={t("Save collection", "Save collection")}
            successMsg={t(
              "Collection successfully saved",
              "Collection successfully saved"
            )}
            failureMsg={t(
              "Collection could not be saved due to en error",
              "Collection could not be saved due to en error"
            )}
            onClick={onUpdate}
          />
        </BottomBar>
      }
    >
      {fetchCollectionAsync.isPending ? <PageLoadingSpinner /> : null}
      {!fetchCollectionAsync.isPending && fetchCollectionAsync.results ? (
        <Formik
          initialValues={fetchCollectionAsync.results}
          validationSchema={CollectionFieldsSchema}
          onSubmit={setFields}
        >
          {({ values, setFieldValue }) => (
            <>
              <CollectionFields fields={values} onChange={setFields} />
              <CollectionProducts
                products={values.products}
                onChange={(products) => {
                  setFieldValue("products", products);
                }}
              />
            </>
          )}
        </Formik>
      ) : null}
    </Page>
  );
};

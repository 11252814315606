interface Crop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface InfoblockImage extends Image {
    imageResp: string | null;
    featuredImage: string | null;
    featuredImageResp: string | null;
    landscapeImage: string | null;
    portraitImage: string | null;
    originalImage?: string | null;
    portraitImageCropping?: string | null;
    imageCropping?: string;
    videoUrl?: string;
    crop?: Crop;
    file?: File;
}

export enum SiteArea {
    TheBrand = 1,
    History = 2,
}

export interface Image {
    id?: number;
    url?: string;
    imageCropping?: string;
    imageOriginal?: string;
    remover?: boolean;
    nova_imagem?: File;
    nova_imagem_crop?: any;
}

export interface InfoBlock {
    id: number;
    images: InfoblockImage[];
    title: string;
    summary: string;
    text: string;
    buttonLabel: string;
    imagePosition: 'left' | 'right';
    imageOrientation: 'portrait' | 'landscape';
    order: number;
    link: string;
    seller: number;
    featured: boolean;
    siteArea: SiteArea;
}

export interface BrandProfileHistoryDetails {
    id: number;
    title: string;
    text: string;
    order: number;
    seller: number;
}


export interface BrandProfileHistory {
    lookbooks: BrandStyle;
    // lookbookTemplate: number;
    // lookbookDescription: string;
    // lookbookImages: {
    //     id: number;
    //     title: string;
    //     order: number;
    //     link: string;
    //     buttonLabel: string;
    //     image285: string;
    //     image580600: string;
    //     imageResp: string;
    // }[];
    historyImage: string;
    historyImageCropping?: string;
    historyImageOriginal?: string;
    nova_imagem?: File;
    nova_imagem_crop?: any;
    details: BrandProfileHistoryDetails[];
    infoBlock: InfoBlock[];
    siteUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    twitterUrl: string;
}

export interface BrandStyleImages {
    id: number;
    remover?: boolean;
    novaImagem?: File;
    imageSrc?: string;
    originalImage?: string;
    novaImagemCrop?: string;
    imageCropping285?: string;
    imageCropping580?: string;
    cropOriginal?: any;
    src?: any;
    index?: number;
    crop?: any;
    title: string;
    link: string;
    button_label?: string;
    videoUrl?: string;
}

export interface BrandStyle {
    description: string;
    template: number;
    images: BrandStyleImages[];
    imagePreviewIndex?: number;
}

export interface TheBrand {
    brandFrom: string;
    yearEstablished: string;
    multibrandRetailers: string;
    multibrandRetailersFull: string;
    featuredInfoBlock?: InfoBlock;
    infoBlock: InfoBlock[];
    termsConditions: string;
    brandStyle: BrandStyle;
}

export interface BrandFooter {
    id: number;
    image: string;
    title: string;
    text: string;
}

export interface CallToAction {
    id: number;
    buttonLabel: string;
    image: string;
    imageOriginal?: string;
    imageCropping?: string;
    imageMobile: string;
    imageCroppingMobile: string;
    link: string;
    seller: number;
    text: string;
    title: string;
    nova_imagem?: File;
    nova_imagem_crop?: any;
}

export interface BrandProfile {
    id: number;
    name: string;
    slug: string;
    draft_status: any;

    coverImage: Image;
    coverImageThumbnail: Image;
    logoBrandPage: Image;

    theBrand: TheBrand;
    history: BrandProfileHistory;
    callToAction?: CallToAction;
}

import cn from 'classnames';
import React from 'react';

import { BaseComponent } from '../_BaseProps';

import styles from './HintTitle.module.scss';

export const HintTitle: BaseComponent = props => (
    <div className={cn(styles.hintTitle, props.className)}>{props.children}</div>
);

import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

interface TextInputProps {
  className?: string;
  id?: string;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  multiline?: boolean;
  name?: string;
  value: string;
  onChange: (value: string) => void;
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <TextField
      className={props.className}
      required={props.required}
      multiline={props.multiline}
      fullWidth={props.fullWidth}
      id={props.id}
      label={props.label}
      value={props.value ?? ""}
      name={props.name}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
    />
  );
};

export const TextInputRaw: React.FC<
  TextInputProps & {
    onChange: (
      value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onBlur: (
      value: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
  }
> = (props) => {
  return (
    <TextField
      className={props.className}
      required={props.required}
      multiline={props.multiline}
      fullWidth={props.fullWidth}
      id={props.id}
      label={props.label}
      value={props.value ?? ""}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
  );
};

export const FormTextInput: React.FC<TextFieldProps & { name: string }> = (
  props
) => {
  const [, meta] = useField(props.name);
  const { t } = useTranslation();

  return (
    <TextField
      {...props}
      value={props.value ?? undefined}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error && t(String(meta.error))}
    />
  );
};

export const MultilineFormTextInput: React.FC<
  TextFieldProps & { name: string }
> = (props) => {
  const [, meta] = useField(props.name);

  return (
    <TextField
      {...props}
      multiline={true}
      value={props.value ?? undefined}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormDateInput: React.FC<TextFieldProps & { name: string }> = (
  props
) => (
  <FormTextInput
    {...props}
    type="date"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export const FormNumberInput: React.FC<TextFieldProps & { name: string }> = (
  props
) => (
  <FormTextInput
    {...props}
    type="number"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

import { API_URL, isMockApi } from "../config";
import { BrandProfile } from "../domain/BrandProfile";
import { ProductVariationColor } from "../domain/Product";
import { randomInt, NewEntity } from "../helpers";

import { Colors } from "./__fixtures__/colors";
import { HttpClient } from "./http-client";

const COLORS_API_URL = `${API_URL}/v1/admin-seller/colors`;

export const fetchColors =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]): Promise<ProductVariationColor[]> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Fetch colors for BrandID", brandId);
          setTimeout(() => {
            resolve(Colors);
          }, 1000);
        })
      : httpClient
          .get(`${COLORS_API_URL}`)
          .then((res) => res.data.data)
          .then((colors) =>
            colors.map((color: any) => ({
              id: color.id,
              name: color.name,
              color: color.color,
              extraColor: color.extra_color,
            }))
          );

export const createColor =
  (httpClient: HttpClient) =>
  (brandId: BrandProfile["id"]) =>
  (
    params: NewEntity<ProductVariationColor>
  ): Promise<{ id: ProductVariationColor["id"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log(
            "[API] Create color for BrandID",
            brandId,
            "and data",
            params
          );
          setTimeout(() => {
            resolve({ id: randomInt(50, 99) });
          }, 1000);
        })
      : httpClient
          // TODO: check SQL error on backend
          .post(`${COLORS_API_URL}`, {
            seller_id: brandId,
            name: params.name,
            color: params.color,
            extra_color: params.extraColor,
          })
          .then((res) => res.data);

export const updateColor =
  (httpClient: HttpClient) =>
  (id: ProductVariationColor["id"]) =>
  (
    params: NewEntity<ProductVariationColor>
  ): Promise<{ id: ProductVariationColor["id"] }> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Update color for ID`", id, "and data", params);
          setTimeout(() => {
            resolve({ id: randomInt(50, 99) });
          }, 1000);
        })
      : httpClient.put(`${COLORS_API_URL}/${id}`, {
          name: params.name,
          color: params.color,
          extra_color: params.extraColor,
        });

export const deleteColor =
  (httpClient: HttpClient) =>
  (id: ProductVariationColor["id"]): Promise<void> =>
    isMockApi
      ? new Promise((resolve, reject) => {
          console.log("[API] Delete color for BrandID", id);
          setTimeout(() => {
            resolve();
          }, 1000);
        })
      : httpClient.delete(`${COLORS_API_URL}/${id}`);

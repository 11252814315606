import { createSlice, createEntityAdapter, ThunkAction, Action } from '@reduxjs/toolkit';

// import { ProductDetail } from '../../../domain/Product';
import { RootState } from '../../../RootState';

// interface NewDetailArgs {
//     text?: ProductDetail['text'];
// }

/** Reducer */
export interface DetailState {
    isNew?: boolean;
}

const makeNewDetail = (): DetailState => ({
    isNew: true,
});

const detailsAdapter = createEntityAdapter<DetailState>();
export const detailsSlice = createSlice({
    name: 'details',
    initialState: detailsAdapter.getInitialState(),
    reducers: {
        detailsFetched: detailsAdapter.setAll,
        detailAdded: detailsAdapter.addOne,
        detailChanged: detailsAdapter.updateOne,
        detailRemoved: detailsAdapter.removeOne,
    },
});
export const detailsActions: any = {
    ...detailsSlice.actions,
    addDetail: (): ThunkAction<void, RootState, unknown, Action<void>> => {
        const detail = makeNewDetail();
        return dispatch => {
            dispatch(detailsActions.detailAdded(detail));
        };
    },
};

/** Selectors */
const detailsSelectors = detailsAdapter.getSelectors((state: RootState) => state.details);

export const selectDetails = detailsSelectors.selectAll;
export const selectDetailsCollection = detailsSelectors.selectEntities;
export const selectDetail = detailsSelectors.selectById;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton } from "../common/IconButton";
import { SmartModal } from "../common/SmartModal";

import { AddNewMeasurement } from "./AddMeasurement";
import { MeasurementCard } from "./MeasurementCard";
import { selectMeasurements, measurementsActions } from "./measurements.state";

import "./MeasurementList.scss";
import { useTranslation } from "react-i18next";

export const MeasurementList: React.FC = () => {
  const measurements = useSelector(selectMeasurements);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className="measurement-list">
        {measurements.map((measurement) => (
          <MeasurementCard key={measurement.id} id={measurement.id} />
        ))}
      </div>
      <SmartModal
        body={({ closeModal }) => (
          <AddNewMeasurement
            onSave={(args) => {
              dispatch(measurementsActions.added(args));
              closeModal();
            }}
          />
        )}
      >
        <IconButton icon={faPlus}>
          {t("Add new measurement", "Add new measurement")}
        </IconButton>
      </SmartModal>
    </>
  );
};

import cn from 'classnames';
import React from 'react';

import { BaseComponent, BaseElement } from './_BaseProps';
import styles from './Page.module.scss';

export const Page: BaseComponent<{
    title: string;
    before?: BaseElement;
    after?: BaseElement;
}> = props => (
    <div className={cn(styles.page, props.className)}>
        {props.before}
        <div className={styles.pageContent}>
            <div className={styles.pageTitle}>{props.title}</div>
            {props.children}
        </div>
        {props.after}
    </div>
);

import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { Category } from '../../../domain/Category';
import { Collection, CollectionProduct } from '../../../domain/Collection';
import { Product, Size,  ProductVariation } from '../../../domain/Product';
import { Segment } from '../../../domain/Segment';
import { Sizechart } from '../../../domain/Sizechart';
import { RootState } from '../../../RootState';
import { collectionsActions, selectCollections } from '../../Collection/collections.state';
import { moveItem } from '../../common/OrderableGrid/moveItem';
import { selectSizes } from '../../Size/sizes.state';

import { selectCategoriesCollection } from './categories.state';
import { detailsActions, DetailState, selectDetailsCollection } from './details.state';
import { selectSegmentsCollection } from './segments.state';
import { selectVariationsCollection, VariationState, variationsActions } from './variations.state';

const makeNewProduct = (): ProductState => ({
    id: -1,
    sellerId: -1,
    details: [],
    variations: [],
    sizes: [],
    collection: undefined,
    sizechartId: null,
    images: [],
    linesheet: [],
    recommendedProducts: [],
    wholesalePrice: {
        currency: 'USD',
        amount: '0',
    },
    retailerPrice: {
        currency: 'USD',
        amount: '0',
    },
    orderDeliveryDays: 0,
    unitWeight: 0,
    name: 'Name',
    slug: '',
    internalName: '',
    referenceCode: '',
    description: 'Description',
    categories: [],
    segments: [],
    tags: [],

    isActive: false,

    orderMinimumQuantity: 0,
    orderMaximumQuantity: 0,
    sizechart_id: 0
});

/** Reducer */
interface ProductDetailState{
    isNew?: boolean;
}
interface ProductVariationState extends ProductVariation {
    isNew?: boolean;
}
interface ProductState extends Omit<Product, 'details' | 'sizes' | 'variations'> {
    details: string[];
    variations: ProductVariationState['id'][];
    sizes: Size['id'][];
}

type ProductSliceState = ProductState;

export const productSlice = createSlice({
    name: 'product',
    initialState: {} as ProductSliceState,
    reducers: {
        added: () => makeNewProduct(),
        fetched: (state, action: PayloadAction<ProductState>) => action.payload,

        changeName: (state, action: PayloadAction<Product['name']>) => ({
            ...state,
            name: action.payload,
        }),

        changeReferenceCode: (state, action: PayloadAction<Product['referenceCode']>) => ({
            ...state,
            referenceCode: action.payload,
        }),

        changeInternalName: (state, action: PayloadAction<Product['internalName']>) => ({
            ...state,
            internalName: action.payload,
        }),

        changeDescription: (state, action: PayloadAction<Product['description']>) => ({
            ...state,
            description: action.payload,
        }),

        changeWholesalePriceCurrency: (
            state,
            action: PayloadAction<Product['wholesalePrice']['currency']>,
        ) => ({
            ...state,
            wholesalePrice: {
                ...state.wholesalePrice,
                currency: action.payload,
            },
        }),

        changeWholesalePriceAmount: (
            state,
            action: PayloadAction<Product['wholesalePrice']['amount']>,
        ) => ({
            ...state,
            wholesalePrice: {
                ...state.wholesalePrice,
                amount: action.payload,
            },
        }),

        changeRetailerPriceCurrency: (
            state,
            action: PayloadAction<Product['retailerPrice']['currency']>,
        ) => ({
            ...state,
            retailerPrice: {
                ...state.retailerPrice,
                currency: action.payload,
            },
        }),

        changeRetailerPriceAmount: (
            state,
            action: PayloadAction<Product['retailerPrice']['amount']>,
        ) => ({
            ...state,
            retailerPrice: {
                ...state.retailerPrice,
                amount: action.payload,
            },
        }),

        changeDeliveryDays: (state, action: PayloadAction<Product['orderDeliveryDays']>) => ({
            ...state,
            orderDeliveryDays: action.payload,
        }),

        changeUnitWeight: (state, action: PayloadAction<Product['unitWeight']>) => ({
            ...state,
            unitWeight: action.payload,
        }),

        changeSizes: (state, action: PayloadAction<Size['id'][]>) => ({
            ...state,
            sizes: action.payload,
        }),

        changeSegments: (state, action: PayloadAction<Segment['id'][]>) => ({
            ...state,
            sizes: action.payload,
        }),

        changeCategories: (state, action: PayloadAction<Category['id'][]>) => ({
            ...state,
            sizes: action.payload,
        }),

        changeCollection: (state, action: PayloadAction<Collection | undefined>) => ({
            ...state,
            collection: action.payload,
        }),

        changeSizechart: (state, action: PayloadAction<number>) => ({
            ...state,
            sizechartId: action.payload,
        }),
        changeIsActive: (state, action: PayloadAction<Product['isActive']>) => ({
            ...state,
            isActive: action.payload,
        }),
        changeMinOrderingQuantity: (
            state,
            action: PayloadAction<Product['orderMinimumQuantity']>,
        ) => ({
            ...state,
            orderMinimumQuantity: action.payload,
        }),
        changeMaxOrderingQuantity: (
            state,
            action: PayloadAction<Product['orderMaximumQuantity']>,
        ) => ({
            ...state,
            orderMaximumQuantity: action.payload,
        }),

        // moveDetail: (
        //     state,
        //     action: PayloadAction<{
        //         sourceId: ProductDetailState['id'];
        //         destinationId: ProductDetailState['id'];
        //     }>,
        // ) => {
        //     return {
        //         ...state,
        //         details: moveItem(state.details)(action.payload),
        //     };
        // },

        moveVariation: (
            state,
            action: PayloadAction<{
                sourceId: ProductVariationState['id'];
                destinationId: ProductVariationState['id'];
            }>,
        ) => {
            return {
                ...state,
                variations: moveItem(state.variations)(action.payload),
            };
        },
    },
    extraReducers: {
        // [detailsActions.detailAdded.type]: (state, action: PayloadAction<DetailState>) => ({
        //     ...state,
        //     details: [...state.details, action.payload],
        // }),
        // [detailsActions.detailRemoved.type]: (state, action: PayloadAction<[]>) => ({
        //     ...state,
        //     details: state.details,
        // }),
        [variationsActions.variationAdded.type]: (state, action: PayloadAction<number>) => ({
            ...state,
            variations: [...state.variations, action.payload],
        }),
        [variationsActions.variationRemoved.type]: (
            state,
            action: PayloadAction<VariationState['id']>,
        ) => ({
            ...state,
            variations: state.variations.filter(variation => variation !== action.payload),
        }),
    },
});
export const productActions = productSlice.actions;

/** Selectors */
const selectProductSlice = (state: RootState): ProductState => state.product;
export const selectProduct = createSelector(selectProductSlice, product => product);
export const selectProductImages = createSelector(selectProduct, product => product?.images);
export const selectProductName = createSelector(selectProduct, product => product?.name);
export const selectProductReferenceCode = createSelector(
    selectProduct,
    product => product.referenceCode,
);
export const selectProductInternalName = createSelector(
    selectProduct,
    product => product.internalName,
);
export const selectProductDescription = createSelector(
    selectProduct,
    product => product.description,
);
export const selectProductWholesalePrice = createSelector(
    selectProduct,
    product => product.wholesalePrice,
);
export const selectProductRetailerPrice = createSelector(
    selectProduct,
    product => product.retailerPrice,
);
export const selectProductDeliveryDays = createSelector(
    selectProduct,
    product => product.orderDeliveryDays,
);
export const selectProductIsActive = createSelector(selectProduct, product => product.isActive);
export const selectProductMinOrderingQuantity = createSelector(
    selectProduct,
    product => product.orderMinimumQuantity,
);
export const selectProductMaxOrderingQuantity = createSelector(
    selectProduct,
    product => product.orderMaximumQuantity,
);
export const selectProductSizes = createSelector(selectProduct, selectSizes, (product, sizes) =>
    sizes.filter(size => product.sizes.some(s => s === size.id)),
);
export const selectProductSegments = createSelector(
    selectProduct,
    selectSegmentsCollection,
    (product, segments) => product.segments.map(id => segments[id]),
);
export const selectProductCategories = createSelector(
    selectProduct,
    product => product.categories
);
export const selectProductCollection = createSelector(
    selectProduct,
    (product) => product.collection,
);
export const selectProductSizechart = createSelector(selectProduct, product => product.sizechart_id);
export const selectProductDetails = createSelector(
    selectProduct,
    product => product.details
);
export const selectProductVariations = createSelector(
    selectProduct,
    selectVariationsCollection,
    (product, variations) =>
        product.variations.map(variationId => variations[variationId] as VariationState),
);

import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrandProfile } from "../../domain/BrandProfile";
import { Imagedit } from "../../external/common/Imageedit/Imagedit";
import { PlaceholderImage } from "../../external/common/PlaceholderImage";
import { TextToggledit } from "../../external/common/TextToggledit";

const EightSmall: React.FC<{
  images: any[];
  loadingPreview: boolean | number;
}> = (props) => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const { t } = useTranslation();

  const handleSaveImage = (image: any, i: any) => {
    const imagemAtual = image.length > 1 ? image[1] : image[0];
    let index = values.history.lookbooks.images.findIndex(
      (image) => image.id === props.images[i]?.id && image.remover !== true
    );

    if (imagemAtual.remover) {
      let auxImages = { ...values.history.lookbooks.images[index] };
      auxImages.remover = true;
      auxImages.novaImagem = undefined;
      auxImages.imageSrc = "";
      setFieldValue(`history.lookbooks.images[${index}]`, auxImages);
    } else {
      setFieldValue(`history.lookbooks.imagePreviewIndex`, i);
      if (index === -1) {
        let auxImages = {
          ...values.history.lookbooks.images[
            values.history.lookbooks.images.length
          ],
        };
        auxImages.id = Math.random();
        auxImages.title = values.history.lookbooks.images[index]?.title || "";
        auxImages.novaImagem = imagemAtual.file;
        auxImages.imageSrc = imagemAtual.src;
        auxImages.novaImagemCrop = imagemAtual.crop;
        auxImages.originalImage = imagemAtual.src;
        auxImages.cropOriginal = imagemAtual.cropOriginal;
        setFieldValue(
          `history.lookbooks.images[${values.history.lookbooks.images.length}]`,
          auxImages
        );
      } else {
        let auxImages = { ...values.history.lookbooks.images[index] };
        if (imagemAtual.file) {
          auxImages.novaImagem = imagemAtual.file;
        }
        auxImages.imageSrc = imagemAtual.src;
        auxImages.novaImagemCrop = imagemAtual.crop;
        auxImages.cropOriginal = imagemAtual.cropOriginal;
        setFieldValue(`history.lookbooks.images[${index}]`, auxImages);
      }
    }
  };

  let items = [];

  for (let i = 0; i < 8; i++) {
    items.push(
      <div className="item">
        <Imagedit
          allowEmpty={true}
          desiredDimensions={{ width: 285, height: 285 }}
          helpDimensions={{ width: 285, height: 285 }}
          render={() => (
            <>
              {props.loadingPreview === i ? (
                <PlaceholderImage loading />
              ) : (
                <>
                  {props.images[i]?.src ? (
                    <img src={props.images[i].src} />
                  ) : (
                    <div className="placeholder-image">
                      <FontAwesomeIcon icon={faImage} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          onSave={(image) => handleSaveImage(image, i)}
          title={t("Lookbook image", "Lookbook image")}
          src={props.images[i] || { src: "", crop: {}, id: Math.random() }}
        />
        <div className="title-box">
          <TextToggledit
            text={props.images[i]?.title || ""}
            placeholder={t("Image text", "Image text")}
            maxLength={50}
            name={`text`}
            onChange={(e) => {
              let index = values.history.lookbooks.images
                .map((item) => item.id)
                .indexOf(props.images[i].id);
              setFieldValue(`history.lookbooks.images[${index}].title`, e);
            }}
          />
        </div>
      </div>
    );
  }

  return <div className="eight-small">{items}</div>;
};

export default EightSmall;

import { faImage, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import "./PlaceholderImage.scss";

export const PlaceholderImage: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames("placeholder-image", props.className)}
      style={props.style}
    >
      {props.loading ? (
        <>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          <div className="placeholder-image-text">{t('Loading...', 'Loading...')}</div>
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faImage} />
          <div className="placeholder-image-text">{t("Upload image", "Upload image")}</div>
        </>
      )}
    </div>
  );
};

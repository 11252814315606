import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

import { CollectionSummary } from "../../../domain/CollectionList";
import { useRoutes } from "../../../useRoutes";

import { CollectionCard } from "./CollectionCard";

import "./CollectionList.scss";

export const CollectionList: React.FC<{ collections: CollectionSummary[] }> = (
  props
) => {
  const routes = useRoutes();
  const { t } = useTranslation();

  if (!props.collections) {
    return null;
  }

  function onAddCollection() {
    routes.collections.navigateToCreate();
  }

  return (
    <>
      <div className="page-title">
        {t("Collections management", "Collections management")}
        <span className="add-button icon-button" onClick={onAddCollection}>
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </div>

      <div className="collection-list">
        {props.collections.map((collection) => (
          <CollectionCard key={collection.id} collection={collection} />
        ))}
      </div>
    </>
  );
};

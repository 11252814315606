import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Compressor from "compressorjs";
import React from "react";

import { FileUpload } from "../FileUpload";
import { OrderableGrid } from "../OrderableGrid/OrderableGrid";
import { OrderableGridHandle } from "../OrderableGrid/OrderableGridHandle";
import { OrderableGridItem } from "../OrderableGrid/OrderableGridItem";
import { Thumbnail } from "../Thumbnail";

import { ImageditImage } from "./ImageditImage.model";

interface ImageditReordererProps {
  currentImageId: ImageditImage["id"] | undefined;
  images: ImageditImage[];
  imageLimit: number;
  onAdd: (args: {
    src: ImageditImage["src"];
    file?: File;
    originalImage?: ImageditImage["src"];
  }) => void;
  onChange: (imageId: ImageditImage["id"]) => void;
  onMove: (
    sourceId: ImageditImage["id"],
    destinationId: ImageditImage["id"]
  ) => void;
}
export const ImageditReorderer: React.FC<ImageditReordererProps> = (props) => {
  const { currentImageId, images, onAdd, onChange, onMove, imageLimit } = props;

  const onImageAdd = (filelist: FileList | null) => {
    if (!filelist) {
      return;
    }

    new Compressor(filelist[0], {
      convertSize: 2000000,
      quality: 0.8,
      maxWidth: 1920,
      success(result: any) {
        const src = URL.createObjectURL(result);
        onAdd({ src, file: result, originalImage: src });
      },
    });
  };

  return (
    <div className="imagedit-reorderer">
      <OrderableGrid className="orderable-gallery" itemsType="imagedit-gallery">
        {images.map((item) => {
          return (
            <OrderableGridItem key={item.id} id={item.id} onMoveItem={onMove}>
              <OrderableGridHandle>
                <Thumbnail
                  src={item.src}
                  className={`gallery-image ${
                    item.id === currentImageId ? "gallery-image-current" : ""
                  }`}
                  onClick={() => onChange(item.id)}
                />
              </OrderableGridHandle>
            </OrderableGridItem>
          );
        })}
        {images.length < imageLimit && (
          <FileUpload accept="image/*" onChange={onImageAdd}>
            <FontAwesomeIcon
              icon={faPlus}
              className="orderable-gallery-add-button"
            />
          </FileUpload>
        )}
      </OrderableGrid>
    </div>
  );
};

import { useState, useEffect } from 'react';

export enum AsyncState {
    'NOT_STARTED',
    'PENDING',
    'SUCCESS',
    'FAILURE',
}

interface UseAsyncStateReturnType {
    isNotStarted: boolean;
    isPending: boolean;
    isSuccess: boolean;
    isFailure: boolean;
    setNotStarted: () => void;
    setPending: () => void;
    setSuccess: () => void;
    setFailure: () => void;
}
export const useAsyncState = (initialState = AsyncState.PENDING): UseAsyncStateReturnType => {
    const [asyncState, setAsyncState] = useState(initialState);

    return {
        isNotStarted: asyncState === AsyncState.NOT_STARTED,
        isPending: asyncState === AsyncState.PENDING,
        isSuccess: asyncState === AsyncState.SUCCESS,
        isFailure: asyncState === AsyncState.FAILURE,

        setNotStarted: () => setAsyncState(AsyncState.NOT_STARTED),
        setPending: () => setAsyncState(AsyncState.PENDING),
        setSuccess: () => setAsyncState(AsyncState.SUCCESS),
        setFailure: () => setAsyncState(AsyncState.FAILURE),
    };
};

interface UseAsyncImageProps {
    src: string | undefined;
}
interface UseAsyncImageReturnType {
    isPending: boolean;
    isSuccess: boolean;
    isFailure: boolean;
    width: number;
    height: number;
}
export const useAsyncImage = (props: UseAsyncImageProps): UseAsyncImageReturnType => {
    const { src } = props;
    const { isPending, isSuccess, isFailure, setSuccess, setFailure } = useAsyncState(
        AsyncState.PENDING,
    );

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    // const [width, setWidth] = useState(0);
    // const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!src) {
            setSuccess();
            return;
        }
        const image = new Image();

        const onLoad = () => {
            setSuccess();
            setDimensions({ width: image.naturalWidth, height: image.naturalHeight });
            // setWidth(image.naturalWidth);
            // setHeight(image.naturalHeight);
        };
        const onError = () => setFailure();

        image.src = src;
        image.addEventListener('load', onLoad);
        image.addEventListener('error', onError);

        return () => {
            image.removeEventListener('load', onLoad);
            image.removeEventListener('error', onError);
        };
    }, [src]);

    return {
        isPending,
        isSuccess,
        isFailure,
        // width,
        // height,
        width: dimensions.width,
        height: dimensions.height,
    };
};

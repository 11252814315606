import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BrandProfile } from "../../domain/BrandProfile";
import { Modal } from "../common/Modal";
import { PrimaryButton } from "../common/PrimaryButton";
import { RichTextEditor } from "../common/RichTextEditor";

import "./InternationalRetailers.scss";

export const InternationalRetailers: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [isEditing, setEditing] = useState(false);
  const [terms, setTerms] = useState(values.theBrand.multibrandRetailersFull);
  const { t } = useTranslation();

  const onSave = () => {
    setFieldValue("theBrand.multibrandRetailersFull", terms);
    setEditing(false);
  };

  return (
    <div className="international-retailers">
      <span onClick={() => setEditing(true)}>{`${t(
        "See all",
        "See all"
      )} >>`}</span>

      <Modal
        isOpen={isEditing}
        contentLabel={t("International Retailers", "International Retailers")}
        onRequestClose={() => setEditing(false)}
      >
        <h2>{t("International Retailers", "International Retailers")}</h2>
        <div className="international-retailers-modal-content">
          <RichTextEditor
            value={terms}
            onChange={(event) => setTerms(event.target.value)}
          />
        </div>
        <div className="international-retailers-modal-actions save-button">
          <PrimaryButton onClick={onSave}>{t("Save", "Save")}</PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import {useApi} from '../../../api/useApi';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faGear} from '@fortawesome/free-solid-svg-icons';
import './Menu.scss';
import Axios from 'axios';

const Menu = () => {
    const {logout} = useApi();
    const {t} = useTranslation();
    const [menuData, setMenuData] = useState([]);
    const menuRef = useRef(null);
    const menuItemRef = useRef(null);
    const apiToken = localStorage.getItem('api_token');

    useEffect(() => {
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/v1/links`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setMenuData(response.data.data);
            })
            .catch((response) => {
                console.log(response);
            });

        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                menuItemRef.current &&
                !menuItemRef.current.contains(event.target) &&
                document.getElementById('nav')?.classList.contains('active')
            ) {
                toggleMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    function toggleMenu() {
        const navContent = document.getElementById('nav');
        navContent?.classList.toggle('active');
    }

    return (
        <nav id='nav' className='new-menu'>
            <button ref={menuItemRef} className='new-menu__icon' onClick={toggleMenu}>
                <span className='new-menu__icon__hamburguer'></span>
            </button>
            <div className='new-menu__list' ref={menuRef}>
                <>
                    {menuData.map((el, index) => {
                        return (
                            <div key={index} className='new-menu__list--item'>
                                <span>{el.group}</span>
                                <div className='new-menu__list--item__subitem'>
                                    {el.links.map((link, key) => {
                                        let href = link.url;
                                        const currentPathname = window.location.hostname;

                                        if (!href.includes(currentPathname)) {
                                            const newAccessToken = `?token=${apiToken}`;
                                            href = apiToken ? href + newAccessToken : href;
                                        }

                                        return (
                                            <a key={key} href={href}>
                                                {link.text}
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </>
                <div className='bottom-items'>
                    <div className='icon-button logout-button'>
                        <Tooltip title={String(t('Settings', 'Settings'))}>
                            <a href={`${process.env.REACT_APP_ADMIN_URL}`}>
                                <svg width={10} height={10} viewBox='0 0 512 512'>
                                    <path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z' />
                                </svg>
                                {t('Settings', 'Settings')}
                            </a>
                        </Tooltip>
                    </div>
                    <div className='icon-button logout-button'>
                        <Tooltip title={String(t('Logout', 'Logout'))} onClick={() => logout()}>
                            <span>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                                {t('Logout', 'Logout')}
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Menu;
